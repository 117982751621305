import $ from "jquery";
import auth_Header from "../utils/authHeader";

export const api = {
    get,
    post,
    getToken,
    getPdf,
    postOut,
    download,
    getReport
};

function download(docType, token){
    window.location = process.env.REACT_APP_API + "/download/" + docType + "/" + token;
}

function getPdf(token) {
    var req = new XMLHttpRequest();
    req.open("GET", process.env.REACT_APP_API + "/download/" + token, true);
    req.responseType = "blob";
    req.onload = function (event) {
        var blob = req.response;
        var fileName = req.getResponseHeader('Content-Disposition') //if you have the fileName header available
    
        var link=document.createElement('a');
        link.href=window.URL.createObjectURL(blob);
        link.download=fileName;
        link.click();
    };

    req.send();
}

function getReport(url, inputData, success, fail){

    var req = new XMLHttpRequest();
    let auth = auth_Header.authHeader();
    req.open("POST", process.env.REACT_APP_API + "/" + url, true);
    req.setRequestHeader('Content-Type', 'application/json');
    req.setRequestHeader('Authorization', auth.Authorization);
    req.responseType="blob";

    req.onload = function (event) {

        var blob = req.response;
        var fn = req.getResponseHeader('Content-Disposition') //if you have the fileName header available
        if(fn){
            var idx = fn.indexOf("filename=");
            var fileName = fn;
            if(idx > -1)
            {
                fileName = fn.substring(idx+9);
            }    
            var link=document.createElement('a');
            link.href=window.URL.createObjectURL(blob);
            link.download=fileName;
            link.click();
            if(success) success({Message:"Datoteka: " + fileName + " je uspješno preuzeta.", Status: 200, ResponseData: null});
        }
        else{
            if(fail) fail({Message:"Greška pri generiranju ili preuzimanju datoteke.", Status: 505, ResponseData: blob});
        }
    };

    // req.onreadystatechange = function() {
    //     if (this.readyState == 4 && this.status == 200) {
    //     //console.log(this.responseText);
    //     }
    // }

    req.send(JSON.stringify(inputData));

}

function get(url, sucess, fail) {
    url = process.env.REACT_APP_API + "/" + url;
  
    $.ajax({
        type: "GET",
        url: url,
        dataType: "json",
        crossDomain: true,
        headers: auth_Header.authHeader(),
    })
        .done(function (data) {
            if (sucess) sucess(data);
        })
        .fail(function (ex) {
            if (fail) fail(ex);
        });
}

function post(url, data, sucess, fail) {
    return $.ajax({
        type: "POST",
        url: process.env.REACT_APP_API + "/" + url,
        dataType: "json",
        data: data,
        crossDomain: true,
        headers: auth_Header.authHeader()
    })
        .done(function (data) {
            if (sucess) sucess(data);
        })
        .fail(function (ex) {
            if (fail) fail(ex);
        });
}

function postOut(url, data, sucess, fail) {
    $.ajax({
        type: "POST",
        url: url,
        dataType: "json",
        data: data,
        crossDomain: true,
    })
    .done(function (data) {
        if (sucess) sucess(data);
    })
    .fail(function (ex) {
        if (fail) fail(ex);
    });
}

function getToken(username, password, sucess, fail) {
    var body = {
        grant_type: "password",
        username: username,
        password: password
    };

    $.ajax({
        url: process.env.REACT_APP_API + "/token",
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        data: body,
    })
        .done(function (data) {
            if (sucess) sucess(data);
        })
        .fail(function (ex) {
            if (fail) fail(ex);
        });
}

export default api;
