import React from "react";

import { LoginOutlined, EditOutlined, SettingOutlined, LogoutOutlined, QuestionCircleOutlined, SearchOutlined, HistoryOutlined, BookOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Row, Col, Affix, Menu, Tooltip } from "antd";
import $ from "jquery";
import util from "../utils/util";
import userService from "../utils/userService";
import facebook from "../images/facebook2.jpg";
import linkedin from "../images/linkedin2.jpg";
import onama from "../o-nama-lexhr.pdf";
import PdfUpute from "../upute-lexhr.pdf";

const { SubMenu } = Menu;

class MainMenu extends React.Component {
    constructor(props) {
        var user = userService.getLoggedInUser();
        super(props);
        window.addEventListener("resize", this.updateDimensions);
        this.state = { isMobileDevice: util.isMobileDevice(), isLoggedIn: user !== null, loading: false, user: user };
    }

    updateDimensions = () => {
        this.setState({ isMobileDevice: util.isMobileDevice() });
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    componentDidMount = () => {
        window.addEventListener("resize", this.updateDimensions);
        $(".main-menu").css("margin-right", "-250px");
        $(".main-menu").animate({ marginRight: "0px" }, 100);
    };

    onWrapperClick = () => {
        let self = this;
        $(".main-menu").animate({ marginRight: "-250px" }, 200, null, () => {
            self.onClick(null);
        });
    };

    onClick = (menu) => {
        if (this.props.onClick) {
            this.props.onClick(menu);
        }

        switch (menu) {
            case "pretraga":
                util.clearSettings();

                if (window.location.toString().endsWith("/")) {
                    window.location.reload();
                } else {
                    window.location = "/";
                }
                $("#searchText").focus();
                break;
            case "kupljenipropisi":
                window.location = "/kupljenipropisi";
                break;
            case "osobnipodaci":
                window.location = "/userprofile";
                break;
            case "prijava":
                //window.location = "/loginxxx";//za reset komponente
                window.location = "/login";
                break;
            case "promjenalozinke":
                window.location = "/passwordchange";
                break;
            case "povezanikorisnici":
                window.location = "/teammembers";
                break;
            case "pretplata":
                window.location = "/pretplata";
                break;
            case "racun":
                window.location = "/racun";
                break;
            case "povijestpregledavanja":
                window.location = "/povijestpregledavanja";
                break;
            case "upute":
                window.location = "/upute";
                
                break;
            case "onama":
                //window.location = "/about";
                //window.open(onama, "_blank").focus();
                window.location = "/onama";
                
                break;
            case "odjava":
                userService.logout();
                window.location = "/";
                
            default:
                return null;
        }
    };

    novaPretraga = () => {
        this.onClick("pretraga");
    };

    render() {
        let loggedInStyle = this.props.mode === 2 ? null : { display: "none" };
        let notLoggedInStyle = this.props.mode === 2 ? { display: "none" } : null;

        return (
            <Affix
                affixtop={60}
                className="menu-wrapper"
                onClick={() => {
                    this.onWrapperClick(null);
                }}
            >
                <div className="main-menu" onClick={(e) => e.stopPropagation()}>
                    <Menu style={{ height: "100vh" }} theme="light" mode="inline">
                        <Menu.Item style={loggedInStyle} icon={<SearchOutlined />} onClick={this.novaPretraga}>
                            Nova pretraga
                        </Menu.Item>
                        <Menu.Item style={loggedInStyle} icon={<BookOutlined />} onClick={() => this.onClick("kupljenipropisi")}>
                            Kupljeni propisi
                        </Menu.Item>
                        <Menu.Item style={loggedInStyle} icon={<HistoryOutlined />} onClick={() => this.onClick("povijestpregledavanja")}>
                            Povijest pregledavanja
                        </Menu.Item>
                        <SubMenu
                            key="sub4"
                            style={loggedInStyle}
                            title={
                                <span>
                                    <SettingOutlined />
                                    <span>Korisnički račun</span>
                                </span>
                            }
                        >
                            <Menu.Item onClick={() => this.onClick("osobnipodaci")}>
                                <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Osobni podaci korisničkog računa s mogućnošću promjene osobnih podataka.">
                                    Osobni podaci
                                </Tooltip>
                            </Menu.Item>
                            {this.state.user !== null && this.state.user.tipKorisnikaID == 1 ? (
                                <Menu.Item onClick={() => this.onClick("povezanikorisnici")}>
                                    <Tooltip align="right" mouseEnterDelay={1} placement="right" title="U ovom dijelu registrirani korisnik (glavni korisnik) može omogućiti ili onemogućiti i drugim subjektima (pozvani korisnici) korištenje svog računa. Primjerice, odgovorna osoba nekog društva može odobriti odabranim djelatnicima kupnju pročišćenih propisa preko svoje kreditne kartice.">
                                        Povezani korisnici
                                    </Tooltip>
                                </Menu.Item>
                            ) : null}
                            <Menu.Item onClick={() => this.onClick("promjenalozinke")}>Promjena zaporke</Menu.Item>
                            {/* {this.state.user !== null && this.state.user.tipKorisnikaID == 1 ? <Menu.Item onClick={() => this.onClick("pretplata")}>Kupnja pretplatom</Menu.Item> : null} */}
                            {this.state.user !== null && this.state.user.tipKorisnikaID == 1 ? (
                                <Menu.Item onClick={() => this.onClick("racun")}>
                                    <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Popis računa koji su izdani za kupnju propisa. Svaki račun na popisu sadrži broj računa, datum izdavanja, naziv usluge, iznos, poveznicu na PDF računa i poveznicu na slanje računa putem e-maila. Svaki se račun može preuzeti u PDF obliku i poslati e-poštom na adresu korisnika računa.">
                                        Izdani računi
                                    </Tooltip>
                                </Menu.Item>
                            ) : null}
                        </SubMenu>

                        <Menu.Item icon={<QuestionCircleOutlined />} onClick={() => this.onClick("upute")}>
                            Upute
                        </Menu.Item>
                        <Menu.Item icon={<InfoCircleOutlined />} onClick={() => this.onClick("onama")}>
                            O nama
                        </Menu.Item>
                        {/* <Menu.Item style={{textAlign:"center"}} onClick={() => this.onClick("registracija")}>
                            <span className="link-icon" onClick={() => (window.location = "http://www.facebook.com/maxlexhr")}>
                                <Image preview={false} src={facebook}></Image>
                            </span>
                            <span className="link-icon" onClick={() => (window.location = "http://www.linkedin.com/maxlexhr")}>
                                <Image preview={false} src={linkedin}></Image>
                            </span>
                        </Menu.Item> */}
                        <Menu.Item style={loggedInStyle} icon={<LogoutOutlined />} onClick={() => this.onClick("odjava")}>
                            Odjava
                        </Menu.Item>
                        <Menu.Item style={notLoggedInStyle} icon={<LoginOutlined />} onClick={() => this.onClick("prijava")}>
                            Prijava
                        </Menu.Item>
                        <Menu.Item style={notLoggedInStyle} icon={<EditOutlined />} onClick={() => this.onClick("registracija")}>
                            Novi korisnik
                        </Menu.Item>
                    </Menu>
                </div>
            </Affix>
        );
    }
}

export default MainMenu;
