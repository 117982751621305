"use strict";

import api from "../utils/api";
import util from "./util";
// eksportiramo metode prema van
export const userService = {
    login,
    logout,
    getLoggedInUser,
    getLoggedInUserFromServer,
};

// ============ logirani user BEGIN
function login(username, password, success, fail) {
    return api.getToken(username, password, success, fail);
}

function logout() {
    util.deleteCookie("user");
    //alert("logout");
    //localStorage.removeItem("user");
}

function getLoggedInUser() {
    let cooki = util.getCookie("user");
    util.toEmptyString(cooki);

    let user = null;

    if (cooki) user = JSON.parse(cooki);

    if (user && user.access_token && user.authdata) {
        return user;
    } else {
        logout();
        return null;
        //location.reload(true);
    }
}

function getLoggedInUserFromServer() {
    api.get("getuser", (data) => {
        return data;
    });
}
// ============ logirani user END

export default userService;
