import React from "react";
import { Row, Col, Collapse } from "antd";
import api from "../utils/api";
import { Spin } from "antd";
import Spinner from "../components/spinner";
import SpinnerFixed from "./spinnerfixed";
import $ from "jquery";
import util from "../utils/util";

class Upute extends React.Component {
    constructor(props) {
        super(props);
        this.state = { html: "", loading: true, init: false };

        let url = "getupute";

        if (window.location.toString().includes("uvjetikoristenja")) url = "getuvjetikoristenja";

        if (window.location.toString().includes("onama")) url = "getonama";

        api.get(
            url,
            (data) => {
                this.setState({ html: data, loading: false, init: true });
            },
            (data) => {
                this.setState({ loading: false });
            }
        );
    }

    componentDidUpdate() {
        if (this.state.init) {

            util.podesiGoToLinkove("#uputeroot a", 80);
            // $("#uputeroot a").each((index, e) => {
            //     let href = $(e).attr("href");
            //     let name = $(e).attr("name");
            //     if(!name) name = "";

            //     if (href && href.startsWith("#") || name) {
            //         $(e).replaceWith("<div class='link' to='" + href + "' id='#" + name + "'>" + $(e).html() + "</div>");
            //     }
            // });

            // $(".link").each((index, e) => {
            //     $(e).click(() => {
            //         let to = $(e).attr("to");
            //         let element = document.getElementById(to);
            //         if(!element) 
            //             element = document.getElementsByName(to)[0];

            //         if(element) {
            //             var headerOffset = 80;
            //             var elementPosition = element.getBoundingClientRect().top;
            //             var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                        
            //             window.scrollTo({
            //                 top: offsetPosition,
            //                 behavior: "smooth"
            //            });
            //             //element.scrollIntoView();
            //         }
            //     });
            // });

            this.setState({ init: false });
        }
    }

    render() {
        return (
            <Row id="uputeroot" style={{ marginTop: 100 }}>
                <Col span={20} offset={2} className="kartica">
                    {/* <div className="link" to="#_Toc93058759">
                        Link to uvod
                    </div> */}
                    <SpinnerFixed show={this.state.loading} text="Učitavanje..."></SpinnerFixed>
                    {this.state.loading ? <Spin></Spin> : <div dangerouslySetInnerHTML={{ __html: this.state.html }}></div>}
                    {/* <div id="#_Toc93058759">to link</div> */}
                </Col>
            </Row>
        );
    }
}

export default Upute;
