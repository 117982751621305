import React from "react";
import { Row, Col, Alert, Button, Input, Space } from "antd";
import api from "../utils/api";
import util from "../utils/util";
import $ from "jquery";
import logo from "../images/web-secure-logo.png";
import { FileWordOutlined, FilePdfOutlined} from "@ant-design/icons";

class PaymentSuccess extends React.Component {
    constructor(props) {
        super(props);
        let params = util.getParametersFromUrl(window.location);

        this.state = {
            isMobileDevice: util.isMobileDevice(),
            token: params.token,
            span: util.isMobileDevice() ? 20 : 12,
            offset: util.isMobileDevice() ? 2 : 6,
        };
    }

    paySuccess = (data) => {
        let b = data;
    };

    payFail = (data) => {
        let b = data;
    };

    success = (data) => {
        this.setState({ uspjesnaRegistracija: true });
    };

    fail = (data) => {
        this.setState({ uspjesnaRegistracija: false });
    };

    preuzmiPdf = (data) => {
        window.location = process.env.REACT_APP_API + "/download/pdf/" + this.state.token;
    }

    preuzmiWord = (data) => {
        window.location = process.env.REACT_APP_API + "/download/word/" + this.state.token;
    }

    render() {

        return (
            <Row type="flex" justify="center" align="middle" style={{ minHeight: "90vh" }}>
                <Col span={24} justify="center" align="middle" >
                    <Row>
                        <Col span={this.state.span} offset={this.state.offset}>
                        <Alert
                            message="Uspješno plaćanje"
                            description={"Kod za preuzimanje: " + this.state.token}
                            type="success"
                            showIcon
                            />
                        </Col>
                    </Row>  
                    <Row className="space">
                      
                    </Row>                    
                    <Row>
                        <Col span={24}>
                            <Space>
                                <Button icon={<FileWordOutlined></FileWordOutlined>} onClick={this.preuzmiWord}>
                                    Preuzmi word dokument
                                </Button>
                                <Button icon={<FilePdfOutlined></FilePdfOutlined>} onClick={this.preuzmiPdf}>
                                    Preuzmi pdf dokument
                                </Button>
                            </Space>

                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default PaymentSuccess;
