import React from "react";
import { Space, Modal, Select, Button, Table, Row, Col, Form, message, Tooltip } from "antd";
import api from "../utils/api";
import { FilePdfOutlined, MailTwoTone } from "@ant-design/icons";
import util from "../utils/util";

const { Column, ColumnGroup } = Table;
const { confirm } = Modal;

class Racun extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pagination: false,
            isMobileDevice: util.isMobileDevice(),
            racuni: [],
        };

        api.get("racuni/get", this.getSuccess, this.getFail);
    }

    getSuccess = (data) => {
        if (data.Status.Status === 200) {
            this.setState({
                racuni: data.Racuni,
                pagination: (data.Racuni == null ? false : data.Racuni.length < 5) ? false : true,
            });
        } else {
            this.responseNotValid(data);
        }
    };

    responseNotValid = (data) => {
      
    };

    getFail = (data) => {
        this.responseNotValid(data);
        if (data.responseText) alert(JSON.parse(data.responseText).Message);
    };

    pdf = (racun) => {
        window.location = process.env.REACT_APP_API + "/racun/get/" + racun.PlacanjeID + "/1";
    };

    email = (racun) => {
        api.get("racun/get/" + racun.PlacanjeID + "/2", this.actionSuccess, this.actionFail);
    };

    actionSuccess = (data) => {
        let msg = data.Data.Message;

        if (data.Data.Status == 200) {
            let config = { content: msg };
            message.success(config);
        } else {
            message.error(msg);
        }
    };

    actionFail = (data) => {
    
        message.error("Akcija nije uspjela: " + data.responseText);
    };

    render() {
        return !this.state.isMobileDevice ? (
            <Row style={{ margin: "3vh", marginTop: 50 }}>
                <Table dataSource={this.state.racuni} tableLayout={"fixed"} pagination={this.state.pagination} size={"small"}>
                    <Column align="right" title="Broj" dataIndex="BrojRacuna" key="BrojRacuna" />
                    <Column align="center" title="Datum" dataIndex="DatumIzdavanja" key="DatumIzdavanja" />
                    <Column title="Vrsta" dataIndex="VrstaRacuna" key="VrstaRacuna" />
                    <Column align="right" title="Iznos [kn]" dataIndex="UkupniIznos" key="UkupniIznos" />
                    <Column
                        key="action"
                        render={(text, record) => (
                            <Space size="middle">
                                <Tooltip placement="top" title="Preuzimanje računa u PDF formatu">
                                    <a
                                        onClick={() => {
                                            this.pdf(record);
                                        }}
                                        className="table-red"
                                    >
                                        PDF <FilePdfOutlined style={{ color: "red" }} />
                                    </a>
                                </Tooltip>
                                <Tooltip placement="top" title="Slanje računa e-mail porukom">
                                    <a
                                        onClick={() => {
                                            this.email(record);
                                        }}
                                        className="table-blue"
                                    >
                                        E-mail <MailTwoTone />
                                    </a>
                                </Tooltip>
                            </Space>
                        )}
                    />
                </Table>
            </Row>
        ) : (
            <Row style={{ marginTop: "100px" }}>
                <Col span={24} className="center">
                    {this.state.racuni.map((item, index) => {
                        return (
                            <Row className="kartica" style={{marginTop:"20px"}}>
                                <Col span={24}>{item.BrojRacuna}</Col>
                                <Col span={24}>{item.Datum}</Col>
                                <Col span={24}>{item.VrstaRacuna}</Col>
                                <Col span={24}>Iznos: {item.UkupniIznos} KN</Col>

                                <Col span={24}>
                                    <a
                                        onClick={() => {
                                            this.pdf(item);
                                        }}
                                        className="table-red"
                                    >
                                        PDF <FilePdfOutlined style={{ color: "red" }} />
                                    </a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a
                                        onClick={() => {
                                            this.email(item);
                                        }}
                                        className="table-blue"
                                    >
                                        E-mail <MailTwoTone />
                                    </a>
                                </Col>

                            </Row>
                        );
                    })}
                </Col>
            </Row>
        );
    }
}

export default Racun;
