import React from "react";
import axios from "axios";
import { Form, FormInstance, Input, Button, Alert, Row, Divider, Checkbox, Select, Option } from "antd";
import locale from "antd/es/date-picker/locale/hr_HR";
import userService from "../utils/userService";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import api from "../utils/api";
import {Link} from "react-router-dom";
import util from "../utils/util";
class Login extends React.Component {
    constructor(props) {
        super(props);
        let params = util.getParametersFromUrl(window.location.href);
        this.state = { username: "", password: "", email: "", errorMessage: "", zaboravljenaLozinka: false, loading: false, returnPropisUID: params.uid, returnPropisTTID: params.ttid, returnPropisDp: params.dp};
    }

    onFinish = (values) => {
        this.submit();
    };

    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    
    submit = (e) => {
        this.setState({ loading: true });
        setTimeout(() => {
            userService.login(this.state.username, this.state.password, this.success, this.fail);
        }, 1000);
    };

    submitPromjenaLozinke = (e) => {
        this.setState({ loading: true });
        api.post("forgottenpasswordsendmail?email=" + this.state.email, null, this.sendMailSuccess, this.sendMailFail);
    };

    sendMailSuccess = (data) => {
        if (data.Status == 200) {
            this.setState({ loading: false, mailsent: true, zaboravljenaLozinka: false });
        } else {
            this.setState({ loading: false, mailsent: false });
        }

        this.setState({
            errorMessage: data.Message,
            loading: false,
        });
    };

    sendMailFail = (data) => {
        this.setState({ loading: false });
    };

    fail = (ex) => {
        this.setState({
            errorMessage: (
                <div>
                    {" "}
                    Neispravno korisničko ime ili zaporka &nbsp;{" "}
                    {/* <span
                        onClick={() => {
                            this.setState({ zaboravljenaLozinka: true, errorMessage: "" });
                        }}
                        style={{ textDecoration: "underline", fontSize: "12px", color: "blue", cursor: "pointer" }}
                    >
                        zaboravljena lozinka
                    </span>{" "} */}
                </div>
            ),
            loading: false,
        });
    };

    success = (user) => {
        var self = this;
        if (user) {
            user.authdata = window.btoa(this.state.username + ":" + this.state.password);

            // Build the expiration date string:
            var expiration_date = new Date();
            var cookie_string = "";
            expiration_date.setFullYear(expiration_date.getFullYear() + 1);
            // Build the set-cookie string:
            cookie_string = "user=" + JSON.stringify(user) + "; path=/; expires=" + expiration_date.toUTCString();
            // Create or update the cookie:
            document.cookie = cookie_string;

            // let backto = sessionStorage.getItem("propisi_backto");

            // if (backto) {
            //     if (backto === "home") {
            //         window.location = "/";
            //     }
            //     if (backto.endsWith("/")) {
            //         window.location = "/";
            //     } else if (backto.indexOf("/confirm") > 0) {
            //         window.location = "/";
            //     } else {
            //         window.location = backto;
            //     }
            // }

            window.location = "/";
            //window.location.reload();
            if (self.props.success) self.props.success();

            if (this.state.returnPropisUID != null) window.location = "/propis?uid=" + this.state.returnPropisUID + "&ttid=" + this.state.returnPropisTTID + (this.state.returnPropisDp ? "&dp=" + encodeURIComponent(this.state.returnPropisDp) : "");
        }
    };

    usernameChanged = (value) => {
        this.setState({ username: value.target.value });
    };

    passwordChanged = (value) => {
        this.setState({ password: value.target.value });
    };

    emailChanged = (value) => {
        this.setState({ email: value.target.value });
    };

    componentDidUpdate(data, data2) {
   
    }
    render() {
        return (
            <Row type="flex" justify="center" align="middle" style={{ minHeight: "80vh" }}>
                <div>
                    {this.state.mailsent ? (
                        <Form style={{ width: "250px" }} name="normal_login" className="login-form" initialValues={{}} onFinish={this.onFinish}>
                            {this.state.errorMessage != "" ? (
                                <Form.Item>
                                    <Alert message={this.state.errorMessage}></Alert>
                                </Form.Item>
                            ) : null}
                        </Form>
                    ) : null}

                    {this.state.zaboravljenaLozinka ? (
                        <Form style={{ width: "250px" }} name="normal_login" className="login-form" initialValues={{}} onFinish={this.onFinish}>
                            <div style={{ fontSize: "12px", textAlign: "center" }}>Molimo unesite e-mail adresu na koju ćemo poslati link za obnavljanje zaporke</div>
                            <br />
                            <Form.Item name="email" rules={[{ required: true, message: "Molimo unesite e-mail adresu na koju ćemo poslati link za obnavljanje zaporke " }]}>
                                <Input onChange={this.emailChanged} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="e-mail" />
                            </Form.Item>
                            <Form.Item>
                                <Button onClick={this.submitPromjenaLozinke} loading={this.state.loading} style={{ width: "100%" }} className="search-button">
                                    Pošalji
                                </Button>
                            </Form.Item>

                            {this.state.errorMessage != "" ? (
                                <Form.Item>
                                    <Alert message={this.state.errorMessage}></Alert>
                                </Form.Item>
                            ) : null}
                        </Form>
                    ) : null}

                    {!this.state.zaboravljenaLozinka && !this.state.mailsent ? (
                        <Form style={{ width: "250px" }} name="normal_login" className="login-form" initialValues={{}} onFinish={this.onFinish}>
                            <Form.Item name="username" rules={[{ required: true, message: "Molimo unesite korisničko ime!" }]}>
                                <Input onChange={this.usernameChanged} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Korisničko ime" />
                            </Form.Item>
                            <Form.Item name="password" rules={[{ required: true, message: "Molimo unesite zaporku!" }]}>
                                <Input onChange={this.passwordChanged} prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Zaporka" />
                            </Form.Item>

                            <Form.Item style={{ textAlign: "center" }}>
                                <Button onClick={this.submit} loading={this.state.loading} style={{ width: "100%" }} className="search-button">
                                    Prijava postojećeg korisnika
                                </Button>

                                <Button onClick={() => {
                                        window.location = "/registracija";
                                        if (this.state.returnPropisUID == null) {
                                            window.location = "/registracija";
                                        } else {
                                            window.location = "/registracija?uid=" + this.state.returnPropisUID + "&ttid=" + this.state.returnPropisTTID + (this.state.returnPropisDp ? "&dp=" + encodeURIComponent(this.state.returnPropisDp) : "");
                                        }
                                    }} style={{ width: "100%", marginTop: "10px"}} className="search-button">
                                    Novi korisnik
                                </Button> 

                                <span
                                    onClick={() => {
                                        this.setState({ zaboravljenaLozinka: true, errorMessage: "" });
                                        window.location = "/loginsendmail";
                                    }}
                                    style={{ textDecoration: "underline", margin: "10px", display: "block", fontSize: "12px", color: "#989898", cursor: "pointer" }}
                                >
                                    zaboravljena zaporka
                                </span>
                            </Form.Item>
                            {this.state.errorMessage != "" ? (
                                <Form.Item>
                                    <Alert message={this.state.errorMessage}></Alert>
                                </Form.Item>
                            ) : null}
                        </Form>
                    ) : null}
                </div>
            </Row>
        );
    }
}

export default Login;
