import React from "react";
import util from "../utils/util";

class CookieConsent extends React.Component {
    constructor(props) {
        super(props);

        let prihvaceno = util.getCookie("maxlexhrcookieconsent");

        this.state = { prihvaceno: prihvaceno };
    }

    cookieName = "maxlexhrcookieconsent";

    onClick = () => {
        var expiration_date = new Date();
        expiration_date.setFullYear(expiration_date.getFullYear() + 100);

        util.setCookie(this.cookieName, "true", expiration_date);
        this.setState({ prihvaceno: true });
    };

    render() {
        return this.state.prihvaceno ? null : (
            <div className="cookie-consent">
                Web stranica lexhr.eu koristi kolačiće (Cookies) u svrhu poboljšanja Vašeg korisničkog iskustva. Uz daljnje korištenje, prihvaćate korištenje kolačića.
                <br />
                <br />
                <button onClick={() => this.onClick()} className="search-button">
                    Prihvaćam
                </button>
            </div>
        );
    }
}

export default CookieConsent;
