import React from "react";
import { Tooltip, Button } from "antd";
class Uputa extends React.Component {
    render() {

        return (
            <Tooltip title={this.props.text}>
                <Button className="upute">Upute</Button>
            </Tooltip>
        );
    }
}

export default Uputa;
