import React from "react";
import $ from "jquery";
import { Row, Col, Dropdown, Menu, Spin, Tooltip, Modal, Button } from "antd";
import { CheckOutlined, EyeOutlined } from "@ant-design/icons";
import api from "../utils/api";
import userService from "../utils/userService";
import util from "../utils/util";
import moment from "moment";
import ListaPropisa from "./listapropisa";

import { CaretDownOutlined, EllipsisOutlined } from "@ant-design/icons";

class PretragaPropisi extends React.Component {
    constructor(props) {
        super(props);

        window.addEventListener("resize", this.updateDimensions);

        this.state = {
            user: userService.getLoggedInUser(),
            isMobileDevice: util.isMobileDevice(),
            set: [],
        };
    }

    updateDimensions = () => {
        this.setState({ isMobileDevice: util.isMobileDevice() });
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    otvoriPropis = (item) => {
        let pretragaDatum = this.props.pretragaDatum;
        if (this.props.pretragaDatum && !moment(pretragaDatum, "DD.MM.YYYY.", true).isValid()) {
            pretragaDatum = "";
        }

        window.location = "/propis?uid=" + item.UID + "&ttid=2&dp=" + pretragaDatum;
        if (this.props.otvoriPropisCallback) {
            this.props.otvoriPropisCallback(item);
        }
    };

    otvoriProcisceniPropis = (item, e) => {
        let pretragaDatum = this.props.pretragaDatum;
        if (this.props.pretragaDatum && !moment(pretragaDatum, "DD.MM.YYYY.", true).isValid()) {
            pretragaDatum = "";
        }

        e.stopPropagation();
        if (!item.ImaAzuriranoTijelo || item.ImaAzuriranoTijelo === 0) {
            Modal.warning({
                title: "maxlex.hr",
                content: "nema ažuriranog tijela...",
            });
            return;
        }

        e.stopPropagation();
        window.location = "/propis?uid=" + item.UID + "&ttid=1&dp=" + pretragaDatum;

        if (this.props.otvoriPropisCallback) {
            this.props.otvoriPropisCallback(item);
        }
    };

    collapse = (item, e) => {
        e.stopPropagation();
        $("#collapse" + item.UID).toggle();
        $(".downbutton" + item.UID).toggle();
        $(".upbutton" + item.UID).toggle();
    };

    openSet = (uid, e) => {
        e.stopPropagation();

        //this.setState({ showSetModal: true, setLoading: true });
        window.location = "/propisiset?UID=" + uid;

        //api.get("propis/getset/" + propisID, this.setSuccess, this.setFail);
    };

    setSuccess = (data) => {
        this.setState({ set: data, setLoading: false });
    };

    setFail = () => {};

    hideSet = () => {
        this.setState({ showSetModal: false, setLoading: false });
    };

    handleMenuClick = (item, e) => {
        if (item == null) return;

        e.domEvent.stopPropagation();
        if (e.key == "1") {
            this.openSet(item.UID, e.domEvent);
        }

        if (e.key == "2") {
            this.otvoriProcisceniPropis(item, e.domEvent);
        }
    };

    render() {
        var self = this;
        var altColor = false;
        var gpid = null;
        var isGlavni = false;
        let buffer = [];

        var menu = (item) => {
            return (
                <Menu forceRender={true} onClick={(e) => this.handleMenuClick(item, e)}>
                    <Menu.Item key="1">Uredi</Menu.Item>
                    <Menu.Item key="2">Pročišćeni tekst</Menu.Item>
                </Menu>
            );
        };

        let test = menu(null);
        let searchSourceID = 0;
        return (
            <div>
                {self.props.propisi.map(function (item, i) {
                    isGlavni = item.GlavniPropisID === null || item.GlavniPropisID === "";
                    let naziv = item.PropisNaziv.replace("[*]", util.tooltip("Temeljni propis koji mijenja drugi temeljni propis."));
                    //logika za mijenjanje backgroundcolor po grupama
                    if (!isGlavni) {
                        if (gpid != item.GlavniPropisID) {
                            altColor = !altColor;
                        }
                    } else {
                        if (item.PropisID != gpid) {
                            altColor = !altColor;
                        }
                    }
                    //##############################################

                    gpid = item.GlavniPropisID || item.PropisID;

                    //var className = isGlavni ? "temeljni-zakon border-bottom noselect " : "izmjena noselect";

                    var className = "izmjena noselect";

                    //if (altColor) className = isGlavni ? "temeljni-zakon border-bottom noselect alt-color" : "izmjena noselect alt-color";

                    //ako je tip pretrage po tekstu onda nema grupiranja
                    //if (self.props.tipPretrage == 2) {
                    className += " border-bottom";
                    //}

                    return (
                        <div key={"indiv" + i}>
                            {/* {searchSourceID === 0 ? <Row className={className}  style={{ padding: "0px!important",textAlign:"left" }} > <Col offset={2} span={12} >Rezultati primarne pretrage</Col></Row>: null } */}
                            {!self.state.isMobileDevice ? (
                                <Row className={className} onClick={() => self.otvoriPropis(item)} style={{ padding: "0px!important" }}>
                                    <Col span={2} style={{ fontSize: "9px", textAlign: "center", fontWeight: "regular" }}>
                                        {/* {isGlavni ? ">" : ""} */}
                                    </Col>
                                    <Col span={2} style={{ textAlign: "left" }}>
                                        <p>{"NN " + item.NovineBroj + "/" + item.NovineGodina.toString().slice(-2)}</p>
                                    </Col>
                                    <Col span={3} style={{ textAlign: "left" }}>
                                        <p>{item.DatumStupanjaNaSnagu ? moment(item.DatumStupanjaNaSnagu).format("DD.MM.YYYY") : null}</p>
                                    </Col>
                                    <Col span={13} style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: naziv }}></Col>
                                    <Col span={self.state.isMobileDevice ? 6 : 4} style={{ textAlign: "left" }}>
                                        {/* {item.OriginalHit === 1 ? <CheckOutlined /> : null} */}

                                        {self.props.showSetButtons && (
                                            <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Klikom na UREDI dobije se popis temeljnog zakona i svih s njim povezanih izmjena i dopuna, sortirani opadajućim redoslijedom prema datumu stupanja na snagu.">
                                                <button className="list-button" onClick={(e) => self.openSet(item.UID, e)}>
                                                    {/* <EyeOutlined /> */}
                                                    Uredi
                                                </button>
                                            </Tooltip>
                                        )}

                                        {!isGlavni ? (
                                            <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Tekst pročišćenog propisa koji uključuje temeljni propis i sve njegove izmjene i dopune do zaključno odabranog propisa.">
                                                <button
                                                    onClick={(e) => {
                                                        self.otvoriProcisceniPropis(item, e);
                                                    }}
                                                    className="list-button"
                                                >
                                                    Pročišćeni tekst
                                                </button>
                                            </Tooltip>
                                        ) : null}

                                        <Modal onCancel={self.hideSet} width={800} forceRender={true} visible={self.state.showSetModal} footer={null}>
                                            {!self.state.setLoading ? (
                                                <ListaPropisa propisi={self.state.set}></ListaPropisa>
                                            ) : (
                                                <div>
                                                    <Spin />
                                                </div>
                                            )}
                                        </Modal>
                                    </Col>
                                    
                                </Row>
                            ) : (
                                <Row className={"list-row-mobile"} onClick={() => self.otvoriPropis(item)} style={{ padding: "10px!important" }}>
                                    <Col span={24} style={{ textAlign: "left" }} className={"small-fonts"}>
                                        {"NN " + item.NovineBroj + "/" + item.NovineGodina.toString().slice(-2)} &nbsp; {item.DatumStupanjaNaSnagu ? moment(item.DatumStupanjaNaSnagu).format("DD.MM.YYYY") : null}
                                    </Col>
                                    <Col span={24} style={{ textAlign: "left", padding: "5px" }} dangerouslySetInnerHTML={{ __html: item.PropisNaziv }}></Col>
                                    {/* <Col span={1} style={{ paddingRight: "3px" }}>
                                        <CheckOutlined />
                                    </Col> */}
                                    {/* <Col span={9} style={{ padding: "3px" }}>
                                        <button
                                            style={{ width: "100%", height: 23 }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            className="btn-mobile"
                                        >
                                            {"NN " + item.NovineBroj + "/" + item.NovineGodina.toString().slice(-2)}
                                        </button>
                                    </Col>
                                    <Col span={9} style={{ padding: "3px" }}>
                                        <button
                                            style={{ width: "100%", height: 23 }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            className="btn-mobile"
                                        >
                                            {item.DatumStupanjaNaSnagu ? moment(item.DatumStupanjaNaSnagu).format("DD.MM.YYYY") : null}
                                        </button>
                                    </Col> */}

                                    {/* <Col span={6} style={{ padding: "3px" }}>
                                        <Dropdown forceRender={true} 
                                            overlay={
                                                <Menu onClick={(e) => this.handleMenuClick(item, e)}>
                                                    <Menu.Item key={"ditem1-" + i}>Uredi</Menu.Item>
                                                    <Menu.Item key={"ditem2-" + i}>Pročišćeni tekst</Menu.Item>
                                                </Menu>
                                            }
                                        >
                                            <button
                                                style={{ width: "100%", height: 23 }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                className="btn-mobile"
                                            >
                                                <EllipsisOutlined />
                                            </button>
                                        </Dropdown>
                                    </Col> */}
                                    <Col span={12} style={{ padding: "3px" }}>
                                        {self.props.showSetButtons && (
                                            <button style={{ width: "100%" }} onClick={(e) => self.openSet(item.UID, e)} className="btn-mobile">
                                                Uredi
                                            </button>
                                        )}
                                    </Col>
                                    <Col span={12} style={{ padding: "3px" }}>
                                        {!isGlavni ? (
                                            <button
                                                style={{ width: "100%" }}
                                                onClick={(e) => {
                                                    self.otvoriProcisceniPropis(item, e);
                                                }}
                                                className="btn-mobile"
                                            >
                                                Pročišćeni tekst
                                            </button>
                                        ) : null}
                                    </Col>
                                </Row>
                            )}
                        </div>
                    );

                    // if (self.props.tipPretrage == 2) {
                    //     return buffer;
                    // } else {
                    //     if (isGlavni) {
                    //         isGlavni = false;
                    //         try {
                    //             return (
                    //                 <div key={"L" + i} className={"grupa"}>
                    //                     {buffer}
                    //                 </div>
                    //             );
                    //         } finally {
                    //             buffer = [];
                    //         }
                    //     }
                    // }
                })}
            </div>
        );
    }
}

export default PretragaPropisi;
