import React from "react";
import $ from "jquery";
import { Input, Row, Col, DatePicker, Form, Radio, Pagination, Collapse, Select, Checkbox, Tooltip } from "antd";
import Propis from "./propis";
import Spinner from "./spinner";
import Uputa from "./uputa";
import PretragaPropisi from "./pretragapropisi";
import moment from "moment";
import api from "../utils/api";
import { Button } from "antd";
import util from "../utils/util";
import Search from "antd/lib/input/Search";
import less from "less";
import { SearchOutlined, CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet-async";
import ScriptTag from "react-script-tag";
//import ReactGA from 'react-ga';

const { Panel } = Collapse;
const { Option } = Select;

class Pretraga extends React.Component {
    constructor(props) {
        super(props);

        //ReactGA.initialize('G-HDGXG586VC');
        //ReactGA.pageview(window.location.pathname + window.location.search);

        let pretragaText,
            brojNarodnihNovina,
            datumStupanjaNaSnagu,
            godina,
            godinaBrojNN,
            godine,
            godineLoading,
            maxGodina,
            naprednoPretrazivanje,
            pageNumber = null,
            pageSize,
            propisiData,
            resultsCount,
            searchType,
            tocanNaziv;

        godine = [];
        //if (this.props.loadFromStorage || true) {
        brojNarodnihNovina = util.toEmptyString(sessionStorage.getItem("broj-narodnih-novina"));
        datumStupanjaNaSnagu = util.toEmptyString(sessionStorage.getItem("datum-objave"));
        godina = util.toEmptyString(sessionStorage.getItem("narodne-novine-godina"));
        naprednoPretrazivanje = util.toEmptyString(sessionStorage.getItem("napredno-pretrazivanje"));
        pageNumber = parseInt(sessionStorage.getItem("pretraga-pageNumber")) || 1;
        pageSize = sessionStorage.getItem("pretraga-pageSize") || 10;
        pretragaText = util.toEmptyString(sessionStorage.getItem("pretraga-text"));
        propisiData = JSON.parse(sessionStorage.getItem("propisi-data"));
        resultsCount = sessionStorage.getItem("pretraga-resultsCount") || 0;
        searchType = sessionStorage.getItem("pretraga-searchType") ? parseInt(sessionStorage.getItem("pretraga-searchType")) : 1;
        tocanNaziv = sessionStorage.getItem("pretraga-tocan-naziv") == "true" ? true : false;
        //}

        searchType = searchType || 1;

        let loading = false;

        if (pretragaText === null || pretragaText === undefined) pretragaText = "";

        if (!propisiData) propisiData = [];

        let pretragaResource = JSON.parse(sessionStorage.getItem("pretraga-godinenn"));
        if (pretragaResource && pretragaResource.GodinaBrojNN) {
            for (let i = 1990; i <= pretragaResource.MaxGodina; i++) {
                godine.push({ label: i, value: i });
            }
            godinaBrojNN = pretragaResource.GodinaBrojNN;
            maxGodina = parseInt(pretragaResource.MaxGodina);
            godineLoading = false;
        } else {
            this.resetGodine();
        }

        window.addEventListener("resize", this.updateDimensions);

        this.state = {
            activeItems: ["1"],
            broj: "",
            brojLabel: "",
            brojNarodnihNovina: brojNarodnihNovina || "",
            brojNarodnihNovinaDisabled: false,
            datumStupanjaNaSnagu: datumStupanjaNaSnagu,
            datumStupanjaNaSnaguDisabled: false,
            errorMessage: "",
            errors: [],
            firstSearch: false,
            godina: godina,
            godinaBrojNN: godinaBrojNN,
            godinaDisabled: false,
            godine: [],
            godineLoading: godineLoading,
            isMobileDevice: util.isMobileDevice(),
            loading: loading,
            maxGodina: maxGodina,
            naprednoPretrazivanje: naprednoPretrazivanje,
            offset: util.isMobileDevice() ? 1 : 3,
            pageNumber: pageNumber,
            pageSize: 50,
            pretragaText: pretragaText,
            propisi: propisiData,
            resultsCount: resultsCount,
            searchPlaceholder: "Unesite pojam...",
            searchType: searchType,
            selectedPropis: {},
            sortOrder: "1",
            span: util.isMobileDevice() ? 22 : 18,
            spinnerText: "pretraga u tijeku...",
            tocanNaziv: tocanNaziv,
        };
    }

    updateDimensions = () => {
        this.setState({ isMobileDevice: util.isMobileDevice() });
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.stopSearch != prevProps.stopSearch) {
            if (this.ajaxPost) this.ajaxPost.abort();

            this.setState({ pretragaText: "", loading: false, selectedParameters: "" });
            this.clearAdvancedFields();

            $("#searchText").prop("disabled", false);

            if (!this.state.isMobileDevice) $("#searchText").focus();
        }
    };

    clearAdvancedFields() {
        this.setState({ propisi: [], stopSearch: true, firstSearch: false, godina: "", brojNarodnihNovina: "", datumStupanjaNaSnagu: "", tocanNaziv: false, sortOrder: "1" });
    }

    componentDidMount = () => {
        if (this.props.loadFromStorage || true) {
            let scrollTop = sessionStorage.getItem("scroll-top") || 0;
            //$("#lista").scrollTop(scrollTop);
            //window.scrollBy(0, scrollTop);
        }

        // window.dataLayer = window.dataLayer || [];
        // function gtag(){dataLayer.push(arguments);}
        // gtag('js', new Date());
        // gtag('config', 'G-HDGXG586VC');

        setTimeout(() => {
            if (!this.state.isMobileDevice) $("#searchText").focus();
        }, 500);
    };

    saveSettings = () => {
        try {
            sessionStorage.setItem("pretraga-text", this.state.pretragaText);
            sessionStorage.setItem("pretraga-pageSize", this.state.pageSize);
            sessionStorage.setItem("pretraga-pageNumber", this.state.pageNumber);
            sessionStorage.setItem("pretraga-resultsCount", this.state.resultsCount);
            sessionStorage.setItem("pretraga-searchType", this.state.searchType || 1);
            sessionStorage.setItem("datum-objave", this.state.datumStupanjaNaSnagu);
            sessionStorage.setItem("propisi-data", JSON.stringify(this.state.propisi));
            sessionStorage.setItem("broj-narodnih-novina", this.state.brojNarodnihNovina || "");
            sessionStorage.setItem("narodne-novine-godina", this.state.godina || "");
            sessionStorage.setItem("napredno-pretrazivanje", this.state.naprednoPretrazivanje);
            sessionStorage.setItem("pretraga-tocan-naziv", this.state.tocanNaziv);
            //let scrollTop = $(document.body).scrollTop();

            sessionStorage.setItem("scroll-top", window.pageYOffset);
        } catch (e) {
            alert(e);
        }
    };

    clear = () => {
        this.setState({ pretragaText: "" });
        this.searchPropis("", 1);
    };

    search = () => {
        this.searchPropis(this.state.pretragaText, 1);
    };

    searchPropis = function (value, pageNumber) {
        if (this.ajaxPost) this.ajaxPost.abort();

        //let nnb = this.state.naprednoPretrazivanje ? this.state.brojNarodnihNovina + "/" + this.state.godina : "";
        let nnb = this.state.brojNarodnihNovina + "/" + this.state.godina;
        if (nnb === "/") nnb = "";

        let searchText = value || this.state.pretragaText;
        let errors = [];

        //let tocanNaziv = this.state.naprednoPretrazivanje ? this.state.tocanNaziv : false;
        let tocanNaziv = this.state.tocanNaziv;

        if (this.state.godina && isNaN(this.state.godina)) errors.push("Neispravan format godine");

        if (this.state.godina && parseInt(this.state.godina) < 1990 && !isNaN(this.state.godina)) errors.push("Godina ne može biti manja od 1990");

        if (this.state.godina && parseInt(this.state.godina) > parseInt(this.state.maxGodina) + 2 && !isNaN(this.state.godina)) errors.push("Godina ne može biti veća od " + (parseInt(this.state.maxGodina) + 2));

        let neispravanDatum = false;

        if (this.state.datumStupanjaNaSnagu && !moment(this.state.datumStupanjaNaSnagu, "DD.MM.YYYY.", true).isValid()) {
            errors.push("Neispravan format datuma stupanja na snagu");
            neispravanDatum = true;
        }

        // if (!this.state.naprednoPretrazivanje) {
        //     if (!searchText) {
        //         this.setState({ searchPlaceholder: "Molimo unesite traženi pojam..." });
        //         errors.push("Molimo unesite traženi pojam");
        //         $("#searchText").focus();
        //     }
        // } else {
        if (!searchText) {
            if (this.state.datumStupanjaNaSnagu && !neispravanDatum) {
                this.setState({ searchPlaceholder: "Molimo unesite traženi pojam..." });
                errors.push("Molimo unesite traženi pojam");
                $("#searchText").focus();
            } else {
                if (this.state.datumStupanjaNaSnagu && neispravanDatum) {
                    this.setState({ searchPlaceholder: "Molimo unesite traženi pojam..." });
                    errors.push("Molimo unesite traženi pojam");
                    $("#searchText").focus();
                } else {
                    if (!nnb) {
                        this.setState({ searchPlaceholder: "Molimo unesite traženi pojam..." });
                        errors.push("Molimo unesite traženi pojam");
                        $("#searchText").focus();
                    }
                }
            }

            if (this.state.searchType == "2" && nnb) {
                this.setState({ searchPlaceholder: "Molimo unesite traženi pojam..." });
                errors.push("Molimo unesite traženi pojam");
                $("#searchText").focus();
            }
        } else {
        }
        // }

        this.setState({ errors: errors });

        if (errors.length > 0) {
            this.setState({ naprednoPretrazivanje: true });
            return;
        }

        if (errors.length > 0) {
            return;
        }

        this.setState({ searchPlaceholder: "Unesite pojam..." });
        if (value === "undefined" || value === undefined) {
            value = undefined;
        } else {
            //value = encodeURIComponent(value);
        }

        if (!pageNumber) pageNumber = 1;

        this.setState({ propisi: [], errorMessage: "" });
        this.setState({ firstSearch: true });
        util.clearSettings();

        let sortOrder = this.state.naprednoPretrazivanje ? this.state.sortOrder : "RANK DESC, DatumStupanjaNaSnagu DESC";
        let datumStupanjaNaSnagu = moment(new Date(3000, 10, 10, 10)).toDate();

        if (this.state.datumStupanjaNaSnagu) {
            datumStupanjaNaSnagu = moment(this.state.datumStupanjaNaSnagu, "DD.MM.YYYY").toDate();
        }

        if (searchText + nnb === "" && !this.state.datumStupanjaNaSnagu) {
            this.setState({ loading: false });
            return;
        }

        var url = "propis/search";

        let qData = {
            SearchText: searchText,
            NovineBroj: nnb,
            DatumStupanjaNaSnagu: datumStupanjaNaSnagu,
            SortOrder: sortOrder,
            PageNumber: pageNumber,
            PageSize: this.state.pageSize,
            SearchType: this.state.searchType,
            TocanNaziv: tocanNaziv,
        };

        this.setState({ loading: true });

        this.ajaxPost = api.post(
            url,
            JSON.stringify(qData),
            (data) => {
                // if(this.state.stopSearch){
                //     this.setState({stopSearch: false});
                //     return;
                // }

                if (data.ErrorMessage) {
                    this.setState({ errorMessage: data.ErrorMessage });
                    return;
                }

                let propisi = data.Results;

                propisi.forEach((item, index) => {
                    propisi[index].PropisNaziv = propisi[index].PropisNaziv.replace("[*]", util.tooltip("Temeljni propis koji mijenja drugi temeljni propis."));
                });

                this.setState({ propisi: propisi, resultsCount: data.Count, loading: false });
                if (data.Results.length > 0) this.setState({ pageNumber: pageNumber });

                this.saveSettings();
            },

            (ex) => {
                this.setState({ errorMessage: "Došlo je do greške. Molimo pokušajte ponovno kasnije. - " + ex.status + " - " + ex.statusText + " - STATUS CODE:" + ex.statusCode() + " - RESPONSE TEXT: " + ex.responseText });
            }
        );
    };

    ajaxPost = null;

    onKeyPress = (event) => {
        if (event.keyCode === 13 || event.charCode === 13) {
            this.search();
        }
    };

    click = () => {
        this.setState({ activeItems: ["3"] });
    };

    timer = null;

    logoClick = () => {
        util.clearSettings();
        this.setState({ propisi: [], hideLogo: false });
    };

    onSearchTextChange = (val, e) => {
        //var self = this;
        // clearTimeout(self.timer);
        this.setState({ pretragaText: val.target.value });

        // self.timer = setTimeout(() => {
        //     self.searchPropis();
        // }, 500);
    };

    onNarodneNovineChange = (value, e) => {
        if (value.target.value || this.state.godina) {
            this.setState({ datumStupanjaNaSnaguDisabled: true, datumStupanjaNaSnagu: "" });
        } else {
            this.setState({ datumStupanjaNaSnaguDisabled: false });
        }
        this.setState({ brojNarodnihNovina: value.target.value });
    };

    onDatumChange = (value, stringValue) => {
        if (value.target.value) {
            this.setState({ godinaDisabled: true, brojNarodnihNovinaDisabled: true, godina: "", brojNarodnihNovina: "" });
        } else {
            this.setState({ godinaDisabled: false, brojNarodnihNovinaDisabled: false });
        }

        if (value) this.setState({ datumStupanjaNaSnagu: value.target.value });
    };

    onSortChange = (value, stringValue) => {
        if (value) this.setState({ sortOrder: value });
    };

    otvoriPropis = (data) => {
        this.saveSettings();
    };

    prosiriPropis = (propisID) => {
        $("#" + propisID).toggle();
        $("#arrowup" + propisID).toggle();
        $("#arrowdown" + propisID).toggle();
    };

    onPageChange = (page) => {
        this.setState({ pageNumber: page });

        this.searchPropis(this.state.pretragaText, page);
    };

    searchType = 1;

    onSearchTypeChange = (e) => {
        this.setState({ searchType: e.target.value, selectedParameters: "" });

        this.searchType = e.target.value;

        if (e.target.value == 1) {
            this.setState({ sortOrder: "1" });
        }
        this.refreshSelectedParametersLabel();

        $("#searchText").focus();
    };

    onTocanNazivOnChange = (e) => {
        this.setState({ tocanNaziv: e.target.checked });
    };

    naprednoPretrazivanje = false;

    onNaprednaPretragaChange = () => {
        let np = !this.state.naprednoPretrazivanje;
        this.naprednoPretrazivanje = np;
        this.setState({ naprednoPretrazivanje: np });
        this.refreshSelectedParametersLabel();
    };

    refreshSelectedParametersLabel() {
        if (this.naprednoPretrazivanje) {
            //otvoreno
            //reset polja
            this.setState({ selectedParameters: "" });
        } else {
            let p1 = this.state.datumStupanjaNaSnagu ? "Propisi koji su stupli na snagu do: " + this.state.datumStupanjaNaSnagu : null;
            let p2 = this.state.godina ? "Godina objave: " + this.state.godina : null;
            let p3 = this.state.brojNarodnihNovina ? "Broj Narodnih novina: " + this.state.brojNarodnihNovina : null;
            let p4 = this.searchType == 2 ? (this.state.tocanNaziv ? "Točan navod cijelih riječi" : "") : "";
            this.setState({ selectedParameters: (p4 ? p4 + ";" : "") + (p1 ? p1 + ";" : "") + (p2 ? p2 + ";" : "") + (p3 ? p3 + ";" : "") });
            //zatvoreno
        }
    }

    resetGodine = () => {
        let godine = [{ label: "", value: "" }];

        //console.log(pretragaResource.GodinaBrojNN);
        api.get(
            "pretraga/get",
            (data) => {
                //console.log(data.GodinaBrojNN);
                sessionStorage.setItem("pretraga-godinenn", JSON.stringify(data));
                for (let i = 1990; i <= data.MaxGodina; i++) {
                    godine.push({ label: i, value: i });
                }
                this.setState({ godinaBrojNN: data.GodinaBrojNN, maxGodina: data.MaxGodina, godine: godine, godineLoading: false });
            },
            (ex) => {}
        );
        // }
    };

    onGodinaChange = (value) => {
        if (value.target.value.length > 4) return;

        if (value.target.value || this.state.brojNarodnihNovina) {
            this.setState({ datumStupanjaNaSnaguDisabled: true, datumStupanjaNaSnagu: "" });
        } else {
            this.setState({ datumStupanjaNaSnaguDisabled: false });
        }

        let br = this.state.godinaBrojNN ? this.state.godinaBrojNN[value.target.value] : null;

        this.setState({ godina: value.target.value, brojLabel: br ? "(1 do " + br + ")" : "" });
    };

    // onBrojChange = (e) => {
    //     this.setState({broj: e.target.value})
    // }

    searchText = React.createRef();

    render() {
        let self = this;
        const dateFormat = "DD.MM.YYYY";
        let astbTextStyle = this.state.isMobileDevice ? { width: "100%" } : { width: "250px" };
        let searchColStyleLeft = { textAlign: "right", paddingRight: "20px", paddingBottom: "15px" };
        let searchColStyleRight = this.state.isMobileDevice ? { paddingLeft: "20px", paddingBottom: "15px", textAlign: "center" } : { textAlign: "left", paddingLeft: "20px", paddingBottom: "15px" };
        let searchRowStyle = { minHeight: "50px" };
        let fieldSpan = this.state.isMobileDevice ? 24 : 12;

        let npHeader = (
            <>
                <div>Napredno pretrživanje</div>
                <div>{this.state.selectedParameters}</div>
            </>
        );
        return (
            <div style={{}}>
                <Helmet>
                    <title>LEXhr - Zbirka izvornih i pročišćenih propisa Republike Hrvatske</title>
                    <meta name="description" content="Pretraga propisa"></meta>
                </Helmet>
                {/* <ScriptTag isHydrating={true} type="text/javascript" onLoad={()=>this.go()} src="https://www.googletagmanager.com/gtag/js?id=G-HDGXG586VC" /> */}
                <Row className="search-box fadein">
                    <Col offset={self.state.offset} span={self.state.span}>
                        <Row>
                            <Col className="opis" span={24}>
                                <h1 className="pretraga-naslov">Zbirka izvornih i pročišćenih propisa Republike Hrvatske</h1>
                                {/* <span dangerouslySetInnerHTML={{ __html: util.tooltip("Pročišćeni propisi test tooltip") }}></span> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={this.state.isMobileDevice ? 22 : 16} offset={this.state.isMobileDevice ? 1 : 4}>
                                <Radio.Group style={{ marginBottom: "10px" }} onChange={this.onSearchTypeChange} value={this.state.searchType}>
                                    <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Traže se svi propisi koji u nazivu sadrže ključne pojmove. Rezultat pretrage se može filtrirati po datumu stupanja na snagu, godini objave i broju Narodnih novina. Ako se ne unese traženi pojam, traže se svi propisi po kriterijima naprednog pretraživanja.">
                                        <Radio value={1}>Traži po nazivu</Radio>
                                    </Tooltip>
                                    <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Traže se svi propisi koji u tekstu sadrže traženi pojam ili niz pojmova (pretraga uvijek daje rezultate po pasusima propisa). Pretraga se može raditi i tako da se unese npr. dio ili cijela rečenice iz nekog propisa koji se želi pronaći. Rezultat pretraga se može filtrirati po točnom navodu cijelih riječi, datumu stupanja na snagu, godini objave i broju Narodnih novina. Rezultat pretraga se može sortirati po relevantnosti, datumu objave (silazno) i datumu objave (uzlazno).">
                                        <Radio value={2}>Traži po tekstu</Radio>
                                    </Tooltip>
                                </Radio.Group>
                                <div className="search-wrap">
                                    <div className="search">
                                        <input type="text" disabled={this.state.loading} id="searchText" ref={this.searchText} className="searchTerm" onKeyPress={(value) => self.onKeyPress(value)} value={this.state.pretragaText} onChange={(value) => self.onSearchTextChange(value)} placeholder={this.state.searchPlaceholder} />
                                        <button type="submit" className="searchButton" onClick={() => this.search()}>
                                            <SearchOutlined />
                                        </button>
                                    </div>
                                </div>
                                {/* {!this.state.isMobileDevice ? <Uputa text="neke upute..."></Uputa> : null} */}

                                {!this.state.isMobileDevice ? (
                                    <span className="search-tooltip">
                                        ?
                                        <div className="tooltip-left" style={{ width: 1000 }}>
                                            <div style={{ fontWeight: "bold" }}>Najvažnija pravila pretrage</div>
                                            <ol style={{ textAlign: "left" }}>
                                                <li>uvijek se upisuju cijele riječi. Alat za pretraživanje kod većine riječi automatski uzima u obzir i padežne nastavke.</li>
                                                <li>upisivanje više cijelih riječi rezultira bržim i točnijim rezultatom pretraživanja</li>
                                                <li>izbjegavati unos samo jedne riječi u onim slučajevima za koje se zna da bi se ta riječ mogla nalaziti u velikom broju propisa, što dovodi do duljeg vremena pretraživanja, (npr. "zakon", "pravilnik" i sl.)</li>
                                                <li>pretraga u tijeku zaustavlja se klikom na gumb „Nova pretraga“</li>
                                            </ol>
                                        </div>
                                    </span>
                                ) : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "left" }} span={this.state.isMobileDevice ? 24 : 16} offset={this.state.isMobileDevice ? 0 : 4}>
                                <span onClick={() => this.onNaprednaPretragaChange()} className={"noselect"} style={{ textAlign: "left", cursor: "pointer", fontSize: 12, marginLeft: 10 }}>
                                    napredno pretraživanje {this.state.naprednoPretrazivanje && <CaretUpOutlined />} {!this.state.naprednoPretrazivanje && <CaretDownOutlined />}
                                    <span className="fadein"> {this.state.selectedParameters}</span>
                                </span>

                                {this.state.naprednoPretrazivanje && (
                                    <div className="fadein" style={{ fontSize: "10px", textAlign: "left", marginTop: "50px" }}>
                                        {this.state.searchType == "2" ? (
                                            <Row style={searchRowStyle}>
                                                <Col span="12" style={searchColStyleLeft}>
                                                    <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Traži se propis koji u tekstu sadrži upisani pojam u navedenom obliku.">
                                                        Točan navod cijelih riječi
                                                    </Tooltip>
                                                </Col>
                                                <Col span="12" style={searchColStyleRight}>
                                                    <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Traži se propis koji u tekstu sadrži upisani pojam u navedenom obliku.">
                                                        <Checkbox label="Točan naziv" onChange={this.onTocanNazivOnChange} checked={this.state.tocanNaziv}></Checkbox>
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        ) : null}

                                        <Row style={searchRowStyle}>
                                            <Col span={fieldSpan} style={searchColStyleLeft}>
                                                <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Traže se svi propisi kojima je datum stupanja na snagu manji ili jednak tom datumu. Unosom datuma u ovo polje više se ne mogu popunjavati polja „Godina objave“ i „Broj Narodnih novina“.">
                                                    Propisi koji su stupili na snagu do <br></br>(dd.mm.gggg.)
                                                </Tooltip>
                                            </Col>
                                            <Col span={fieldSpan} style={searchColStyleRight}>
                                                {/* <DatePicker
                                                    allowClear={false}
                                                    className="search-input"
                                                    disabled={this.state.brojNarodnihNovina && this.state.brojNarodnihNovina !== ""}
                                                    style={astbTextStyle}
                                                    onChange={function (value) {
                                                        self.onDatumChange(value);
                                                    }}
                                                    defaultValue={moment(this.state.DatumStupanjaNaSnagu, dateFormat)}
                                                    format={dateFormat}
                                                /> */}
                                                <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Traže se svi propisi kojima je datum stupanja na snagu manji ili jednak tom datumu. Unosom datuma u ovo polje više se ne mogu popunjavati polja „Godina objave“ i „Broj Narodnih novina“.">
                                                    <Input disabled={this.state.loading} placeholder="dd.mm.gggg." onChange={this.onDatumChange} style={astbTextStyle} value={this.state.datumStupanjaNaSnagu} onPressEnter={() => this.search()} allowClear={true} />
                                                </Tooltip>
                                            </Col>
                                        </Row>

                                        {/* <Row style={searchRowStyle}>
                                            <Col span="12" style={searchColStyleLeft}>
                                                Narodne novine broj/godina
                                            </Col>
                                            <Col span="12" style={searchColStyleRight}>
                                                <Input placeholder="br./g  prm: 77/21" onChange={self.onNarodneNovineChange} style={astbTextStyle} defaultValue={this.state.brojNarodnihNovina} onPressEnter={() => this.search()} allowClear={true} />
                                            </Col>
                                        </Row> */}
                                        <Row style={searchRowStyle}>
                                            <Col span={fieldSpan} style={searchColStyleLeft}>
                                                <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Traže se svi propisi objavljeni u nekoj godini. Moguće je popunjavati i polje „Broj narodnih novina“. Ako je zadana godina, u nazivu polja „Broj Narodnih novina“ pojavljuje se oznaka koliko je brojeva Narodnih novina objavljeno u zadanoj godini. Ako je popunjeno polje „Godina objave“, nije moguće popuniti polje „Propisi koji su stupili na snagu do“.">
                                                    Godina objave<br></br>(od 1990)
                                                </Tooltip>
                                            </Col>
                                            <Col span={fieldSpan} style={searchColStyleRight}>
                                                {/* <Select notFoundContent={"Učitavanje podataka"} allowClear loading={this.state.godineLoading} onClick={this.resetGodine} value={this.state.godina} style={astbTextStyle} options={this.state.godine} onChange={this.onGodinaChange}></Select> */}
                                                <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Traže se svi propisi objavljeni u nekoj godini. Moguće je popunjavati i polje „Broj narodnih novina“. Ako je zadana godina, u nazivu polja „Broj Narodnih novina“ pojavljuje se oznaka koliko je brojeva Narodnih novina objavljeno u zadanoj godini. Ako je popunjeno polje „Godina objave“, nije moguće popuniti polje „Propisi koji su stupili na snagu do“.">
                                                    <Input disabled={this.state.loading} placeholder={"1990 do " + this.state.maxGodina} onChange={this.onGodinaChange} style={astbTextStyle} value={this.state.godina} onPressEnter={() => this.search()} allowClear={true} />
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                        <Row style={searchRowStyle}>
                                            <Col span={fieldSpan} style={searchColStyleLeft}>
                                                <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Traže se propisi određenog broja Narodnih novina bez obzira na godinu objave. Kad se popunjava ovo polje, nije moguće popuniti polje „Propisi koji su stupili na snagu do“.">
                                                    Broj Narodnih novina {<b>{this.state.brojLabel}</b>}
                                                </Tooltip>
                                            </Col>
                                            <Col span={fieldSpan} style={searchColStyleRight}>
                                                <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Traže se propisi određenog broja Narodnih novina bez obzira na godinu objave. Kad se popunjava ovo polje, nije moguće popuniti polje „Propisi koji su stupili na snagu do“.">
                                                    <Input disabled={this.state.loading} placeholder={this.state.brojLabel} onChange={this.onNarodneNovineChange} style={astbTextStyle} value={this.state.brojNarodnihNovina} onPressEnter={() => this.search()} allowClear={true} />
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                        {this.state.searchType == "2" ? (
                                            <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Rezultat pretrage može se sortirati po relevantnosti, datumu objave (silazno) i datumu objave (uzlazno). Sortiranje po relevantnosti je sortiranje po broju nađenih pojmova u odnosu na ukupan broj pojmova.">
                                                <Row style={searchRowStyle}>
                                                    <Col span={fieldSpan} style={searchColStyleLeft}>
                                                        Sortiraj po
                                                    </Col>
                                                    <Col span={fieldSpan} style={searchColStyleRight}>
                                                        <Select style={astbTextStyle} value={this.state.sortOrder} onChange={self.onSortChange}>
                                                            <Option value="1">relevantnosti</Option>
                                                            <Option value="2">datumu objave (silazno)</Option>
                                                            <Option value="3">datumu objave (uzlazno)</Option>
                                                        </Select>{" "}
                                                    </Col>
                                                </Row>
                                            </Tooltip>
                                        ) : null}
                                        <Row style={searchRowStyle}>
                                            <Col span={fieldSpan} style={{ textAlign: "right" }}></Col>
                                            <Col span={fieldSpan} style={searchColStyleRight}>
                                                <input type="button" onClick={() => this.search()} style={{ width: "100px" }} className="search-button" value="Traži" />
                                            </Col>
                                        </Row>
                                        <Row style={searchRowStyle}>
                                            {this.state.errors.length > 0 && (
                                                <Col span="24">
                                                    <ul className="search-errors">
                                                        {this.state.errors.map((item, index) => {
                                                            return <li key={"err" + index}>{item}</li>;
                                                        })}
                                                    </ul>
                                                </Col>
                                            )}
                                        </Row>
                                    </div>
                                )}

                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row>
                    {self.state.propisi.length > 0 && !this.state.isMobileDevice ? (
                        <Col offset={self.state.offset} span={self.state.span}>
                            <Row className="pretraga-header">
                                <Col span={2}></Col>
                                <Col span={2}>Broj Narodnih novina</Col>
                                <Col span={3}>Datum stupanja na snagu</Col>
                                <Col span={13}>Naziv</Col>
                            </Row>
                        </Col>
                    ) : null}
                </Row>

                <Row id="lista">
                    {this.state.loading ? (
                        <Col align="middle" offset={self.state.offset} span={self.state.span}>
                            <Row type="flex" justify="center" align="middle">
                                {this.state.errorMessage !== "" ? <div className="search-errors">{this.state.errorMessage}</div> : <Spinner text={this.state.spinnerText} />}
                            </Row>
                        </Col>
                    ) : self.state.propisi.length > 0 ? (
                        <Col offset={self.state.offset} align={"center"} span={self.state.span} className=" fadein">
                            <PretragaPropisi showSetButtons tipPretrage={this.state.searchType} pretragaDatum={this.state.datumStupanjaNaSnagu} propisi={self.state.propisi} otvoriPropisCallback={this.otvoriPropis}></PretragaPropisi>
                            <Pagination style={{ marginTop: "20px" }} size="small" total={this.state.resultsCount} onChange={this.onPageChange} current={this.state.pageNumber} pageSize={this.state.pageSize} showSizeChanger={false} />
                            <p style={{ fontSize: "0.8em" }}>Broj rezultata {this.state.resultsCount}</p>
                        </Col>
                    ) : (
                        <Col align="center" offset={self.state.offset} span={self.state.span}>
                            {this.state.firstSearch && this.state.errors.length === 0 ? <div style={{ fontSize: "0.8em", color: "#595959" }}>nema rezultata...</div> : null}
                        </Col>
                    )}
                </Row>
                <Row className="odabrani-row">
                    <Col Col span={this.state.isMobileDevice ? 22 : 8} offset={this.state.isMobileDevice ? 1 : 8} align={"center"} className="odabrani fadein">
                        <Collapse className="odabrani-collapse">
                            <Panel header="Lista odabranih propisa (A-Z)" className="odabrani-header">
                                <ul className="odabrani-list-item">
                                    <li>
                                        <a href="/propis?uid=a2c34a56-1cbe-4e43-8df8-a37b9640e58b&ttid=1&dp=" title="Kazneni zakon" className="list-group-item">
                                            Kazneni zakon
                                        </a>
                                    </li>
                                </ul> 
                                <ul className="odabrani-list-item">
                                    <li>
                                        <a href="/propis?uid=cad13ab4-1ac4-4088-b04e-3c5a8611e4db&ttid=1&dp=" title="Zakon o gradnji" className="list-group-item">
                                            Zakon o gradnji
                                        </a>
                                    </li>
                                </ul>
                                <ul className="odabrani-list-item">
                                    <li>
                                        <a href="/propis?uid=c3e87cb4-5aaa-47dd-99fd-08d6685029ec&ttid=1&dp=" title="Zakon o obveznim odnosima" className="list-group-item">
                                            Zakon o obveznim odnosima
                                        </a>
                                    </li>
                                </ul>
                                <ul className="odabrani-list-item">
                                    <li>
                                        <a href="/propis?uid=e1e75c52-0060-477b-af6d-424ad6941552&ttid=1&dp=" title="Zakon o porezu na dodanu vrijednost" className="list-group-item">
                                            Zakon o porezu na dodanu vrijednost
                                        </a>
                                    </li>
                                </ul>
                                <ul className="odabrani-list-item">
                                    <li>
                                        <a href="/propis?uid=44072945-5ccd-47f1-aa72-b28798edd0eb&ttid=1&dp=" title="Zakon o radu" className="list-group-item">
                                            Zakon o radu
                                        </a>
                                    </li>
                                </ul>
                                <ul className="odabrani-list-item">
                                    <li>
                                        <a href="/propis?uid=a420d6a5-e607-46ac-ae89-ec54087ee78d&ttid=1&dp=" title="Zakon o trgovačkim društvima" className="list-group-item">
                                            Zakon o trgovačkim društvima
                                        </a>
                                    </li>
                                </ul>
                                <ul className="odabrani-list-item">
                                    <li>
                                        <a href="/propis?uid=ba0574bf-349d-4380-8b7e-20842a98879b&ttid=2&dp=" title="Naputak o načinu provođenja mjera kontrole zdravlja životinja propisanih Naredbom o mjerama zaštite životinja od zaraznih i nametničkih bolesti i njihovom financiranju u 2017. godini" className="list-group-item">
                                        Naputak o načinu provođenja mjera kontrole zdravlja životinja propisanih Naredbom o mjerama zaštite životinja od zaraznih i nametničkih bolesti i njihovom financiranju u 2017. godini
                                        </a>
                                    </li>
                                </ul>
                                
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </div>

            
        );
    }
}

export default Pretraga;
