import React from "react";
import $ from "jquery";
import api from "../utils/api";

//ovo je samo za plaćanje putem web forme, jer priprema za plaćanje putem tokena ide api pozivom u što je uključen i paymentPreparation i samo plaćanje
class WSPayForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Labela: "Plaćanje u tijeku...",
            CustomerFirstName: "",
            CustomerLastName: "",
            CustomerAddress: "",
            CustomerCity: "",
            CustomerZIP: "",
            CustomerCountry: "",
            CustomerPhone: "",
            CustomerEmail: "",
            ShopID: "",
            ShoppingCartID: "",
            TotalAmount: "",
            Signature: "",
            ShopVersion: "",
            URL: "",
            SuccessURL: "",
            CancelURL: "",
            ErrorURL: "",
            IsTokenRequest:0,
            paymentInPreparation: false     
        }
}

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.paymentModel && this.state.paymentInPreparation === false) {
            this.setState({paymentInPreparation: true});
            this.pm = this.props.paymentModel;

            this.paymentPreparation();
        }
    };

    componentDidMount = () => {
        if (this.props && this.props.location && this.props.location.state && this.props.location.state.paymentModel) {
            // this.pm = this.props.location.state.paymentModel;
            // this.paymentPreparation();
        } else {
            this.setState({ Labela: "Nema podataka za plaćanje" });
            //window.history.back();
        }
    };

    pm = {};

    paymentPreparation = () => {
        let data = {
            KorisnikID: this.pm.KorisnikID,
            UID: this.pm.UID,
            TipPlacanjaID: this.pm.TipPlacanjaID,
            DokumentFormatID: 0,
            ModelPretplateID: this.pm.ModelPretplateID,
            TokenPaymentForThisPropisOnly: this.pm.TokenPaymentForThisPropisOnly,
            WsPayOrderID: "",
            TipTijelaID: this.pm.TipTijelaID,
            PretragaDatum: this.pm.Datum
        };

        api.post("paymentpreparation", JSON.stringify(data), this.paymentPreparationSuccess, this.paymentPreparationFail);

        // let signatureData = {
        //     TotalAmount: 17,
        //     ShoppingCartID: 78,
        //     ShopID: "MYSHOP",
        //     ShopType: 1,
        //     SignatureType: 0
        // }
        // api.post("signature", JSON.stringify(signatureData), this.paymentPreparationSuccess, this.paymentPreparationFail);
    };

    paymentPreparationSuccess = (data) => {

        //todo: ovdje bi trebala ići logika prepoznavanja, ako nešto nije valjalo data ima int polje Status i ErrorMessage
        //a osim toga, tj. s uspješnim statusom, može se dogoditi da je PaymentDetails prazan, ako nije potrebno ići u naplatu

        if(data.Status == 0){
            if(data && data.PaymentDetails && data.PaymentDetails.Customer){
                this.setState({
                    CustomerFirstName: data.PaymentDetails.Customer.CustomerFirstName,
                    CustomerLastName: data.PaymentDetails.Customer.CustomerLastName,
                    CustomerAddress: data.PaymentDetails.Customer.CustomerAddress,
                    CustomerCity: data.PaymentDetails.Customer.CustomerCity,
                    CustomerZIP: data.PaymentDetails.Customer.CustomerZIP,
                    CustomerCountry: data.PaymentDetails.Customer.CustomerCountry,
                    CustomerPhone: data.PaymentDetails.Customer.CustomerPhone,
                    CustomerEmail: data.PaymentDetails.Customer.CustomerEmail,
                    ShopID: data.ShopID,
                    ShoppingCartID: data.PaymentDetails.ShoppingCartID,
                    TotalAmount:  data.PaymentDetails.TotalAmount.toString().replace(".", ","),
                    Signature: data.Signature,
                    ShopVersion: data.ShopVersion,
                    URL: data.URL,
                    SuccessURL: data.SuccessURL,
                    CancelURL: data.CancelURL,
                    ErrorURL: data.ErrorURL,
                    IsTokenRequest: this.pm.IsTokenRequest
                })
        
             

                let id = this.props.id ? "#" + this.props.id : "#wspayform";
                $(id).submit();
        
            }
            else{
                console.log("API request succeeded but something is missing in response.")
            }    
        }
        else{
           
        }

       

        if(this.props.onSuccess)
            this.props.onSuccess(data);

    }

    paymentPreparationFail = (data) => {
        if(this.props.onError)
            this.props.onError(data);
        

       
        //message.error("Priprema za plaćanje pute web forme nije uspjela: " + data.responseText);
    };

    render() {
        return (
            <div>
                <form name="pay" id={this.props.id || "wspayform"} action={this.state.URL} method="POST">
                    <input type="hidden" name="CustomerFirstName" value={this.state.CustomerFirstName} />
                    <input type="hidden" name="CustomerLastName" value={this.state.CustomerLastName} />
                    <input type="hidden" name="CustomerAddress" value={this.state.CustomerAddress} />
                    <input type="hidden" name="CustomerCity" value={this.state.CustomerCity} />
                    <input type="hidden" name="CustomerZIP" value={this.state.CustomerZIP} />
                    <input type="hidden" name="CustomerCountry" value={this.state.CustomerCountry} />
                    <input type="hidden" name="CustomerPhone" value={this.state.CustomerPhone} />
                    <input type="hidden" name="CustomerEmail" value={this.state.CustomerEmail} />
                    <input type="hidden" name="ShopID" value={this.state.ShopID} />
                    <input type="hidden" name="ShoppingCartID" value={this.state.ShoppingCartID} />
                    <input type="hidden" name="Version" value={this.state.ShopVersion} />
                    <input type="hidden" name="TotalAmount" value={this.state.TotalAmount} />
                    <input type="hidden" name="Signature" value={this.state.Signature} />
                    <input type="hidden" name="ReturnURL" value={this.state.SuccessURL} />
                    <input type="hidden" name="CancelURL" value={this.state.CancelURL} />
                    <input type="hidden" name="ReturnErrorURL" value={this.state.ErrorURL} />
                    <input type="hidden" name="IsTokenRequest" value={this.state.IsTokenRequest} />
                    {/* <input type="submit" value="Platite kreditnom karticom"/>  */}
                </form>
            </div>
        );
    }
}
export default WSPayForm;
