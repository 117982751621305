import React from "react";
import { Row, Alert } from "antd";
import api from "../utils/api";
import util from "../utils/util";
import SpinnerFixed from "./spinnerfixed";
import UserProfile from "./userprofile";
import userService from "../utils/userService";

class RegistracijaConfirm extends React.Component {
    constructor(props) {
        super(props);
        let params = util.getParametersFromUrl(window.location);
        this.state = {loading:false, uspjesnaRegistracija: true, registrationUsername: params.username, registrationToken: params.token, returnPropisUID: params.uid, returnPropisTTID: params.ttid, returnPropisDp: params.dp };
        userService.logout();
        
    }

    confirmationSuccess = (data) => {
        this.setState({ uspjesnaRegistracija: true });
        //window.location = "/login";
        userService.login(this.state.registrationUsername, this.state.registrationToken, this.loginSuccess, this.loginFail);
    };

    confirmationFail = (data) => {
        this.setState({ uspjesnaRegistracija: false, loading: false  });
    };

    loginSuccess = (user) => {
        user.authdata = window.btoa(this.state.registrationUsername + ":" + this.state.registrationToken);
     
        // Build the expiration date string:
        var expiration_date = new Date();
        var cookie_string = '';
        expiration_date.setFullYear(expiration_date.getFullYear() + 1);
        // Build the set-cookie string:
        cookie_string = "user=" + JSON.stringify(user) + "; path=/; expires=" + expiration_date.toUTCString();
        // Create or update the cookie:
        document.cookie = cookie_string;

        this.setState({loading:false, uspjesnaRegistracija: true });

        if (this.state.returnPropisUID == null) {
            window.location = "#";
            window.location.reload();
        } else {
            window.location = "#/propis?uid=" + this.state.returnPropisUID + "&ttid=" + this.state.returnPropisTTID + (this.state.returnPropisDp ? "&dp=" + this.state.returnPropisDp : "");
            window.location.reload();
        }
    };

    loginFail = (data) => {
        
    };

    onSave = (data) => {
        let newData = data;
        newData.RegistrationUsername = this.state.registrationUsername;
        newData.RegistrationToken = this.state.registrationToken;
        this.setState({loading: true});
        api.post("confirmation", JSON.stringify(newData), this.confirmationSuccess, this.confirmationFail);
    }

    render() {
        return (
            <div ref={this.wrapper}>
                {this.state.uspjesnaRegistracija ? 
                <div>
                    <Alert  message="Uspješno ste se registrirali. Molimo ispunite osobne podatke." type="success" showIcon /> 
                    <UserProfile firstRegistration={true} onSave={this.onSave}></UserProfile>
                    <SpinnerFixed show={this.state.loading} text="Registracija u tijeku..."></SpinnerFixed>
                </div>: null}
                

                
            </div>
        );
    }
}

export default RegistracijaConfirm;
