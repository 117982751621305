import React from "react";
import { Form, Input, Row, Col, Button, Divider, Alert } from "antd";
import api from "../utils/api";
import Spinner from "../components/spinner";
import util from "../utils/util";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 12 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

class Registracija extends React.Component {
    constructor(props) {
        super(props);
        let params = util.getParametersFromUrl(window.location.href);
        this.state = {
            username: "",
            password: "",
            passwordConfirm: "",
            responseText: "",
            gotovaRegistracija: false,
            returnPropisUID: params.uid, 
            returnPropisTTID: params.ttid,
            returnPropisDp: params.dp
        };
    }

    register = () => {};

    onFinish = (values) => {
        this.submit();
    };

    onFinishFailed = (errorInfo) => {
        
    };

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    };

    submit = () => {
        this.setState({ loading: true });
        let data = {
            username: this.state.username,
            password: this.state.password,
            uid: this.state.returnPropisUID,
            ttid: this.state.returnPropisTTID,
            dp: encodeURIComponent(this.state.returnPropisDp)
        };
        api.post("register", JSON.stringify(data), this.success, this.fail);
    };

    success = (data) => {
     
 
            this.setState({
                loading: false,
                gotovaRegistracija: true,
                responseText: data,
            });
     
    };

    fail = (data) => {
   
        let text = data.responseJSON;
        if(text==="Invalid password."){
            text =  <div style={{textAlign:"left"}}>Zaporka se mora sastojati od:<br />
             - minimalno 6 znamenki<br />
            - barem jednog malog slova<br />
            - barem jednog velikog slova<br />
            - barem jedne brojčane oznake<br />
            i može sadržavati jedan ili više sljedećih znakova \.,;:_()[]-"<br /></div>;
        }
        this.setState({ loading: false, responseText: text });
      
        let formData = {username: this.state.username, password: this.state.password, passwordConfirm: this.state.passwordConfirm};

        this.frmRegistracija.current.setFieldsValue(formData);

    };


    frmRegistracija = React.createRef();

    render() {
        return (
            <Row
                type="flex"
                justify="center"
                align="middle"
                style={{
                    minHeight: "100vh",
                }}
            >
                {this.state.gotovaRegistracija ? (
                    <Row>
                        <Alert
                            justify="center"
                            style={{
                                minWidth: "100%",
                                marginBottom: "20px",
                            }}
                            showIcon
                            message={this.state.responseText}
                            type="warning"
                        />
                    </Row>
                ) : (
                    <Row>
                        <br></br>
                        <br></br>

                        {this.state.loading ? (
                            <Col align="center" offset={6} span={12} id="propisi">
                                <Spinner />
                            </Col>
                        ) : (
                            <Form style={{ textAlign: "center", width: "300px" }} ref={this.frmRegistracija} layout="vertical" scrollToFirstError onFinish={this.onFinish} onFinishFailed={this.onFinishFailed} validateTrigger="onBlur">
                                <Form.Item
                                    name="username"
                                    label="Korisničko ime (e-mail)"
                                    onChange={this.onChange}
                                    rules={[
                                        {
                                            type: "email",
                                            message: "Neispravna e-mail adresa!",
                                        },
                                        {
                                            required: true,
                                            message: "Molimo unesite E-mail!",
                                        },
                                    ]}
                                >
                                    <Input id="username" />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    label="Zaporka"
                                    onChange={this.onChange}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Molimo unesite zaporku!",
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item
                                    name="passwordConfirm"
                                    label="Potvrdi zaporku"
                                    onChange={this.onChange}
                                    dependencies={["password"]}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: "Molimo potvrdite zaporku!",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue("password") === value) {
                                                    return Promise.resolve();
                                                }

                                                // let isValid = util.isValidPassword(value);

                                                // if (isValid !== "ok") {
                                                //     return Promise.reject(isValid);
                                                // }

                                                return Promise.reject("Zaporke se ne podudaraju!");
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Row>
                                    {this.state.responseText !== "" ? (
                                        <Alert
                                            justify="center"
                                            style={{
                                                minWidth: "100%",
                                                marginBottom: "20px",
                                            }}
                                            showIcon
                                            message={this.state.responseText}
                                            type="warning"
                                        />
                                    ) : null}
                                </Row>
                                <Form.Item>
                                    <Button className="search-button" type="primary" htmlType="submit">
                                        Registriraj se
                                    </Button>
                                </Form.Item>
                            </Form>
                        )}
                    </Row>
                )}
            </Row>
        );
    }
}

export default Registracija;
