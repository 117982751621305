import React from "react";
import { Modal, Button, Row, Col, Divider, message } from "antd";
import api from "../utils/api";
import $ from "jquery";
import WsPayForm from "./wspayform";
import SpinnerFixed from "./spinnerfixed";

class Pretplata extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            visiblePrekidObnove: false,
            tokenValid: false,
            pm: null,
            pagination: false,
            visiblePayment: false,
            captionButton2: "Nova pretplata",
            loading: true,
        };
        api.get("sifarnici/get?requesterID=2", this.sifarniciSuccess, this.sifarniciFail);
    }

    pm = {};

    sifarniciSuccess = (data) => {
        if (data && data.Korisnik) {
            this.tipModelaPretplateOptions = data.TipModelaPretplate;
            this.setState({ tokenValid: data.Korisnik.TokenValid });

            this.tekstPretplataConfirmTitle = data.PretplataTekstovi[0];
            this.tekstPretplataConfirm = data.PretplataTekstovi[1];
            this.tekstPrekidConfirmTitle = data.PretplataTekstovi[2];
            this.tekstPrekidConfirm = data.PretplataTekstovi[3];
            this.pm.IsTokenRequest = data.Korisnik.IsTokenRequest;

            this.pm.TipPlacanjaID = data.Korisnik.TokenValid == true && data.Korisnik.TipStatusaBrzogPlacanjaID == 1 ? 3 : 2;
            this.pm.KorisnikID = data.Korisnik.KorisnikID;
            this.pm.ModelPretplateID = 0;
            this.pm.UID = null;
            this.pm.TokenPaymentForThisPropisOnly = null;

            api.get("pretplate/get", this.getSuccess, this.getFail);
        } else {
            console.log("Sa servera nisu stigli podaci.");
        }
    };

    sifarniciFail = (data) => {
     
        this.setState({ loading: false });
    };

    tipModelaPretplateOptions = [];

    getSuccess = (data) => {
        

        if (data.Status.Status === 401) {
            this.setState({ notAuthorized: true });
        }

        if (data.Status.Status === 200) {
            if (data && data.Pretplate) {
                this.setState({
                    pretplate: data.Pretplate.Pretplate,
                    brojSlobodnihPropisa: data.Pretplate.BrojSlobodnihPropisa,
                    brojPropisaIzPrethodnihPretplata: data.Pretplate.BrojSlobodnihPropisa - data.Pretplate.BrojSlobodnihPropisaUNajnovijojAktivnojPretplati,
                    brojPropisaUOvomPaketu: data.Pretplate.BrojSlobodnihPropisaUNajnovijojAktivnojPretplati,
                    nazivPretplate: data.Pretplate.NajnovijaAktivnaPretplataNaziv,
                    datumPocetka: data.Pretplate.NajnovijaAktivnaPretplataDatum,
                    datumZavrsetka: data.Pretplate.NajnovijaAktivnaPretplataDatumDo,
                    renewalActive: data.Pretplate.RenewalActive,
                    renewalModel: data.Pretplate.RenewalModel,
                    renewalModelID: data.Pretplate.RenewalModelID,
                    pagination: (data.Pretplate.Pretplate == null ? false : data.Pretplate.Pretplate.length < 5) ? false : true,
                    loading: false,
                });
                if (this.state.nazivPretplate == "") {
                    this.setState({
                        visiblePayment: true,
                        captionButton2: "Sakrij pakete pretplate",
                    });
                }
            }
        } else {
            this.responseNotValid(data);
        }
    };

    responseNotValid = (data) => {
       
        this.setState({ loading: false });
    };

    getFail = (data) => {
        this.responseNotValid(data);

        if (data.responseText) alert(JSON.parse(data.responseText).Message);
    };

    onPrekidClicked = () => {
       
        api.post("pretplate/prekid", null, this.onPrekidSuccess, this.onPrekidFail);
    };

    onPrekidSuccess = (data) => {
        if (data.Status === 200) {
            

            api.get("pretplate/get", this.getSuccess, this.getFail);

            let msg = data.Message;

            if (data.Status == 200) {
                let config = { content: msg, duration: 5 };
                message.success(config);
            } else {
                message.error(msg);
            }
        } else {
            this.responsePretplateNotValid(data);
        }
    };

    responsePretplateNotValid = (data) => {
 
        message.error("Prekid pretplate nije uspio: " + data.responseText);
    };

    onPrekidFail = (data) => {
        this.responsePretplateNotValid(data);

        if (data.responseText) alert(JSON.parse(data.responseText).Message);
    };

    onChange(value) {
        this.pm.ModelPretplateID = value;
        this.setState({ tipModelaPretplateID: value });
    }

    onBlur() {

    }

    onFocus() {
     
    }

    onSearch(val) {
        
    }

    validateMessages = {
        required: "${label} je obvezan podatak!",
        pattern: {
            mismatch: "${name} je niz e-mail adresa odvojenih s točka-zarezom",
        },
    };

    showPrekidObnoveModal = () => {
        if (!this.state.renewalActive) {
            message.error({
                content: "Prekid auto-obnove podrazumijeva da postoji aktivna automatski obnavljajuća pretplata. U ovom trenutku nema takve pretplate.",
                className: "custom-class",
                duration: 3,
                style: {
                    marginTop: "8em",
                    maxWidth: "40%",
                    marginLeft: "30vw",
                },
            });
            return;
        }

        this.setState({
            visiblePrekidObnove: true,
        });
        $("#Prekid").html(this.tekstPrekidConfirm);
    };

    handlePrekidObnoveOk = (e) => {
        this.setState({
            visiblePrekidObnove: false,
        });
        this.onPrekidClicked();
    };

    showModels = () => {
        this.setState({
            visiblePayment: !this.state.visiblePayment,
            captionButton2: this.state.visiblePayment ? "Nova pretplata" : "Sakrij pakete pretplate",
        });
    };

    showModal = (value) => {
        this.pm.ModelPretplateID = value;
        this.setState({
            visible: true,
            tipModelaPretplateID: value,
        });
        $("#Nova").html(this.tekstPretplataConfirm);
    };

    handleOk = (e) => {
        this.setState({
            visible: false,
        });
        this.buyConfirmed();
        // this.test();
    };

    handleCancel = (e) => {
        this.setState({
            visible: false,
            visiblePrekidObnove: false,
        });
    };

    // test = () => {
    //     api.get("transaction/finish?tipTransakcijeID=1&UID={35D31602-4C29-4CED-B394-F3BB9A3A713E}", this.tokenPaymentSuccess, this.tokenPaymentFail)
    // }

    buyConfirmed = () => {
        if (this.pm.TipPlacanjaID == 3) {
            //wstokenpayment radi i PaymentPreparation i plaćanje (ovo naravno važi samo za tokensko plaćanje)
            //api.post("korisnik/newsubscription?modelPretplateID=" + this.pm.ModelPretplateID, null, this.tokenPaymentSuccess, this.tokenPaymentFail);
            var location = window.location;
            localStorage.setItem("payment_backto", location.hash);
            //window.location = "/result?tt=1&UID={35D31602-4C29-4CED-B394-F3BB9A3A713E}";
            api.post("korisnik/newsubscription?modelPretplateID=" + this.pm.ModelPretplateID, null, this.tokenPaymentSuccess, this.tokenPaymentFail);
        } else {
            this.setState({ pm: this.pm });
        }
    };

    tokenPaymentSuccess = (data) => {
        window.location = "/result?tt=1&UID={" + data.PlacanjeUID + "}";
        // $("#test").html(data);
    };

    tokenPaymentFail = (data) => {
        message.error("One click plaćanje nije uspjelo: " + data.responseText);
    };

    pdf = (pretplata) => {
        window.location = process.env.REACT_APP_API + "racun/get/" + pretplata.PlacanjeID + "/1";
    };

    render() {
        var self = this;
        return self.state.notAuthorized ? null : (
            <Row type="flex" justify="center" align="middle" style={{ margin: "0%" }}>
                <SpinnerFixed show={this.state.loading} text="Učitavanje..."></SpinnerFixed>
                <WsPayForm id="wspayForm" paymentModel={this.state.pm}></WsPayForm>
                {this.state.nazivPretplate == "" ? (
                    <Row style={{ paddingTop: "1em", paddingBottom: "1em", paddingRight: "1em", marginRight: "5%", marginLeft: "5%", marginTop: "5%", backgroundColor: "beige", width: "100%", textAlign: "center", borderRadius: "15px" }}>
                        <Col span={23} offset={1}>
                            <div>Nema niti jedne pretplate!</div>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ paddingTop: "1em", paddingBottom: "1em", paddingRight: "1em", marginRight: "5%", marginLeft: "5%", marginTop: "5%", backgroundColor: "beige", borderRadius: "15px" }}>
                        <Col span={7} offset={1} style={{ paddingBottom: "6px" }}>
                            <div>Zadnji kupljeni paket pretplate:</div>
                        </Col>
                        <Col span={16}>
                            <div style={{ position: "absolute", bottom: "6px" }}>
                                <b>{this.state.nazivPretplate}</b>
                            </div>
                        </Col>
                        <Col span={7} offset={1} style={{ paddingBottom: "6px" }}>
                            <div>Datum kupnje zadnjeg paketa:</div>
                        </Col>
                        <Col span={16}>
                            <div style={{ position: "absolute", bottom: "6px" }}>
                                <b>{this.state.datumPocetka}</b>
                            </div>
                        </Col>
                        <Col span={7} offset={1} style={{ paddingBottom: "6px" }}>
                            <div>Status pretplate:</div>
                        </Col>
                        {this.state.datumZavrsetka != "" ? (
                            <Col span={16} style={{ paddingBottom: "6px" }}>
                                <div>
                                    Napravili ste prekid obnove pretplate datuma <b>{this.state.datumZavrsetka}</b>
                                </div>
                            </Col>
                        ) : (
                            <Col span={16} style={{ paddingBottom: "6px" }}>
                                <div>Automatska obnova je aktivna</div>
                            </Col>
                        )}
                        <Col span={7} offset={1} style={{ paddingBottom: "6px" }}>
                            <div>Ukupni broj propisa slobodnih za preuzimanje:</div>
                        </Col>
                        <Col span={16}>
                            <div style={{ color: "red", display: "inline", position: "absolute", bottom: "6px" }}>
                                <b>{this.state.brojSlobodnihPropisa}</b>
                            </div>
                        </Col>
                    </Row>
                )}
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex" style={{ display: "block", textAlign: "center", width: "100%", paddingTop: "1em" }}>
                    {this.state.nazivPretplate != "" ? (
                        <Col style={{ display: "inline-block" }}>
                            <Button style={{ width: "100%", minWidth: "180px" }} className="search-button" onClick={this.showPrekidObnoveModal}>
                                Prekid auto-obnove
                            </Button>
                        </Col>
                    ) : null}

                    <Col style={{ display: "inline-block" }}>
                        <Button style={{ width: "100%", minWidth: "180px" }} className="search-button" onClick={this.showModels}>
                            {this.state.captionButton2}
                        </Button>
                    </Col>
                </Row>
                {this.state.visiblePayment == true ? (
                    <div style={{ width: "100%" }}>
                        <Divider orientation="center" style={{ width: "100%", paddingRight: "5%", paddingLeft: "5%" }}>
                            Paketi pretplate (PDV uključen u cijenu)
                        </Divider>
                        <Row style={{ width: "100%" }}>
                            {this.tipModelaPretplateOptions.map(function (item, i) {
                                return (
                                    <Col key={item.value} span={24} style={{ textAlign: "center", minHeight: "15vh" }}>
                                        <Button className="search-button" style={{ minWidth: "50%", minHeight: "10vh", fontSize: "1.4em" }} onClick={() => self.showModal(item.value)}>
                                            {item.label}
                                            <br />
                                            <div style={{ fontSize: "0.65em" }}>{item.label2}</div>
                                        </Button>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                ) : null}

                <Modal title={this.tekstPretplataConfirmTitle} visible={this.state.visible} onOk={() => this.handleOk()} onCancel={this.handleCancel} okText="Kupi" cancelText="Odustani" forceRender={true}>
                    <div id="Nova"></div>
                </Modal>
                <Modal title={this.tekstPrekidConfirmTitle} visible={this.state.visiblePrekidObnove} onOk={this.handlePrekidObnoveOk} onCancel={this.handleCancel} okText="Prekini auto-obnovu" cancelText="Odustani" forceRender={true}>
                    <div id="Prekid"></div>
                </Modal>
                {/* </Form> */}
            </Row>
        );
    }
}

export default Pretplata;
