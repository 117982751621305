"use strict";
/**
 * Auth header is a helper function that returns an HTTP Authorization header containing
 * the basic authentication credentials (base64 username and password) of the currently logged in user from local storage.
 * If the user isn't logged in an empty object is returned.
 * The auth header is used to make authenticated HTTP requests to the server api using basic authentication.
 */
import userService from "../utils/userService";
import util from "./util";

// kada se s api-jem komunicira preko servisa (npr. user.service-a), uvijek će se pozvati metoda authHeader,
// koja �e provjeriti je li korisnik logiran, vratiti potrebne header-e i pustiti request prema api-ju
class AuthHeader {
    static authHeader() {
        var self = this;
        //let user = JSON.parse(localStorage.getItem("user"));
        let cooki = util.getCookie("user");

        let user = null;

        if (cooki) user = JSON.parse(cooki);

        // if(user && user.access_token)
        //     alert(user.access_token);

        if (user && user.access_token && user.authdata) {
            return {
                Authorization: "Bearer " + user.access_token,
                Accept: "application/json",
                "Content-Type": "application/json",
            };
        } else {
            return {
                "Content-Type": "application/json",
            };
        }

    }
}

export default AuthHeader;
