import React from "react";
import PuffLoader from "react-spinners/PuffLoader";

class SpinnerFixed extends React.Component {
    render() {
        return this.props.show ? (
            <div className="spinner-fixed" type="flex" justify="center" align="middle">
                <div style={{top:"50%",marginLeft:"-35px",left:"50%", marginTop:"-35px", position: "inherit", color:"lightgray"}}>
                    <PuffLoader size={70} color={"lightgray"} loading={true} />
                    {this.props.text}
                </div>
            </div>
        ) : null;
    }
}

export default SpinnerFixed;
