import React from "react";
import { Tabs, Modal, Checkbox, Table, DatePicker, Space, Form, Button, Row, Col, message, Tooltip, Switch, Select } from "antd";
import api from "../utils/api";
import { MailOutlined, BarChartOutlined, SendOutlined } from "@ant-design/icons";
import moment from "moment";
import util from "../utils/util";
import $ from "jquery";
import Spinner from "./spinner";
import Upute from "./upute";
const { Column, ColumnGroup } = Table;
const { RangePicker } = DatePicker;
const dateFormat = "DD.MM.YYYY";
const { TabPane } = Tabs;

class TeamMembers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination: false,
            selectedUsers: [],
            pozivnica: "",
            showErrorOnPozivnica: false,
            errorMessage: "",
            sendingPozivnica: false,
            isMobileDevice: util.isMobileDevice(),
            selectedRows: [{}],
            selectedRowKeys: [], // Check here to configure the default column
            includeMe: true,
            selectLabel: "Odznači sve",
            selectAllChecked: true,
            autoClear: false,
            pozivnicaMode: "tags",
            visiblePozivnica: false,
            loading: true,
            datumOd: null,
            datumDo: null,
        };

        api.get("sifarnici/get?requesterID=3", this.sifarniciSuccess, this.sifarniciFail);
    }

    sifarniciSuccess = (data) => {
        if (data) {
            this.tekstInvitationConfirm = data.Tekstovi[0];
            this.tekstInvitationConfirmTitle = data.Tekstovi[1];

            api.get("teammembers/get", this.getSuccess, this.getFail);
        } else {
            console.log("Sa servera nisu stigli podaci.");
        }
    };

    sifarniciFail = (data) => {
        
    };

    onFinishReport = (fieldsValue) => {
        // Should format date value before submit.
        // const rangeValue = fieldsValue['range-picker'];
        // const rangeTimeValue = fieldsValue['range-time-picker'];
        const values = {
            ...fieldsValue,
            // 'range-picker': [rangeValue[0].format(dateFormat), rangeValue[1].format(dateFormat)],
            datumOd: fieldsValue["datumOd"].format(dateFormat),
            datumDo: fieldsValue["datumDo"].format(dateFormat),
            // 'date-picker': fieldsValue['date-picker'].format('YYYY-MM-DD'),
            // 'date-time-picker': fieldsValue['date-time-picker'].format('YYYY-MM-DD HH:mm:ss'),
            // 'month-picker': fieldsValue['month-picker'].format('YYYY-MM'),

            // 'range-time-picker': [
            //     rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss'),
            //     rangeTimeValue[1].format('YYYY-MM-DD HH:mm:ss'),
            // ],
            // 'time-picker': fieldsValue['time-picker'].format('HH:mm:ss'),
        };
        console.log("Received values of form: ", values);

        let members = this.state.selectedRows.map((x) => x.KorisnikID);
        // let datumOd = values["range-picker"][0];
        // let datumDo = values["range-picker"][1];

        let datumOd = values["datumOd"];
        let datumDo = values["datumDo"];

        let data = {
            Members: members,
            DatumOd: datumOd,
            DatumDo: datumDo,
            IncludeMe: this.state.includeMe,
        };

        //api.post("teammembers/report", JSON.stringify(data), this.onReportSuccess, this.onReportFail)
        api.getReport("teammembers/report", data, this.onReportSuccess, this.onReportFail);
    };

    rangeConfig = {
        rules: [
            {
                type: "array",
                required: true,
                message: "Odaberite period!",
            },
        ],
    };

    validateMessages = {
        required: "${label} je obvezan!",
    };

    onReportSuccess = (data) => {
    
        let msg = data.Message;

        if (data.Status == 200) {
            let config = { content: msg };
            message.success(config);
        } else {
            message.error(msg);
        }
    };

    onReportFail = (data) => {
        console.log(data.Message); //ima i data.ResponseData
    };

    PozivnicaForm = React.createRef();
    ReportForm = React.createRef();

    getSuccess = (data) => {
        

        if (data.Status.Status === 200) {
            if (data.Members) {
                //***************  ovo je za prošli mjesec:
                // let dateTo = moment();
                // dateTo.set('date', 1);
                // dateTo.add(-1, 'days');

                // let dateFrom = moment();
                // dateFrom.set('date', 1);
                // dateFrom.add(-1, 'days');
                // dateFrom.add(-1,'months').add(1,'days');
                //****************************************

                //ovo je za ovaj mjesec
                let dateTo = moment();

                let dateFrom = moment();
                dateFrom.set("date", 1);

                this.setState({
                    members: data.Members,
                    pagination: data.Members.length < 5 ? false : true,
                    datumOd: dateFrom,
                    datumDo: dateTo,
                });

                let formData = { datumOd: this.state.datumOd, datumDo: this.state.datumDo };
                this.ReportForm.current.setFieldsValue(formData);

                var lista = this.state.members.map((x) => x.key);
                this.setState({
                    selectedRowKeys: lista,
                    selectAllChecked: true,
                    selectLabel: "Odznači sve",
                    loading: false,
                });
            } else {
                console.log("Nema podataka.");
            }

            this.setState({ sendingPozivnica: false });
        } else {
            this.responseTeamMembersNotValid(data);
        }
    };

    responseTeamMembersNotValid = (data) => {
        
        this.setState({ sendingPozivnica: false });
    };

    getFail = (data) => {
        this.responseTeamMembersNotValid(data);
        if (data.responseText) alert(JSON.parse(data.responseText).Message);
    };

    clicked = (member) => {
        let data = {
            korisnikID: member.KorisnikID,
        };
        api.post("teammembers/action?korisnikID=" + member.KorisnikID, null, this.actionSuccess, this.actionFail);
    };

    actionSuccess = (data) => {
        if (data.Status === 200) {
            
            api.get("teammembers/get", this.getSuccess, this.getFail);
            let msg = data.Message;

            if (data.Status == 200) {
                let config = { content: msg };
                message.success(config);
            } else {
                message.error(msg);
            }
        } else {
            this.responseNotValid(data);
        }
    };

    responseNotValid = (data) => {
        
        message.error("Akcija nije uspjela: " + data.responseText);
    };

    actionFail = (data) => {
        this.responseNotValid(data);
        if (data.responseText) alert(JSON.parse(data.responseText).Message);
    };

    validateMessages = {
        required: "${name} je obvezan podatak!",
        pattern: {
            mismatch: "${name} ove e-mail adrese je nepotpuna ili pogrešna!",
        },
    };

    onFinish = (values) => {
        console.log(values);
        this.setState({ sendingPozivnica: true });
        api.post("teammembers/invitation?emailList=" + this.state.pozivnica, null, this.onFinishSuccess, this.onFinishFail);
    };

    onFinishSuccess = (data) => {
        if (data.Status.Status === 200) {
            
            this.setState({ visiblePozivnica: false, selectedUsers: [] });
            this.PozivnicaForm.current.resetFields();
            api.get("teammembers/get", this.getSuccess, this.getFail);
            let msg = data.Status.Message;

            if (data.Status.Status === 200) {
                let config = { content: msg };
                message.success(config);
            } else {
                message.error(msg);
            }
        } else {
            this.responsePozivnicaNotValid(data);
        }
    };

    responsePozivnicaNotValid = (data) => {
        this.setState({ sendingPozivnica: false });
        
        message.error("Obrada pozivnica nije uspjela: " + data.responseText);
        this.setState({ visiblePozivnica: false });
    };

    onFinishFail = (data) => {
        this.responsePozivnicaNotValid(data);
        if (data.responseText) alert(JSON.parse(data.responseText).Message);
    };

    onPozivnicaInputKeyDown = (data) => {
        if (data.key == "Enter") {
            if (this.state.pozivnicaMode == "multiple") {
                this.setState({ showErrorOnPozivnica: true, errorMessage: "Neispravan unos" });
                setTimeout(() => {
                    this.setState({ showErrorOnPozivnica: false });
                }, 1000);
            }
        }
    };

    onPozivnicaSearch = (data) => {
        //
        let valid = util.isValidEmail(data);
        if (!valid) {
            this.setState({ pozivnicaMode: "multiple" });
        } else {
            this.setState({ pozivnicaMode: "tags" });
        }
        //this.setState({autoClear: false});
    };

    onPozivnicaChange = (data, data2) => {
        let pozivnica = "";
        var self = this;

        let lastItem = data[data.length - 1];
        let isValid = util.isValidEmail(lastItem);

        if (!isValid && data.length > 0) {
            data.pop();
            //ovdje postaviti samo tekst od selekta

            this.setState({ showErrorOnPozivnica: true, errorMessage: "Neispravan unos" });
            setTimeout(() => {
                self.setState({ showErrorOnPozivnica: false });
            }, 1000);
        } else {
            this.setState({ pozivnicaMode: "multiple" });
        }

        data.forEach((item) => (pozivnica = pozivnica + item + ";"));
        this.setState({ selectedUsers: data, pozivnica: pozivnica });
    };

    selectAllClicked = () => {
        var lista = this.state.members.map((x) => x.key);
        this.setState({
            selectLabel: this.state.selectLabel == "Označi sve" ? "Odznači sve" : "Označi sve",
            selectedRowKeys: this.state.selectLabel == "Označi sve" ? lista : [],
            selectAllChecked: this.state.selectLabel == "Označi sve" ? true : false,
            indeterminate: false,
        });
    };

    title = () => {
        return (
            <div>
                <p style={{ margin: "0px" }}>{this.state.selectLabel}</p>
                <Checkbox id="selectAll" defaultChecked={true} checked={this.state.selectAllChecked} onClick={this.selectAllClicked} indeterminate={this.state.indeterminate}></Checkbox>
            </div>
        );
    };

    onDatumChange = (date, dateString) => {
        console.log(date, dateString);
    };

    onCheckboxChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
        this.setState({ inculdeMe: e.target.checked });
    };

    showModal = () => {
        if (this.state.selectedUsers && this.state.selectedUsers.length > 0) {
            this.setState({ visiblePozivnica: true });
            $("#SlanjePozivnica").html(this.tekstInvitationConfirm);
        } else {
            message.error({
                content: "Niste upisali niti jednu e-mail adresu.",
                className: "custom-class",
                duration: 3,
                style: {
                    marginTop: "8em",
                    maxWidth: "40%",
                    marginLeft: "30vw",
                },
            });
        }
    };

    hideModal = () => {
        this.setState({ visiblePozivnica: false, sendingPozivnica: false });
    };

    callback(key) {
        
    }

    render() {
        var self = this;
        //var selection = this.state.selectedRowKeys;
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            type: "checkbox",
            fixed: true,
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
                this.setState({ selectedRows: selectedRows, selectedRowKeys: selectedRowKeys });

                if (this.state.members.length != selectedRows.length && selectedRows.length != 0) {
                    this.setState({ indeterminate: true });
                } else if (selectedRows.length == 0) {
                    this.setState({ selectAllChecked: false, indeterminate: false, selectLabel: "Označi sve" });
                } else {
                    this.setState({ selectAllChecked: true, indeterminate: false, selectLabel: "Odznači sve" });
                }
            },
            columnTitle: this.title(),
            // columnTitle: "Označi sve",
            columnWidth: "120px",
        };
        return (
            <>
                <Row style={{ marginTop: "100px" }}>
                    {
                        this.state.loading ? (
                            <Row justify="center" align="middle" style={{ height: "100vh", minWidth: "100vw" }}>
                                <Spinner text="učitavanje..." />
                            </Row>
                        ) : this.state.members.length > 0 ? (
                            <Col span={22} offset={1}>


                                <Table dataSource={this.state.members} tableLayout={"fixed"} pagination={this.state.pagination} size={"small"} rowSelection={rowSelection}>
                                    <Column title="Korisničko ime" dataIndex="UserName" key="UserName" />
                                    {!this.state.isMobileDevice && <Column title="Status" dataIndex="AktivanOpisno" key="AktivanOpisno" />}
                                    <Column
                                        title="Akcija"
                                        key="action"
                                        render={(text, record) => (
                                            <Space size="middle">
                                                <a
                                                    style={{ color: "red !important" }}
                                                    onClick={() => {
                                                        this.clicked(record);
                                                    }}
                                                >
                                                    {record.Aktivan == 1 ? "Deaktiviraj" : record.DatumPrijave == null ? "Ponovno pozovi" : "Aktiviraj"}
                                                </a>
                                            </Space>
                                        )}
                                    />
                                    {!this.state.isMobileDevice && <Column title="Ime" dataIndex="Ime" key="Ime" />}
                                    {!this.state.isMobileDevice && <Column title="Prezime" dataIndex="Prezime" key="Prezime" />}
                                </Table>
                            </Col>
                        ) : (
                            <Col span={24} style={{ paddingTop: "1em", paddingBottom: "1em", paddingRight: "1em", backgroundColor: "beige", borderRadius: "15px", width: "100%", textAlign: "center" }}>
                                Nema povezanih korisnika
                            </Col>
                        )
                        /* <Col span={24} style={{height:"15vh", lineHeight:"15vh", textAlign:"center"}}> Nema povezanih korisnika</Col> */
                    }
                </Row>
                <Row justify="center" align="middle">
                    <Tabs type="card" defaultActiveKey="1" onChange={this.callback} style={{ width: "94vw", marginTop: "3vh" }} centered>
                        <TabPane tab="Pozivnice" key="1" forceRender={true} className="center">
                            <Form ref={this.PozivnicaForm} name="Pozivnica" style={{ width: "88vw", paddingTop: "1em" }} validateMessages={this.validateMessages} onFinish={this.showModal}>
                                {this.state.loading ? null : (
                                    <Row>
                                        <Col span={24} style={{ textAlign: "center", marginBottom: "6px" }}>
                                            <p style={{ marginBottom: "0px" }}>Unesite jednu ili više e-mail adresa na koje želite poslati pozivnicu:</p>
                                            <Tooltip placement="bottomRight" title="Neispravan unos" color={"red"} visible={this.state.showErrorOnPozivnica}>
                                                <Select mode={this.state.pozivnicaMode} style={{ width: "100%" }} placeholder="" value={this.state.selectedUsers} dropdownStyle={{ display: "none" }} onChange={this.onPozivnicaChange} onSearch={this.onPozivnicaSearch} onInputKeyDown={this.onPozivnicaInputKeyDown}></Select>
                                                {/* <Upute text="Unesite e-mail adrese na koje želite poslati pozivnicu."></Upute> */}
                                                <p style={{ marginBottom: "0px", fontSize: "smaller" }}>Nakon upisa svake adrese pritisnite enter</p>
                                            </Tooltip>
                                        </Col>
                                        <Col span={24} style={{ textAlign: "center" }}>
                                            <Form.Item>
                                                <Button
                                                    
                                                    htmlType="submit"
                                                    className="propis-button"
                                                    loading={this.state.sendingPozivnica}
                                                    style={{
                                                        width: "100px",
                                                        minWidth: "120px",
                                                    }}
                                                    icon={<SendOutlined />}
                                                >
                                                    Pošalji
                                                </Button>
                                                <Modal title={this.tekstInvitationConfirmTitle} visible={this.state.visiblePozivnica} onOk={this.onFinish} onCancel={this.hideModal} okText="Pozovi" cancelText="Odustani" forceRender={true}>
                                                    <div id="SlanjePozivnica"></div>
                                                </Modal>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}

                                <Row>
                                    <Col span={21} offset={2} style={{ textAlign: "center" }}></Col>
                                </Row>
                            </Form>
                        </TabPane>
                        <TabPane tab="Izvještaj o kupljenim propisima" key="2" forceRender={true} className="center">
                            <Form ref={this.ReportForm} name="Report" style={{ paddingTop: "1em", width: "44vw", textAlign: "center" }} onFinish={this.onFinishReport} validateMessages={this.validateMessages}>
                                {this.state.loading ? null : (
                                    <>
                                        <Row>
                                            <Col offset={1} span={10} style={{ marginBottom: "0px" }}>
                                                Od:
                                            </Col>
                                            <Col offset={2} span={10} style={{ marginBottom: "0px" }}>
                                                Do:
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col offset={1} span={10}>
                                                <Form.Item name="datumOd" validateTrigger="onBlur" rules={[{ required: true }]}>
                                                    <DatePicker placeholder={"Od"} format={dateFormat}></DatePicker>
                                                </Form.Item>
                                            </Col>
                                            <Col offset={2} span={10}>
                                                <Form.Item
                                                    name="datumDo"
                                                    validateTrigger="onBlur"
                                                    dependencies={["datumOd"]}
                                                    rules={[
                                                        { required: true },
                                                        ({ getFieldValue }) => ({
                                                            validator(rule, value) {
                                                                if (value <= getFieldValue("datumOd")) {
                                                                    return Promise.reject("Neispravan period!");
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <DatePicker placeholder={"Do"} format={dateFormat}></DatePicker>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row style={{ display: "block" }}>
                                            <Form.Item style={{ display: "inline-block" }}>
                                                <Checkbox onChange={this.onCheckboxChange} defaultChecked={true}>
                                                    Uključi i mene u izvještaj
                                                </Checkbox>
                                            </Form.Item>

                                            <Form.Item style={{ display: "inline-block" }}>
                                                <Button
                                                     
                                                    htmlType="submit"
                                                    className="propis-button"
                                                    style={{
                                                        minWidth: "120px",
                                                    }}
                                                    icon={<BarChartOutlined />}
                                                >
                                                    Kreiraj izvještaj
                                                </Button>
                                            </Form.Item>
                                        </Row>
                                    </>
                                )}
                            </Form>
                        </TabPane>
                    </Tabs>
                </Row>
            </>
        );
    }
}
export default TeamMembers;
