import React from "react";
import "../src/css/App.css";
import "../src/css/vanjski.css";
import "antd/dist/antd.css";
import "../src/css/theme.css";
import facebook from "./images/facebook.jpg";
import bckg from "./images/bckg.jpg";
import linkedin from "./images/linkedin.jpg";
import Propis from "./components/propis";
import Login from "./components/login";
import Pretraga from "./components/pretraga";
import CookieConsent from "./components/cookieconsent";
import Registracija from "./components/registracija";
import PaymentSuccess from "./components/paymentsuccess";
import RegistracijaConfirm from "./components/registracijaconfirm";
import WsPayPlacanje from "./components/wspayplacanje";
import KupljeniPropisi from "./components/kupljenipropisi";
import PropisiSet from "./components/propisiset";
import PovijestPregledavanja from "./components/povijestpregledavanja";
import UserProfile from "./components/userprofile";
import Upute from "./components/upute";
import About from "./components/about";
import PasswordChange from "./components/passwordchange";
import TeamMembers from "./components/teammembers";
import Pretplata from "./components/pretplata";
import Racun from "./components/racun";
import MainMenu from "./components/mainmenu";
import { SearchOutlined, HistoryOutlined, FileDoneOutlined, FacebookOutlined } from "@ant-design/icons";
import { Row, Col, Image, Tooltip } from "antd";
import userService from "../src/utils/userService";
import $ from "jquery";
import { Switch, Route, Link, HashRouter, BrowserRouter } from "react-router-dom";
import util from "../src/utils/util";
import logo from "../src/images/logo.png";
import ico from "../src/images/icon.png";
import HamburgerMenu from "react-hamburger-menu";
import api from "../src/utils/api";
import history from "history";
//import CookieConsent from "react-cookie-consent";
import WSPayForm from "./components/wspayform";
import CallbackForm from "./components/callbackform";
import PdfUvjetiKoristenja from "./uvjeti-koristenja-lexhr.pdf";
import PdfUpute from "./upute-lexhr.pdf";
import onama from "./o-nama-lexhr.pdf";

class App extends React.Component {
    constructor(props) {
        super(props);

        window.addEventListener("resize", this.updateDimensions);

        $("document").ready(function () {
            $("#searchText").focus();
            // $("#forhide").hide();
        });

        $(window).on("hashchange", function (e) {
            var hash = window.location.hash;

            if (hash === "/login") {
                window.location.reload();
            }
        });

        let self = this;

        var user = userService.getLoggedInUser();

        // Build the expiration date string:
        var expiration_date = new Date();
        var cookie_string = "";
        expiration_date.setFullYear(expiration_date.getFullYear() + 1);
        // Build the set-cookie string:
        cookie_string = "test_cookies=true; path=/; expires=" + expiration_date.toUTCString();
        // Create or update the cookie:
        document.cookie = cookie_string;

        api.get(
            "getuser",
            (data) => {
                if (!data || data === "") {
                    user = null;
                    //alert(data);
                    userService.logout();
                    self.setState({ isLoggedIn: user !== null, user: user });
                }
            },
            (data) => {
                //alert("get user error");
                user = null;
                userService.logout();
                self.setState({ isLoggedIn: user !== null, user: user });
            }
        );

        this.state = {
            isMobileDevice: util.isMobileDevice(),
            isLoggedIn: user !== null,
            user: user,
            loadFromStorage: false,
            menuIsOpen: false,
            stopSearch: false,
        };

        //sluzi za sakrivanje mobitelne tipkovnice kad se pritisne return
        if (util.isMobileDevice()) {
            $(document).on("keyup", function (e) {
                if (e.keyCode == 13) {
                    $("input").blur();
                }
            });
        }

        //polifill za IE
        if (typeof Object.assign != "function") {
            Object.assign = function (target) {
                if (target == null) {
                    throw new TypeError("Cannot convert undefined or null to object");
                }

                target = Object(target);
                for (var index = 1; index < arguments.length; index++) {
                    var source = arguments[index];
                    if (source != null) {
                        for (var key in source) {
                            if (Object.prototype.hasOwnProperty.call(source, key)) {
                                target[key] = source[key];
                            }
                        }
                    }
                }
                return target;
            };
        }
    }

    updateDimensions = () => {
        this.setState({ isMobileDevice: util.isMobileDevice() });
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    getUserSuccess = (data) => {};

    getUserError = (data) => {};

    componentDidMount = () => {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/enterprise.js?render=6LeXi6cbAAAAAIul_mp_0urcnun3cXYeRXAnwOns";
        script.async = true;

        document.body.appendChild(script);
    };

    componentWillReceiveProps = () => {};

    logout = () => {
        userService.logout();
        window.location.reload();
    };

    login = () => {
        this.triggerMenu();
        sessionStorage.setItem("propisi_backto", window.location);
        window.location = "/login";
    };

    registracija = () => {
        this.triggerMenu();
        window.location = "/registracija";
    };

    loginSuccess = () => {
        let user = userService.getLoggedInUser();
        //console.log(user);
        this.setState({ isLoggedIn: true, user: user });
        //if (backTo) window.location = backTo;
    };

    triggerMenu = (e) => {
        this.setState({
            menuIsOpen: !this.state.menuIsOpen,
        });
    };

    onMenuClick = (menu) => {
        this.setState({ menuIsOpen: false });
    };

    novaPretraga = () => {
        this.setState({ stopSearch: !this.state.stopSearch });
        util.clearSettings();
    };

    render() {
        let bullet1 = (
            <div>
                <div style={{ textAlign: "center", padding: 10 }}>PROMOTIVNA AKCIJA</div>
                <div style={{ fontSize: "11px" }}>Na svim zadnjim pročišćenim verzijama propisa moguće je preko poveznica u posebnom prozoru otvarati tekst članka na koji se referira neki drugi članak.</div>
            </div>
        );

        let siteName = window.location.pathname.split("/")[1];

        //siteName = "localhost:3000";

        return (
            <BrowserRouter>
                <div className="top-container">
                    {/* <div className="bounding-box"></div>  */}
                    {/* <Image className={"bckgpic"} preview={false} src={bckg}></Image> */}
                    {this.state.isMobileDevice === false ? (
                        <div style={{ position: "fixed", width: "100vw", zIndex: "1000" }}>
                            <Row className="header" span="10" justify="center" align="middle">
                                <Col span="18">
                                    <Link className="" to="/">
                                        <img src={ico} alt="Logo" style={{ height: "46px", marginLeft: "6px" }} className="logoimg" />
                                        <img src={logo} alt="Logo" style={{ height: "60px" }} className="logoimg" />
                                    </Link>
                                    <Link onClick={() => this.novaPretraga()} to="/">
                                        <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Klikom se otvara stranica pretrage s početnim postavkama pretrage.">
                                            <span className="navbutton">
                                                <SearchOutlined style={{ fontSize: 20, fontWeight: "bold" }} /> Nova pretraga
                                                {/* <SearchOutlined /> */}
                                            </span>
                                        </Tooltip>
                                    </Link>
                                    {this.state.isLoggedIn ? (
                                        <Link onClick={() => sessionStorage.setItem("propisi_backto", window.location.hash)} to="kupljenipropisi">
                                            <span className="navbutton">
                                                <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Popis svih kupljenih propisa poredanih po datumu preuzimanja. Svaki propis u popisu sadrži datum preuzimanja, datum stupanja na snagu i naziv.">
                                                    Kupljeni propisi
                                                </Tooltip>
                                                {/* <FileDoneOutlined /> */}
                                            </span>
                                        </Link>
                                    ) : null}
                                    {this.state.isLoggedIn ? (
                                        <Link onClick={() => sessionStorage.setItem("propisi_backto", window.location.hash)} to="/povijestpregledavanja">
                                            <span className="navbutton">
                                                <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Popis zadnjih 100 pregledanih propisa poredanih po datumu pregledavanja. Svaki propis sadrži datum pregledavanja, datum stupanja na snagu (pročišćeni tekst) ili broj Narodnih novina (izvorni tekst) i naziv.">
                                                    Povijest pregledavanja
                                                </Tooltip>
                                                {/* <HistoryOutlined /> */}
                                            </span>
                                        </Link>
                                    ) : null}
                                    {/* <Link  onClick={() => window.location = "https://lexhr.eu/propisi/godine.html"} >
                                        <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Pregled svih propisa po godinama">
                                            <span className="navbutton">Svi propisi</span>
                                        </Tooltip>
                                    </Link> */}
                                </Col>
                                <Col span="6" align="right">
                                    {/* <span style={{ marginRight: "40px" }}>
                                        <span className="link-icon" onClick={() => (window.location = "http://www.facebook.com/maxlexhr")}>
                                            <Image preview={false} src={facebook}></Image>
                                        </span>
                                        <span className="link-icon" onClick={() => (window.location = "http://www.linkedin.com/maxlexhr")}>
                                            <Image preview={false} src={linkedin}></Image>
                                        </span>
                                    </span> */}
                                    {!this.state.isMobileDevice && (
                                        <span style={{ marginRight: "40px" }}>
                                            <span className="link-icon">
                                                <Tooltip placement="bottom" title={bullet1}>
                                                    <div className="led-green"></div>
                                                </Tooltip>
                                            </span>
                                        </span>
                                    )}

                                    {this.state.user ? (
                                        <span onClick={() => this.triggerMenu()} style={{ marginRight: "20px" }}>
                                            <a className="header-mobile-icon" style={{ display: "inline-flex" }}>
                                                <p style={{ fontSize: "14px", display: "inline-flex", marginRight: "10px", marginTop: "-4px" }}>{this.state.user.username} </p>
                                                <HamburgerMenu menuClicked={this.triggerMenu} isOpen={this.state.menuIsOpen} color="white" width={18} height={15} />
                                            </a>
                                        </span>
                                    ) : null}

                                    {!this.state.user ? (
                                        <span>
                                            <Link
                                                // replace
                                                // onClick={() => {
                                                //     sessionStorage.setItem("propisi_backto", window.location.hash);
                                                // }}
                                                to="/login"
                                            >
                                                <span className="navbutton">Prijava</span>
                                            </Link>
                                            {/*
                                            <Link to="/registracija">
                                                <span className="navbutton">Novi korisnik</span>
                                            </Link> 
                                            */}

                                            <Link /*onClick={() => window.open(onama, "_blank").focus()}*/ to="/onama">
                                                <span className="navbutton">O nama</span>
                                            </Link>
                                        </span>
                                    ) : null}
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <div style={{ position: "fixed", width: "100vw", zIndex: "1000", top: "0px" }}>
                            <Row className="header-mobile">
                                <Col span="20">
                                    <Link className="navlink-title" to="/">
                                        <img src={ico} alt="Logo" style={{ height: "46px", marginLeft: "6px" }} className="logoimg" />
                                        <img src={logo} alt="Logo" style={{ height: "60px" }} className="logoimg" />
                                    </Link>
                                </Col>
                                <Col span="4" align="right">
                                    <a className="header-mobile-icon">
                                        <HamburgerMenu isOpen={this.state.menuIsOpen} menuClicked={this.triggerMenu} color="white" width={18} height={15} />
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    )}

                    {/* <div className="footer-fixed">© Lexhr.eu. Sva prava pridržana.</div> */}

                    <Row className="pocetna-slika"></Row>

                    {this.state.menuIsOpen ? <MainMenu mode={this.state.isLoggedIn ? 2 : 1} onClick={this.onMenuClick}></MainMenu> : null}
                    {/* A <Switch> looks through its children <Route>s and
                    renders the first one that matches the current URL. */}

                    <CookieConsent></CookieConsent>
                    {/* <CookieConsent
                        location="bottom"
                        buttonText="Slažem se"
                        cookieName="maxlexhrc_cookie_request"
                        style={{ background: "#2B373B" }}
                        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                        expires={150}
                    >
                        Web stranica maxlex.hr koristi kolačiće (Cookies) u svrhu poboljšanja Vašeg korisničkog iskustva. Uz daljnje korištenje, prihvaćate korištenje kolačića.{" "}
                        <span style={{ fontSize: "10px" }}></span>
                    </CookieConsent> */}

                    <Switch>
                        <Route exact path="/">
                            <Pretraga stopSearch={this.state.stopSearch} />
                        </Route>
                        <Route exact path="/home">
                            <Pretraga stopSearch={this.state.stopSearch} />
                        </Route>
                        <Route path="/login">
                            <Login success={this.loginSuccess} />
                        </Route>
                        <Route path="/loginsendmail">
                            <Login success={this.loginSuccess} />
                        </Route>
                        <Route path="/passwordrecovery">
                            <PasswordChange success={this.loginSuccess} />
                        </Route>
                        <Route path="/registracija">
                            <Registracija />
                        </Route>
                        <Route path="/about">
                            <About />
                        </Route>
                        {/* <Route path="/propis/:id" component={Propis} />
                        <Route path="/propisglavni/:id" component={Propis} /> */}
                        <Route path="/propis" component={Propis} />
                        <Route path="/propisglavni" component={Propis} />
                        <Route exact path="/confirm">
                            <RegistracijaConfirm />
                        </Route>
                        <Route path="/result">
                            <CallbackForm />
                        </Route>
                        <Route path="/wspayplacanje">
                            <WsPayPlacanje />
                        </Route>
                        <Route path="/paymentsuccess">
                            <PaymentSuccess />
                        </Route>
                        <Route path="/propisi/kupljenipropisi">
                            <KupljeniPropisi />
                        </Route>
                        <Route path="/propisiset">
                            <PropisiSet />
                        </Route>
                        <Route path="/povijestpregledavanja">
                            <PovijestPregledavanja />
                        </Route>
                        <Route path="/upute">
                            <Upute />
                        </Route>
                        <Route path="/onama">
                            <Upute />
                        </Route>
                        <Route path="/uvjetikoristenja">
                            <Upute />
                        </Route>
                        <Route path="/userprofile">
                            <UserProfile />
                        </Route>
                        <Route path="/passwordchange">
                            <PasswordChange />
                        </Route>
                        <Route path="/teammembers">
                            <TeamMembers />
                        </Route>
                        <Route path="/pretplata">
                            <Pretplata />
                        </Route>
                        <Route path="/racun">
                            <Racun />
                        </Route>
                        <Route path="/wspayform" component={WSPayForm} />
                    </Switch>
                </div>

                {!this.state.isMobileDevice ? (
                    <></>
                    // <div className="footer" style={{ paddingLeft: "40px", paddingRight: "40px" }}>
                    //     <span style={{ float: "left" }}>© LEXhr.eu. Sva prava pridržana.</span>
                    //     <span
                    //         onClick={() => {
                    //             window.location = "/upute";
                    //         }}
                    //         style={{ float: "right", textDecoration: "underline", cursor: "pointer" }}
                    //     >
                    //         Upute
                    //     </span>

                    //     <span
                    //         onClick={() => {
                    //             window.location = "/uvjetikoristenja";
                    //         }}
                    //         style={{ float: "right", textDecoration: "underline", cursor: "pointer", marginRight: "20px" }}
                    //     >
                    //         Uvjeti korištenja
                    //     </span>
                    // </div>
                ) : (

                    <></>
                    // <div className="footer" style={{ paddingLeft: "40px", paddingRight: "40px" }}>
                    //     <div style={{ width: "100%" }}>© LEXhr.eu. Sva prava pridržana.</div>
                    //     <span
                    //         onClick={() => {
                    //             window.location = "/upute";
                    //         }}
                    //         style={{ textDecoration: "underline", cursor: "pointer" }}
                    //     >
                    //         Upute
                    //     </span>
                    //     &nbsp;&nbsp;&nbsp;
                    //     <span
                    //         onClick={() => {
                    //             window.location = "/uvjetikoristenja";
                    //         }}
                    //         style={{ textDecoration: "underline", cursor: "pointer", marginRight: "20px" }}
                    //     >
                    //         Uvjeti korištenja
                    //     </span>
                    // </div>
                )}
            </BrowserRouter>
        );
    }
}

export default App;
