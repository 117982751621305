import React from "react";
import { Row, Col } from "antd";
import moment from "moment";
import api from "../utils/api";
import util from "../utils/util";
import SpinnerFixed from "./spinnerfixed";
import ListaPropisa from "./listapropisa";

class KupljeniPropisi extends React.Component {
    constructor(props) {
        super(props);

        window.addEventListener("resize", this.updateDimensions);

        this.state = {
            isMobileDevice: util.isMobileDevice(),
            span: util.isMobileDevice() ? 18 : 16,
            offset: util.isMobileDevice() ? 2 : 3,
            propisi: [],
            loading: true,
        };

        api.get("propis/getfromkorisnik", this.success, this.fail);
    }

    updateDimensions = () => {
        this.setState({ isMobileDevice: util.isMobileDevice() });
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    
    otvoriPropis = (item) => {
        window.location = "/propis/" + item.UID;
    };

    success = (data) => {
        this.setState({ propisi: data, loading: false });
    };

    fail = () => {
        this.setState({ uspjesnaRegistracija: false, loading: false });
    };

    render() {
        return (
            <div style={{ marginTop: this.state.isMobileDevice ? "80px" : "80px" }}>
                <Row>
                    <Col span ={20} offset = {2} className="kartica">
                        <SpinnerFixed show={this.state.loading} text="Učitavanje..."></SpinnerFixed>           
                        <ListaPropisa naziv={"Kupljeni propisi"} propisi={this.state.propisi}></ListaPropisa>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default KupljeniPropisi;

