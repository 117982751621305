import React from "react";
import { Row, Col } from "antd";
import moment from "moment";
import api from "../utils/api";
import util from "../utils/util";
import SpinnerFixed from "./spinnerfixed";
import ListaPropisa from "./listapropisa";
import PretragaPropisi from "./pretragapropisi";

class PropisiSet extends React.Component {
    constructor(props) {
        super(props);

        window.addEventListener("resize", this.updateDimensions);

        this.state = {
            isMobileDevice: util.isMobileDevice(),
            span: util.isMobileDevice() ? 18 : 16,
            offset: util.isMobileDevice() ? 2 : 3,
            propisi: [],
            loading: true,
            span: util.isMobileDevice() ? 22 : 18,
            offset: util.isMobileDevice() ? 1 : 3,
        };

        let params = util.getParametersFromUrl(window.location);

        api.get("propis/getset/" + params.UID, this.success, this.fail);
    }

    otvoriPropis = (item) => {
        window.location = "/propis/" + item.UID;
    };

    success = (data) => {
        this.setState({ propisi: data, loading: false });
    };

    fail = () => {
        this.setState({ uspjesnaRegistracija: false, loading: false });
    };

    otvoriPropis = (data) => {};

    render() {
        return (
            <Row style={{ marginTop: this.state.isMobileDevice ? "80px" : "80px" }}>
                <Col span={24} offset={0} className="">
                    <SpinnerFixed show={this.state.loading} text="Učitavanje..."></SpinnerFixed>
                    {/* <ListaPropisa propisi={this.state.propisi}></ListaPropisa> */}

                    <Row>
                        {this.state.propisi.length > 0  ? (
                            <Col offset={1} span={22}>
                                <Row> 
                                    <Col offset={this.state.isMobileDevice ? 0 : 2} span={22} >
                                       <div className="header-naslov"><h1 className="header-naslov-propisi">Temeljni propis i svi propisi koji ga mijenjaju</h1></div>
                                       <div style={{color:"gray", marginTop:"-25px", fontSize:"12px"}}>sortirano silazno po datumu stupanja na snagu</div>
                                    </Col>
                                </Row>

                                {!this.state.isMobileDevice &&
                                <Row className="pretraga-header" style={{marginTop:"30px"}}>
                                    <Col span={2}></Col>
                                    <Col span={2}>Broj Narodnih novina</Col>
                                    <Col span={3}>Datum stupanja na snagu</Col>
                                    <Col span={13}>Naziv</Col>
                                </Row>}
                            </Col>
                        ) : null}
                    </Row>
                    <Row>
                        <Col offset={1} span={22}>
                            <PretragaPropisi tipPretrage={1} pretragaDatum={new Date()} propisi={this.state.propisi} otvoriPropisCallback={this.otvoriPropis}></PretragaPropisi>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default PropisiSet;
