import React from "react";
import $ from "jquery";
import { Row, Col } from "antd";
import { Spin, Form, Button, Collapse, Popover, message, Input, Affix, Modal, Checkbox, Image, Tooltip } from "antd";
import { SendOutlined, CloseCircleOutlined, LeftOutlined, CreditCardOutlined, CaretDownOutlined, CaretUpOutlined, CheckCircleOutlined, FilePdfOutlined, SearchOutlined } from "@ant-design/icons";
import userService from "../utils/userService";
import api from "../utils/api";
import util from "../utils/util";
import Spinner from "../components/spinner";
import WsPayForm from "../components/wspayform";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";

const { Panel } = Collapse;
const { Search } = Input;

class Propis extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        window.onhashchange = () => {
            if (window.location.toString().indexOf("/propis?") > -1) {
                self.setState({ loading: true });
                self.reload();
            }
        };

        $(document).keydown(function (objEvent) {
            if (objEvent.ctrlKey) {
                if (objEvent.keyCode === 65) {
                    //objEvent.disableTextSelect();
                    return false;
                }
            }
        });

        this.init();
        this.reload();
    }

    init = () => {
        let params = util.getParametersFromUrl(window.location);

        const el = document.querySelector("body");

        el.scrollTop = 0;

        let user = util.getLoggedInUser();

        window.addEventListener("resize", this.updateDimensions);

        let state = {
            loading: true,
            propis: null,
            placanje: false,
            user: userService.getLoggedInUser(),
            isMobileDevice: util.isMobileDevice(),
            span: util.isMobileDevice() ? 22 : 20,
            offset: util.isMobileDevice() ? 1 : 2,
            html: null,
            searchText: "",
            anchorState: "0/0",
            sendingMail: false,
            showSearchBox: false,
            TokenZaKupnju: "",
            poslano: false,
            oneClickKupnjaInProgress: false,
            tipTijelaID: params.ttid,
            isProcisceni: params.ttid == "1",
            buyEnabled: true,
            params: params,
            kupiDrugomKarticomModalShow: false,
            enableOneClickKupnju: false,
            datum: null,
            mailToSend: user ? user.username : null,
            ucitavanjeVanjskePoveznice: false,
        };

        this.state = state;
    };

    updateDimensions = () => {
        this.setState({ isMobileDevice: util.isMobileDevice() });
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    loaded = null;
    reload = () => {
        let params = util.getParametersFromUrl(window.location);

        if (params.uid) {
            if (this.loaded == params.uid + params.ttid) {
                this.loaded = null;
                return;
            }
            this.loaded = params.uid + params.ttid;

            this.propisID = params.uid;
            this.tipTijelaID = params.ttid;

            this.getPropis(params.uid);
        }
    };

    propisID = null;
    tipTijelaID = 2;
    locationUrl = null;

    addOnHashChangeEventHabdler = () => {
        window.onhashchange = () => {
            if (window.location.toString().indexOf("/propis?") > -1) {
                this.setState({ loading: true });
                this.reload();
            }
        };
    };

    //#region Pretraživanje unutar texta
    toggleSearchBox = () => {
        this.setState({ showSearchBox: !this.state.showSearchBox });
        $("#searchBox").focus();
    };

    anchorDown = () => {
        if (this.anchors.length === 0) {
            this.anchorIndex = -1;
            if (this.state.searchText !== "") {
                this.onChange(this.state.searchText);
            }
            return;
        }

        this.anchorIndex += 1;
        if (this.anchorIndex === this.anchors.length) this.anchorIndex = 0;

        this.anchorFocus();
    };

    anchorUp = () => {
        if (this.anchors.length === 0) {
            this.anchorIndex = -1;
            return;
        }

        this.anchorIndex -= 1;
        if (this.anchorIndex === -1) this.anchorIndex = this.anchors.length - 1;

        this.anchorFocus();
    };

    anchorsClear = () => {
        this.anchors.forEach((element) => {
            $(element).removeClass("p-match");
        });

        this.setState({ anchorState: "" });

        //$("span").removeClass("")
    };

    clearSearchText = () => {
        this.onChange("");
        this.setState({ searchText: "" });
    };

    anchorFocus = () => {
        this.anchorsClear();

        if (this.anchors.length === 0) return;

        if (this.anchorIndex === -1) this.anchorIndex = 0;

        this.setState({ anchorState: this.anchorIndex + 1 + "/" + this.anchors.length });

        let aTag = this.anchors[this.anchorIndex];

        var top_of_element = $(aTag).offset().top;
        var bottom_of_element = $(aTag).offset().top + $(aTag).outerHeight();
        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        var top_of_screen = $(window).scrollTop();

        $(aTag).addClass("p-match");

        if (bottom_of_screen > top_of_element && top_of_screen < bottom_of_element) {
            // the element is visible, do something
        } else {
            if (aTag) $("html,body").animate({ scrollTop: aTag.offset().top - 300 }, 1);
            // the element is not visible, do something else
        }
    };

    anchorIndex = -1;
    anchors = [];

    paragraphs = {};

    onSearchChange = (e) => {
        this.setState({ searchText: e.target.value, searchLoading: true });
        this.onChange(e.target.value);
    };

    onSearchKeyPress = (e) => {
        if (e.keyCode === 27) {
            this.clearSearchText();
        }
    };

    replaceBulk = (originalText, trazilice) => {
        let lcText = originalText.toLowerCase();
        let newWords = [];
        let wordIndex = -1;
        debugger;
        for (let i = 0; i < trazilice.length; i++) {
            let indexOf = 0;
            let searchWord = trazilice[i];

            while (indexOf > -1) {
                indexOf = lcText.indexOf(searchWord.toLowerCase(), indexOf);
                if (indexOf == -1) break;

                let originalMatch = originalText.substring(indexOf, indexOf + searchWord.length);
                let newWord = "<span class='highlight'>" + originalMatch + "</span>";
                wordIndex = wordIndex + 1;
                newWords.push(newWord);
                let wordCode = "~" + wordIndex + "~";
                //originalText = this.replaceAt(originalText, indexOf, newWord, originalMatch);
                originalText = this.replaceAt(originalText, indexOf, wordCode, originalMatch);

                lcText = originalText.toLowerCase();
                indexOf = indexOf + 2;
            }
        }

        newWords.forEach((item, index) => {
            originalText = originalText.replace("~" + index + "~", item);
        });

        return originalText;
    };

    replaceAt = (text, index, replacement, toReplace) => {
        if (index >= text.length) {
            return text.valueOf();
        }
        return text.substring(0, index) + replacement + text.substring(index + 1 + toReplace.length - 1);
    };

    timout = null;
    onChange = (value) => {
        clearTimeout(this.timout);
        this.timout = setTimeout(() => {
            this.search(value);
            $("#searchBox").focus();
        }, 300);
    };

    search = (value) => {
        var data = value.trim();
        let scrollTop = 0;
        if (data === "") {
            scrollTop = $("html,body").scrollTop();
            this.setState({ anchorState: "0/0" });
        }

        var self = this;
        self.anchorIndex = -1;
        self.anchors = [];

        var trazilice = data.split(" ");

        $("p, td").each(function () {
            let pText = $(this).text().toLowerCase();
            let pid = $(this).attr("id");
            let pTextOriginal = $(this).text();

            if (self.paragraphs[pid]) pTextOriginal = self.paragraphs[pid];

            let isMatch = true;
            let p = $(this);
            $.each(trazilice, function (i) {
                if (pText.indexOf(trazilice[i].trim().toLowerCase()) === -1) {
                    isMatch = false;
                }
            });

            let newText = "";

            if (data != "") newText = self.replaceBulk(pTextOriginal, trazilice);

            if (isMatch && data !== "") {
                self.anchors.push(p);
                debugger;
                $(p).html(newText);
            } else {
                $(p).html(pTextOriginal);

                $(p).removeClass("p-match");
            }
        });

        if (self.anchors.length === 0) {
            self.setState({ anchorState: "0/0" });
            setTimeout(() => {
                $("html,body").scrollTop(scrollTop);
                self.resetClanakClickHandler();
            }, 200);
        } else {
            self.anchorFocus();
            self.resetClanakClickHandler();
        }

        this.setState({ searchLoading: false });
    };

    //#endregion

    getPropis = (id) => {
        let params = util.getParametersFromUrl(window.location);

        if (!this.locationUrl || this.locationUrl != window.location.toString()) {
            this.locationUrl = window.location.toString();
        } else {
            if (this.locationUrl == window.location.toString()) {
                return;
            }
        }

        let url = "propis/get";
        $("[id^=anchor-]").off("click");

        var data = {
            UID: params.uid,
            TipTijelaID: params.ttid,
            PretragaDatum: new Date(params.dp),
        };

        data = JSON.stringify(data);
        //---

        api.post(url, data, this.success, this.error);
    };

    sveIzmjene = () => {
        if (!this.state.propis.PopisSvihIzmjena || !this.state.isProcisceni) return null;

        return (
            <div style={{ textAlign: "center", display: "block", marginBottom: "40px" }}>
                {this.state.propis.PopisSvihIzmjena.map((item, index) => {
                    const state = { page_id: 1, user_id: 1 };
                    const htitle = "";

                    let content = (
                        <div style={{ textAlign: "center", width: "100%" }}>
                            <Button
                                onClick={() => {
                                    let url = "/propis?uid=" + item.UID + "&ttid=2&dp=" + this.state.pretragaDatum;
                                    window.history.pushState(state, htitle, url);
                                    this.setState({ loading: true });
                                    //this.reload();
                                    //this.addOnHashChangeEventHabdler();
                                    this.reload();
                                }}
                            >
                                {index == 0 ? "Originalni tekst temeljnog zakona" : "Originalni tekst"}
                            </Button>
                            &nbsp;
                            {item.GlavniPropisID != null && item.UID != this.state.propis.UID ? (
                                <Button
                                    onClick={() => {
                                        let url = "/propis?uid=" + item.UID + "&ttid=1&dp=" + this.state.pretragaDatum;
                                        window.history.pushState(state, htitle, url);
                                        this.setState({ loading: true });
                                        //this.reload();
                                        //this.addOnHashChangeEventHabdler();
                                        this.reload();
                                    }}
                                >
                                    Pročišćeni tekst
                                </Button>
                            ) : null}
                        </div>
                    );

                    let title = (
                        <div style={{ textAlign: "center" }}>
                            <b>{item.Naziv}</b>
                            <br></br>
                            <div>{util.datum(item.DatumStupanjaNaSnagu)}</div>
                        </div>
                    );

                    let className = index == 0 ? "nn-link temeljni-zakon-link" : "nn-link";

                    return (
                        <Popover trigger={this.state.isMobileDevice ? "click" : "hover"} key={"sve-izmjene-" + index} content={content} title={title} className={className}>
                            <Button>{item.Broj}</Button>
                        </Popover>
                    );
                })}
            </div>
        );
    };

    napomeneText = () => {
        return <p style={{}}>Napomene</p>;
    };

    napomene = () => {
        let span1 = 8;
        let span2 = 16;
        let datumStyle = { paddingLeft: "20px", whiteSpace: "pre-wrap" };

        return (
            <Collapse align="left" bordered={false}>
                <Panel header={this.napomeneText()} className="napomene" key="1" style={{ textAlign: "left" }}>
                    <Row>
                        <Col style={{ textAlign: "right" }} span={span1}>
                            Datum objave:
                        </Col>
                        <Col style={datumStyle} span={span2}>
                            {util.datum(this.state.propis.DatumObjave) || "-"}
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col style={{ textAlign: "right" }} span={span1}>
                            Datum stupanja na snagu:
                        </Col>
                        <Col style={datumStyle} span={span2}>
                            {util.datum(this.state.propis.DatumStupanjaNaSnagu) || "-"}
                        </Col>
                    </Row> */}
                    <Row>
                        <Col style={{ textAlign: "right" }} span={span1}>
                            Datum primjene:
                        </Col>
                        <Col style={datumStyle} span={span2}>
                            {util.datum(this.state.propis.DatumPrimjene) || "-"}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "right" }} span={span1}>
                            Datum prestanka važenja:
                        </Col>
                        <Col style={datumStyle} span={span2}>
                            {util.datum(this.state.propis.DatumPrestankaVazenja) || "-"}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "right" }} span={span1}>
                            Napomena za datum prestanka važenja:
                        </Col>
                        <Col style={datumStyle} span={span2}>
                            {this.state.propis.NapomenaPrestankaVazenja}
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        );
    };

    error = (data) => {};

    success = (data) => {
        let self = this;

        let params = util.getParametersFromUrl(window.location);

        let podnaslov = " na datum ";
        let pretragaDatum = new Date();
        let isProcisceni = params.ttid == "1";
        let datum = null;

        if (isProcisceni) {
            podnaslov = "Pročišćeni tekst na datum: ";
        }

        if (params.dp && params.dp != "undefined") {
            pretragaDatum = new Date(params.dp);
        }

        podnaslov = podnaslov + util.datum(data.DatumStupanjaNaSnagu);
        datum = data.DatumStupanjaNaSnagu;

        self.setState({
            propis: data,
            loading: false,
            cijena: data.Cijena,
            isAdmin: data.IsAdmin,
            aktivnaPretplataID: data.AktivnaPretplataID,
            voditeljAktivnaPretplataID: data.VoditeljAktivnaPretplataID,
            brojSlobodnihPropisa: data.BrojSlobodnihPropisa,
            voditeljImaToken: data.VoditeljImaToken,
            imaToken: data.ImaToken,
            kupljeno: data.Kupljeno,
            pretragaDatum: pretragaDatum,
            datum: datum,
            podnaslov: podnaslov,
            tipTijelaID: params.ttid,
            isProcisceni: isProcisceni,
            poslano: false,
            sendingMail: false,
            tipStatusaBrzogPlacanjaID: data.TipStatusaBrzogPlacanjaID,
        });

        var tijelo = isProcisceni ? data.TijeloAzurirano : data.TijeloOriginal;
        var elements = $(tijelo);
        var found = $(".sl-content", elements);

        var html = found[0];

        if (found.length === 0) html = tijelo;

        self.setState({ html: html });

        $("#propisroot").empty();

        $("#propisroot").html(html);

        //############### podešavanje linkova fusnota
        util.podesiGoToLinkove("#propisroot a", 170);
        //############### podešavanje linkova fusnota

        $("#propisroot img").each(function () {
            let urli = $(this).attr("src");

            if (!urli) {
                return;
            }
            //LINKOVI ZA SLIKE I TIJELU NISU DOBRI PA IH PODEŠAVAMO OVAKO
            // ####################################################################
            // Opcija 1.
            // - skraćena verzija koja se nalazi u html tekstu
            // "/files/_web/sluzbeni-dio/2017/129691/images/2575.jpg"
            // - cijeli link
            // https://narodne-novine.nn.hr/files/_web/sluzbeni-dio/2017/129691/images/2575.jpg
            // - pravilo: ako počinje s "/files/"

            urli = urli.replace("/files/", "https://narodne-novine.nn.hr/files/");

            // Opcija 2.
            // - primjer: <img src="dodatni/349699.gif">
            // - skraćena verzija koja se nalazi u html tekstu: "src="dodatni/349699.gif"
            // - cijeli link: "https://narodne-novine.nn.hr/clanci/sluzbeni/dodatni/349699.gif"
            // - pravilo: ako počinje s "dodatni/"

            urli = urli.replace("dodatni/", "https://narodne-novine.nn.hr/clanci/sluzbeni/dodatni/");
            urli = urli.replace("dodaci/", "https://narodne-novine.nn.hr/clanci/sluzbeni/dodaci/");

            $(this).attr("src", urli);
            // ####################################################################
        });

        let pindex = -1;

        $("p").each(function () {
            pindex = pindex + 1;
            let pid = "p" + pindex;
            $(this).attr("id", pid);
            self.paragraphs[pid] = $(this).html();
        });

        if (!isProcisceni || data.Kupljeno) {
            //$(".noselect").removeClass("noselect");
        }

        $("[id^=anchor-]").click((e) => {
            let b = $(e.target).attr("id");

            document.getElementById("id1").scrollIntoView();
        });

        self.resetClanakClickHandler();
        // $('.vanjska-poveznica').on('click',function(e){
        //     //Your code here

        // });

        window.scrollTo(0, 0);
    };

    windowTop = 0;

    resetClanakClickHandler = () => {
        let self = this;
        $(".unutarnja-poveznica").on("click", function (e) {
            let clanakBr = $(e.currentTarget).attr("data-target");
            let innerHtml = $("#" + clanakBr).html();
            let html = "<div>" + innerHtml + "</div>";

            var doc = document.documentElement;
            //pamtimo scroll-top zbog onog problema što se nakon zatvaranja modala pomiće scroll prema gore. Kada zatvorim modal, vratit ćemo na ovu poziciju.
            self.windowTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

            if (html) {
                html = html.replaceAll("vanjska-poveznica", "bold");
                html = html.replaceAll("unutarnja-poveznica", "bold");
            }
            self.setState({ clanak: html, showClanak: true });
        });

        $(".vanjska-poveznica").on("click", function (e) {
            let target = $(e.currentTarget).attr("data-target");
            var propisInformacijeID = target.split("-")[1];
            self.vanjskiClanakBr = target;

            var doc = document.documentElement;
            //pamtimo scroll-top zbog onog problema što se nakon zatvaranja modala pomiće scroll prema gore. Kada zatvorim modal, vratit ćemo na ovu poziciju.
            self.windowTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

            let vanjskiPropis = self.vanjskiPropisi.find((x) => x.PropisInformacijeID == propisInformacijeID);
            if (vanjskiPropis) {
                self.setState({ showClanak: true });
                self.getVanjskaPoveznicaSuccess(vanjskiPropis);
                return;
            }

            let data = { Clanak: target };

            self.setState({ ucitavanjeVanjskePoveznice: true, showClanak: true });
            api.post("propis/getbyclanak", JSON.stringify(data), self.getVanjskaPoveznicaSuccess, self.getVanjskaPoveznicaError);
        });
    };

    vanjskiClanakBr = "";
    vanjskiPropisi = [];

    getVanjskaPoveznicaSuccess = (data) => {
        let tijelo = "";
        let datai = this.vanjskiClanakBr.split("-");

        let propisInformacijeID = datai[1];
        let vanjskiPropis = this.vanjskiPropisi.find((x) => x.PropisInformacijeID == propisInformacijeID);
        if (!vanjskiPropis) {
            this.vanjskiPropisi.push(data);
        }

        let clanakBr = -1;

        if (datai.length === 4) {
            clanakBr = datai[3];
            tijelo = data.TijeloOriginal;
        } else {
            clanakBr = datai[2];
            tijelo = data.TijeloAzurirano;
        }

        var doc = new DOMParser().parseFromString(tijelo, "text/html");

        let element = doc.getElementById("cl-" + clanakBr);
        let innerHtml = $(element).html();
        if (innerHtml) {
            innerHtml = innerHtml.replaceAll("vanjska-poveznica", "bold");
            innerHtml = innerHtml.replaceAll("unutarnja-poveznica", "bold");
        }
        this.setState({ clanak: innerHtml, ucitavanjeVanjskePoveznice: false });
    };

    getVanjskaPoveznicaError = (data) => {};

    cs = () => {
        if (this.state.isProcisceni) {
            //if (this.state.isProcisceni && !this.state.kupljeno) {
            if (window.getSelection) {
                if (window.getSelection().empty) {
                    // Chrome
                    window.getSelection().empty();
                } else if (window.getSelection().removeAllRanges) {
                    // Firefox
                    window.getSelection().removeAllRanges();
                }
            } else if (document.selection) {
                // IE?
                document.selection.empty();
            }
            //$("#searchBox").focus();
        }
    };

    oneClick = () => {
        if (this.state.oneClickKupnjaInProgress) return;

        // if(this.state.tipStatusaBrzogPlacanjaID != 1){
        //     alert("OneClick kupnja trenutno nije omogućena ili je pauzirana.");
        //     return;
        // }

        this.setState({ oneClickKupnjaInProgress: true });

        let data = {
            TokenPaymentForThisPropisOnly: null,
            UID: this.state.propis.UID,
            ModelPretplateID: null,
            Datum: this.state.datum,
        };
        //let url = "wstokenpayment?tokenPaymentForThisPropisOnly=null&UID=" + this.state.propis.UID + "&modelPretplateID=null&datumPretrage=" + this.state.pretragaDatum ;
        api.post("wstokenpayment", JSON.stringify(data), this.onClickSuccess, this.oneClickError);
    };

    onClickSuccess = (data) => {
        if (data.Status === 200) {
            window.location.reload();
        } else {
            this.setState({ oneClickKupnjaInProgress: false });
            message.info(data.Message);

            this.responseNotValid(data);
        }
    };

    responseNotValid = (data) => {
        this.setState({ oneClickKupnjaInProgress: false });
    };

    oneClickError = (data) => {
        this.responseNotValid(data);
        if (data.responseText) alert(JSON.parse(data.responseText).Message);
    };

    preuzmiPrekoPretplate = () => {
        let data = {
            uid: this.state.propis.UID,
            datum: this.state.datum,
        };
        this.setState({ preuzimanjePrekoPretplateUTijeku: true });
        api.post("pretplate/checkset", JSON.stringify(data), this.preuzmiPrekoPretplateSuccess, this.preuzmiPrekoPretplateError);
    };

    preuzmiPrekoPretplateSuccess = (data) => {
        if (data.Status.Status === 200) {
            this.setState({ preuzimanjePrekoPretplateUTijeku: false, kupljeno: true, poslano: true });
            //$(".noselect").removeClass("noselect");
        } else {
            this.responsePretplateNotValid(data);
        }
    };

    responsePretplateNotValid = (data) => {
        this.setState({ preuzimanjePrekoPretplateUTijeku: false });
    };

    preuzmiPrekoPretplateError = (data) => {
        this.responsePretplateNotValid(data);
        if (data.responseText) alert(JSON.parse(data.responseText).Message);
    };

    preuzmiBesplatno = () => {
        this.setState({ preuzimanjeBesplatnoUTijeku: true });

        //više ne spremamo poslane propise u PropisKupovinu.
        //api.post("allocation?uid=" + this.state.propis.UID, null, this.preuzimanjeBesplatnoUTijekuSuccess, this.preuzimanjeBesplatnoUTijekuError);

        //samo preuzmi
        this.preuzmi();
    };

    preuzimanjeBesplatnoUTijekuSuccess = (data) => {
        if (data.Status.Status === 200) {
            this.setState({ preuzimanjeBesplatnoUTijeku: false, poslano: true });
        } else {
            this.responseNotValid(data);
        }
    };

    responseNotValid = (data) => {
        this.setState({ preuzimanjeBesplatnoUTijeku: false });
    };

    preuzimanjeBesplatnoUTijekuError = (data) => {
        this.responseNotValid(data);
    };

    kupiDrugomKarticomModalOk = () => {
        this.buy();
    };

    kupiDrugomKarticomModalCancel = () => {
        this.setState({ kupiDrugomKarticomModalShow: false });
    };

    buy = () => {
        let data = {
            KorisnikID: null,
            UID: this.state.propis.UID,
            TipPlacanjaID: 2,
            WsPayOrderId: 0,
            DokumentFormatID: 0,
            PretragaDatum: this.state.pretragaDatum,
            TipTijelaID: this.state.tipTijelaID,
            IsTokenRequest: this.state.enableOneClickKupnju ? 1 : 0,
            Datum: this.state.datum,
        };

        localStorage.setItem("payment_backto", window.location);
        this.setState({ loading: true, pm: data });

        //sessionStorage.setItem("propisi_propisID", this.state.propis.UID);
        //window.location = "/wspayplacanje";
    };

    preuzmi = () => {
        if (this.state.sendingMail === true) return;

        this.setState({ sendingMail: true });
        api.get(
            "sendtomail/" + this.state.propis.UID + "/" + this.state.tipTijelaID,
            (data) => {
                if (data.Status !== 200) {
                    alert(data.Message);
                }

                this.setState({ sendingMail: true, poslano: true });
            },
            () => {
                this.setState({ sendingMail: false, poslano: true });
            }
        );
    };

    paySuccess = (data) => {
        //alert(data.ErrorMessage);
        if (data.ErrorMessage) {
            this.setState({ errorMessage: data.ErrorMessage, loading: false, pm: null, buyEnabled: false });
        }
    };

    payError = (data) => {
        if (data.ErrorMessage) {
            this.setState({ errorMessage: data.ErrorMessage, loading: false, buyEnabled: false });
        }
    };

    otvoriGlavniPropis = () => {
        window.location = "/propis/" + this.state.propis.GlavniPropisUID;
    };

    povratak = () => {
        window.location = "/";

        // var backto = localStorage.getItem("payment_backto");

        // if (backto && backto === "home") {
        //     window.location = "/";
        //     //window.location.reload();
        // } else {
        //     this.setState({ loading: true });
        //     window.history.back();
        // }
    };

    mailFormRef = React.createRef();

    sendMailAnonymousShowModal = () => {
        this.setState({ sendMailAnonymousModalVisible: true });

        try {
            this.recaptchaRef.reset();
        } catch {}
    };

    hideMailFormModal = () => {
        this.setState({ sendMailAnonymousModalVisible: false, recaptchaToken: null });
    };

    mailFormModalFooter = () => {
        if (!this.state.sendingMail) {
            return [
                <Row type="flex" key={"ssc1"} justify="center" align="middle">
                    <Button key="xc1" onClick={this.hideMailFormModal}>
                        Cancel
                    </Button>
                    <Button key="xc2" onClick={this.sendMailAnonymous}>
                        Pošalji
                    </Button>
                </Row>,
            ];
        } else {
            return [
                <Button key="xc12" onClick={this.hideMailFormModal}>
                    Zatvori
                </Button>,
            ];
        }
    };

    sendMailAnonymous = () => {
        this.mailFormRef.current
            .validateFields()
            .then((values) => {
                if (this.state.recaptchaToken) {
                    if (this.state.sendingMail === true) return;

                    this.setState({ sendingMail: true });

                    var data = {
                        mail: this.state.mailToSend,
                        UID: this.state.propis.UID,
                        ttid: this.state.tipTijelaID,
                        token: this.state.recaptchaToken,
                    };

                    this.setState({ sendingMail: true, recaptchaToken: null });
                    api.post(
                        "sendtomailanonymous",
                        JSON.stringify(data),
                        (data) => {
                            if (data.Status == 200) {
                                this.setState({ sendMailAnonymousModalVisible: false, sendingMail: false, poslano: true });
                            } else {
                                alert(data.Message);
                                this.setState({ sendingMail: false });
                            }
                        },
                        () => {
                            this.setState({ sendMailAnonymousModalVisible: false, sendingMail: false });
                        }
                    );
                } else {
                    alert("Molimo potvrdite da niste robot.");
                }
            })
            .catch((err) => {});
    };

    recaptchaOnChange = (token) => {
        this.setState({ recaptchaToken: token });
    };

    recaptchaRef = React.createRef();

    onMailChange = (e) => {
        this.setState({ mailToSend: e.target.value });
    };

    renderSendMailAnonymousModal = () => {
        return (
            <Modal onCancel={this.hideMailFormModal} forceRender={true} visible={this.state.sendMailAnonymousModalVisible} footer={this.mailFormModalFooter()}>
                {!this.state.sendingMail ? (
                    <Row type="flex" justify="center" align="middle" style={{ padding: "20px" }}>
                        <Form ref={this.mailFormRef} style={{ textAlign: "center", width: "300px", border: "none !important" }} className="noborder" layout="vertical" scrollToFirstError validateTrigger="onBlur">
                            <Form.Item
                                name="mail"
                                label="e-mail"
                                onChange={this.onMailChange}
                                rules={[
                                    {
                                        type: "email",
                                        message: "Neispravna e-mail adresa!",
                                    },
                                    {
                                        required: true,
                                        message: "Molimo unesite E-mail!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <ReCAPTCHA style={{ marginTop: "20px", textAlign: "center" }} ref={(e) => (this.recaptchaRef = e)} sitekey="6LdJxa4bAAAAAJvz3h5R37CQ-1PD9dFZJwoOHSvI" onChange={this.recaptchaOnChange} />
                        </Form>
                    </Row>
                ) : (
                    <div>
                        <Spin />
                        {"    "}
                        <span style={{ color: "lightgray" }}>Slanje u tijeku</span>
                    </div>
                )}

                <Row style={{ textAlign: "center" }}>{/* {this.state.recaptchaToken && <Button style={{textAlign:"center"}} onClick={this.sendMailAnonymous}>Pošalji e-poštom</Button>} */}</Row>
            </Modal>
        );
    };

    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        document.body.appendChild(script);
    }

    redirectToLogin = () => {
        let params = util.getParametersFromUrl(window.location);
        window.location="/login?uid=" + params.uid + "&ttid=" + params.ttid + (params.dp ? "&dp=" + encodeURIComponent(params.dp) : "");
    }

    render() {
        var self = this;
        var onJeAdmin = this.state.isAdmin && this.state.user;
        var nijeAdmin = !onJeAdmin && this.state.user;
        var imaSlobodnePropise = this.state.brojSlobodnihPropisa > 0;
        var imaPretplatu = this.state.aktivnaPretplataID && this.state.brojSlobodnihPropisa > 0;
        var voditeljImaPretplatu = this.state.voditeljAktivnaPretplataID != null;
        var voditeljNemaPretplatu = !voditeljImaPretplatu;
        var voditeljImaToken = this.state.voditeljImaToken;
        var nemaPretplatu = !imaPretplatu;
        var vecKupljeno = this.state.kupljeno;
        var imaGlavniPropis = self.state.propis && self.state.propis.GlavniPropisID;
        var nijeKupljeno = !vecKupljeno;
        var imaToken = this.state.imaToken;
        var nemaToken = !imaToken;
        var poslano = this.state.poslano;
        var nijePoslano = !poslano;
        var naplatno = this.state.tipTijelaID == 1;
        var nijeNaplatno = !naplatno;
        var ulogiran = this.state.user != null;
        var nijeUlogiran = !ulogiran;
        var preuzimanjePrekoPretplateNijeTijeku = !this.state.preuzimanjePrekoPretplateUTijeku;
        var brzoPlacanje = this.state.tipStatusaBrzogPlacanjaID == 1;

        let naslov = this.state.isProcisceni ? (this.state.propis ? this.state.propis.GlavniPropisNaziv : null) : this.state.propis ? this.state.propis.PropisNaziv : null;
        return (
            <div>
                <Helmet>
                    <title>{naslov}</title>
                    <meta name="description" content="lexhr.eu propis"></meta>
                </Helmet>
                <WsPayForm id="wspayForm" paymentModel={this.state.pm} onSuccess={this.paySuccess} onError={this.onError}></WsPayForm>
                {this.state.loading ? (
                    <Row type="flex" justify="center" align="middle" style={{ minHeight: "100vh" }}>
                        <Spinner text="učitavanje..." />
                    </Row>
                ) : (
                    <div>
                        <Affix offsetTop={60}>
                            <div>
                                <Row style={{ backgroundColor: "#fbfbfb", paddingTop: "20px", paddingBottom: "20px" }}>
                                    <Col offset={self.state.offset} span={self.state.span}>
                                        <Button
                                            className="propis-button"
                                            align="center"
                                            type="default"
                                            icon={<LeftOutlined />}
                                            onClick={() => {
                                                this.povratak();
                                            }}
                                            size={"large"}
                                        >
                                            Povratak na pretragu
                                        </Button>

                                        {nijeUlogiran && nijePoslano && this.state.isProcisceni ? (
                                            <Button className="propis-button" align="center" type="default" icon={<SendOutlined />} size={"large"} onClick={this.redirectToLogin}>
                                                Kupi
                                            </Button>
                                        ) : null} 

                                        {nijeUlogiran && nijePoslano && !this.state.isProcisceni ? (
                                            <Button className="propis-button" align="center" type="default" icon={<SendOutlined />} size={"large"} onClick={this.sendMailAnonymousShowModal}>
                                                Pošalji e-poštom
                                            </Button>
                                        ) : null}

                                        {/* {imaGlavniPropis ? (
                                        <Button className="propis-button" align="right" type="default" onClick={() => self.otvoriGlavniPropis()} icon={<BookOutlined />} size={"large"}>
                                            Otvori glavni propis
                                        </Button>
                                    ) : null} */}

                                        {naplatno && nijeKupljeno && nemaPretplatu && imaToken && onJeAdmin ? (
                                            <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Postupak kupnje jednim klikom. Prvom kupnjom može se odabrati da buduće kupnje budu „OneClick kupnja“.. „OneClick kupnja“ se može uvijek isključiti  u postavkama korisničkog računa (Korisnički račun/Osobni podaci).">
                                                <Button className="propis-button" align="center" type="default" icon={<SendOutlined />} loading={this.state.oneClickKupnjaInProgress} size={"large"} onClick={this.oneClick}>
                                                    OneClick kupnja ({(Math.round(this.state.cijena * 100) / 100).toFixed(2)} KN)
                                                </Button>
                                            </Tooltip>
                                        ) : null}

                                        {naplatno && nijeKupljeno && voditeljNemaPretplatu && voditeljImaToken && nijeAdmin ? (
                                            <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Postupak kupnje jednim klikom. Prvom kupnjom može se odabrati da buduće kupnje budu „OneClick kupnja“.. „OneClick kupnja“ se može uvijek isključiti  u postavkama korisničkog računa (Korisnički račun/Osobni podaci).">
                                                <Button className="propis-button" align="center" type="default" icon={<SendOutlined />} loading={this.state.oneClickKupnjaInProgress} size={"large"} onClick={this.oneClick}>
                                                    OneClick kupnja ({(Math.round(this.state.cijena * 100) / 100).toFixed(2)} KN)
                                                </Button>
                                            </Tooltip>
                                        ) : null}

                                        {naplatno && nijeKupljeno && imaSlobodnePropise && onJeAdmin ? (
                                            <Button className="propis-button" align="center" type="default" icon={<SendOutlined />} loading={this.state.preuzimanjePrekoPretplateUTijeku} size={"large"} onClick={this.preuzmiPrekoPretplate}>
                                                Preuzmi preko važećeg modela pretplate
                                            </Button>
                                        ) : null}

                                        {naplatno && nijeKupljeno && nijeAdmin && imaSlobodnePropise ? (
                                            <Button className="propis-button" align="center" type="default" icon={<SendOutlined />} loading={this.state.preuzimanjePrekoPretplateUTijeku} size={"large"} onClick={this.preuzmiPrekoPretplate}>
                                                Preuzmi preko pretplate od nadređenog
                                            </Button>
                                        ) : null}

                                        {
                                            //za voditelja kada kupuje izvan oneClick kupnje ili pretplate
                                            naplatno && nijeKupljeno && onJeAdmin && preuzimanjePrekoPretplateNijeTijeku ? (
                                                <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Registrirani korisnik može obaviti kupnju i bilo kojom drugom kreditnom karticom.">
                                                    <Button className="propis-button" align="center" type="default" size={"large"} onClick={() => this.setState({ kupiDrugomKarticomModalShow: true })}>
                                                        {imaToken ? "Kupi drugom kreditnom karticom" : "Kupi kreditnom karticom"}
                                                    </Button>
                                                </Tooltip>
                                            ) : null
                                        }

                                        {
                                            //za člana kada kupuje izvan oneClick kupnje ili pretplate
                                            naplatno && nijeKupljeno && nijeAdmin ? (
                                                <Button className="propis-button" align="center" disabled={!this.state.buyEnabled} type="default" icon={<SendOutlined />} loading={this.state.sendingMail} size={"large"} onClick={() => this.setState({ kupiDrugomKarticomModalShow: true })}>
                                                    Kupi kreditnom karticom
                                                </Button>
                                            ) : null
                                        }

                                        <Modal
                                            key="modal-cl"
                                            footer={[
                                                <Button
                                                    onClick={() => {
                                                        this.setState({ showClanak: false });
                                                    }}
                                                >
                                                    Zatvori
                                                </Button>,
                                            ]}
                                            forceRender
                                            width={"60vw"}
                                            title={null}
                                            visible={this.state.showClanak}
                                            onOk={() => this.setState({ showClanak: false })}
                                            onCancel={() => this.setState({ showClanak: false })}
                                            afterClose={() => {
                                                window.scrollTo(0, this.windowTop);
                                            }}
                                        >
                                            <Row style={{ textAlign: "center", display: "block" }}>{this.state.ucitavanjeVanjskePoveznice ? <Spin tip="Učitavanje članka..." /> : <div style={{ marginTop: 0 }} id="clanak" className="left doc noselect" dangerouslySetInnerHTML={{ __html: this.state.clanak }}></div>}</Row>
                                        </Modal>

                                        <Modal key="modal-upit" title={<CreditCardOutlined></CreditCardOutlined>} visible={this.state.kupiDrugomKarticomModalShow} onOk={this.kupiDrugomKarticomModalOk} onCancel={this.kupiDrugomKarticomModalCancel}>
                                            <p>Cijena propisa je {this.state.propis.Cijena} kn. Želite li nastaviti kupnju?</p>
                                            <br></br>
                                            {nemaToken && onJeAdmin ? <Checkbox onChange={(e) => this.setState({ enableOneClickKupnju: e.target.checked })}>Želim spremiti podatke o kartici i omogućiti "One Click" kupnju za buduća plaćanja.</Checkbox> : null}
                                            <br></br>
                                            <br></br>
                                            <Row align="center">
                                                <a href="http://www.wspay.info" title="WSpay - Web Secure Payment Gateway" target="_blank">
                                                    <Image preview={false} width={118} src="./images/wsPay.png" border="0" />
                                                </a>
                                            </Row>
                                            <br></br>
                                            <Row align="center">
                                                <a href="http://www.pbzcard.hr" title="PBZ Card" target="_blank">
                                                    <Image preview={false} width={118} src="./images/pbzcard.png" border="0" />
                                                </a>
                                            </Row>
                                        </Modal>

                                        {poslano ? (
                                            <>
                                                <span className="poslano-message">
                                                    <div>
                                                        <CheckCircleOutlined /> Poslano na e-mail adresu: <i>{this.state.mailToSend}</i>
                                                    </div>
                                                    <div className="poslano-message-after">Ukoliko niste zaprimili mail, provjerite neželjenu poštu.</div>
                                                </span>
                                            </>
                                        ) : null}

                                        {naplatno && vecKupljeno && nijePoslano ? (
                                            <Tooltip align="right" mouseEnterDelay={1} placement="right" title="Svaki se kupljeni propis može ponovno poslati u PDF (OCR) i Word obliku na e-adresu korisnika.">
                                                <Button className="propis-button" align="center" type="default" icon={<SendOutlined />} loading={this.state.sendingMail} size={"large"} onClick={this.preuzmi}>
                                                    Pošalji ponovno
                                                </Button>
                                            </Tooltip>
                                        ) : null}

                                        {naplatno && vecKupljeno ? (
                                            // <Alert align="center" className="propis-button" message="Kupljeno" type="success" showIcon />

                                            <span className="kupljeno-message">
                                                <span>
                                                    <CheckCircleOutlined /> Kupljeno
                                                </span>
                                            </span>
                                        ) : null}

                                        {nijeNaplatno && nijePoslano && ulogiran ? (
                                            <Button loading={this.state.preuzimanjeBesplatnoUTijeku} className="propis-button" align="center" type="default" icon={<SendOutlined />} size={"large"} onClick={this.preuzmiBesplatno}>
                                                {this.state.preuzimanjeBesplatnoUTijeku ? "Slanje u tijeku" : "Preuzmi besplatno (pošalji e-poštom)"}
                                            </Button>
                                        ) : null}

                                        {!this.state.isMobileDevice ? (
                                            <span>
                                                <Button
                                                    style={{ float: "right", height: "32px!important" }}
                                                    className="search-box-button"
                                                    align="center"
                                                    type="default"
                                                    onClick={() => {
                                                        this.anchorUp();
                                                    }}
                                                    icon={<CaretUpOutlined />}
                                                ></Button>
                                                <Button
                                                    style={{ float: "right", height: "32px!important" }}
                                                    className="search-box-button"
                                                    align="center"
                                                    type="default"
                                                    onClick={() => {
                                                        this.anchorDown();
                                                    }}
                                                    icon={<CaretDownOutlined />}
                                                ></Button>

                                                <Search
                                                    loading={this.state.searchLoading}
                                                    style={{ width: "300px", float: "right" }}
                                                    value={this.state.searchText}
                                                    id="searchBox"
                                                    onPressEnter={() => {
                                                        this.anchorDown();
                                                    }}
                                                    onChange={this.onSearchChange}
                                                    onKeyUp={this.onSearchKeyPress}
                                                    suffix={<span>{this.state.anchorState}</span>}
                                                    placeholder={"traži unutar propisa"}
                                                ></Search>
                                            </span>
                                        ) : null}
                                    </Col>
                                </Row>
                                <Row style={{ height: "30px" }} className="gradient-opacity"></Row>
                            </div>
                        </Affix>

                        <Row>
                            <Col offset={self.state.offset} span={self.state.span}></Col>
                        </Row>

                        <Row>
                            <Col offset={self.state.offset} span={self.state.span} align="center" style={{ textAlign: "center" }}>
                                <div onMouseMove={() => this.cs()} className="propis-tekst fadein noselect">
                                    <div className="propis-naslov fadein noselect"><h1 className="propis-naslov-h1">{naslov}</h1></div>
                                    <div className="propis-podnaslov fadein noselect">{this.state.podnaslov}</div>
                                    <div>{this.sveIzmjene()}</div>
                                    <div>{this.state.isProcisceni ? "" : this.napomene()}</div>

                                    <br />
                                    <div className="kartica" id="propisroot"></div>
                                </div>
                            </Col>
                        </Row>

                        <div className="search-box-filter">
                            {this.state.showSearchBox === false && this.state.isMobileDevice ? (
                                <Button
                                    style={{ float: "right" }}
                                    className="search-box-button"
                                    align="right"
                                    type="default"
                                    onClick={() => {
                                        this.toggleSearchBox();
                                    }}
                                >
                                    <SearchOutlined />
                                </Button>
                            ) : null}

                            {this.state.showSearchBox ? (
                                <div style={{ display: "contents" }}>
                                    {this.state.isMobileDevice ? (
                                        <Search
                                            loading={this.state.searchLoading}
                                            style={{ width: "60%" }}
                                            value={this.state.searchText}
                                            id="searchBox"
                                            onPressEnter={() => {
                                                this.anchorDown();
                                            }}
                                            onChange={this.onSearchChange}
                                            onKeyUp={this.onSearchKeyPress}
                                            suffix={this.state.anchorState}
                                            placeholder={"traži unutar propisa"}
                                        ></Search>
                                    ) : null}
                                    <Button
                                        className="search-box-button"
                                        align="center"
                                        type="default"
                                        onClick={() => {
                                            this.anchorDown();
                                        }}
                                        icon={<CaretDownOutlined />}
                                    ></Button>
                                    <Button
                                        className="search-box-button"
                                        align="center"
                                        type="default"
                                        onClick={() => {
                                            this.anchorUp();
                                        }}
                                        icon={<CaretUpOutlined />}
                                    ></Button>
                                    <Button
                                        className="search-box-button"
                                        align="center"
                                        type="default"
                                        onClick={() => {
                                            this.clearSearchText();
                                            this.toggleSearchBox();
                                        }}
                                    >
                                        <CloseCircleOutlined />
                                    </Button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                )}
                <br />
                <br />
                <br />
                <br />
                <br />
                {this.renderSendMailAnonymousModal()}
            </div>
        );
    }
}

export default Propis;
