import React from "react";
import axios from "axios";
import { Form, FormInstance, Input, Button, Alert, Row, Divider, Checkbox, Select, Option } from "antd";
import locale from "antd/es/date-picker/locale/hr_HR";
import userService from "../utils/userService";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

class About extends React.Component {
    constructor(props) {
        super(props);
        let test=0;
        this.state = { loading: false };
    }

    render() {
        return (
            <Row type="flex" justify="center" align="middle" style={{ minHeight: "80vh" }}>
               <h1>O nama</h1> 
            </Row>
        );
    }
}

export default About;
