import React from "react";
import { Row, Col} from "antd";
import PuffLoader from "react-spinners/PuffLoader";

class Spinner extends React.Component {
    render() {
        return (
            <div style={{color:"#595959", width: "200px", textAlign:"center"}}>
                <div>
                    <Col align="center" offset={6} span={12} className="spinner">
                    <PuffLoader
                        size={70}
                        color={"#595959"}
                        loading={true}
                    />
                               
                    </Col>
                </div>
                    {this.props.text ? this.props.text : null} 
            </div>
        );
    }
}

export default Spinner;
