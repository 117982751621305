import React from "react";
import { Row, Col } from "antd";
import moment from "moment";
import api from "../utils/api";
import util from "../utils/util";
import Spinner from "./spinner";

class ListaPropisa extends React.Component {
    constructor(props) {
        super(props);

        window.addEventListener("resize", this.updateDimensions);

        this.state = {
            isMobileDevice: util.isMobileDevice(),
            span: util.isMobileDevice() ? 18 : 16,
            offset: util.isMobileDevice() ? 2 : 3,
            propisi: [],
        };
    }

    updateDimensions = () => {
        this.setState({ isMobileDevice: util.isMobileDevice() });
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    otvoriPropis = (item) => {
        window.location = "/propis?uid=" + item.UID + "&ttid=" + item.TipTijelaID + "&dp=" + item.PretragaDatum;
    };

    render() {

        let propisi = this.props.propisi;

        return (
            <div style={{ }}>
                {this.state.isMobileDevice ? (
                    <Row>
                        <Col className="header-naslov" span={24}>
                           <h1 className="header-naslov-propisi">{this.props.naziv || null}</h1>
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row>
                            <Col className="header-naslov" span={24} offset={this.state.offset}>
                                <h1 className="header-naslov-propisi">{this.props.naziv || null}</h1>
                            </Col>
                        </Row>
                        <Row className="zakon-header">
                            <Col span={3} offset={this.state.offset}>
                                {window.location.toString().includes("kupljenipropisi") ? <p>Datum <br></br> preuzimanja</p> : <p>Datum <br></br>pregledavanja</p>}
                            </Col>
                            <Col span={4}></Col>
                            <Col span={11} style={{ marginLeft: 10 }}>
                                Naziv
                            </Col>
                        </Row>
                    </>
                )}

                {propisi.map((item) => {
                    let isProcisceni = item.TipTijelaID === 1;
                    let isGlavni = item.GlavniPropisID && item.GlavniPropisID > 0;

                    let brnn = "NN " + item.NovineBroj + "/" + item.NovineGodina.toString().slice(-2);
                    let naziv = item.PropisNaziv;
                    let className = "zakon-item";
                    if (isProcisceni) {
                        brnn = " Pročišćeni tekst na datum " + util.datum(item.DatumStupanjaNaSnagu);
                        naziv = item.GlavniPropisNaziv;
                    }
                    if (!isGlavni) {
                        className = "temeljni-zakon";
                    }

                    if(naziv)
                        naziv = naziv.replace("[*]", util.tooltip("Temeljni propis koji mijenja drugi temeljni propis."));

                    let datum = window.location.toString().includes("kupljenipropisi") ? moment(item.DatumPreuzimanja).format("DD.MM.YYYY") : moment(item.DatumPregledavanja).format("DD.MM.YYYY");

                    return !this.state.isMobileDevice ? (
                        <Row key={"keyrow" + item.UID} className={className} onClick={() => this.otvoriPropis(item)}>
                            <Col span={3} offset={this.state.offset}>
                                {datum}
                            </Col>
                            <Col span={4} style={{textAlign: 'left'}}>{brnn}</Col>
                            <Col span={11} key={"keycol" + item.UID} style={{ marginLeft: 10 }} dangerouslySetInnerHTML={{__html: naziv}}>
                            
                            </Col>
                        </Row>
                    ) : (
                        <Row key={"keyrow2" + item.UID} className={className} onClick={() => this.otvoriPropis(item)}>
                            
                                <Col span={24}>{datum}</Col>
                                <Col span={24}>{brnn}</Col>
                            
                                <Col span={24} key={"keycol" + item.UID}  dangerouslySetInnerHTML={{__html: naziv}}>
                                    
                                </Col>
                           
                        </Row>
                    );
                })}
            </div>
        );
    }
}

export default ListaPropisa;
