import React from "react";
import { Form, Input, Button, Row, Col, message, Alert } from "antd";
import api from "../utils/api";
import SpinnerFixed from "./spinnerfixed";
import util from "../utils/util";

class PasswordChange extends React.Component {
    constructor(props) {
        super(props);

        let params = util.getParametersFromUrl(window.location);

        if (params && params.uid) {
            this.state = { loading: false, passwordRecovery: true, passwordRecovered: false, uid: params.uid };
        } else {
            this.state = { loading: false, passwordRecovery: false, passwordRecovered: false, uid: null };
        }
    }

    validateMessages = {
        required: "${label} je obvezan podatak!",
        string: {
            min: "${label} - prekratka. Min: ${min}",
        },
    };

    onFinish = (values) => {
        //console.log(values);
        this.setState({ loading: true });

        if (this.state.passwordRecovery) {
            let data = {
                NewPwd: values.NewPassword,
                OldPwd: values.OldPassword,
                Uid: this.state.uid,
            };
            api.post("passwordrecovery", data, this.passwordRecoverySuccess, this.passwordRecoveryFail);
        } else {
            let data = {
                NewPwd: values.NewPassword,
                OldPwd: values.OldPassword,
            };
            api.post("korisnik/passwordchange", JSON.stringify(data), this.successSubmit, this.failSubmit);
        }
    };

    passwordRecoverySuccess = (data) => {
        if (data.Status == 200) {
            this.setState({ passwordRecovered: true, loading: false });
        } else {
            this.setState({ loading: false });
            message.error(data.Message);
        }
    };

    passwordRecoveryFail = () => {
        this.setState({ loading: false });
    };

    successSubmit = (data) => {
        let msg = data.Message;

        if (data.Status == 200) {
            let config = { content: msg, onClose: this.onMessageClose };
            message.success(config);
        } else {
            message.error(msg);
        }
    };

    responseNotValid = (data) => {
        message.error("Promjena zaporke nije uspjela: " + data.responseText);
    };

    failSubmit = (data) => {
        this.responseNotValid(data);
    };

    onMessageClose = () => {
        window.history.back();
    };

    formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    prefixColLayout = {
        xs: { span: 4 },
        sm: { span: 8 },
    };

    middleColLayout = {
        xs: { span: 16 },
        sm: { span: 10 },
    };

    render() {
        return (
            <Row
                // justify="center"
                align="middle"
                style={{ minHeight: "75vh" }}
            >
                <SpinnerFixed show={this.state.loading} text="Učitavanje..."></SpinnerFixed>
                {this.state.passwordRecovered ? (
                    <Row type="flex" justify="center" align="middle" style={{ minHeight: "80vh", width: "100%" }}>
                        <Alert message={"Uspješno ste promijenili lozinku."}></Alert>
                    </Row>
                ) : (
                    <Row type="flex" justify="center" align="middle" style={{ minHeight: "80vh", width: "100%" }}>
                        <Col justify="center" align="middle"  span={24}>
                            <div style={{ width: 500, maxWidth:"90vw" }}>
                                <Form {...this.formItemLayout}  name="PasswordChange" onFinish={this.onFinish} validateMessages={this.validateMessages} style={{ width: "200px" }}>
                                    <Col style={{ maxWidth: "1200px", margin: "auto" }}>
                                        <Row>
                                            <Col span={24}>
                                                {!this.state.uid && (
                                                    <Form.Item label="Stara zaporka" rules={[{ required: true }, { min: 3 }]} name="OldPassword" style={{ width: "100%" }}>
                                                        <Input.Password />
                                                    </Form.Item>
                                                )}
                                                <Form.Item label="Nova zaporka" rules={[{ required: true }, { min: 3 }]} name="NewPassword">
                                                    <Input.Password />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Ponovi zaporku"
                                                    dependencies={["NewPassword"]}
                                                    rules={[
                                                        { required: true },
                                                        { min: 3 },
                                                        ({ getFieldValue }) => ({
                                                            validator(rule, value) {
                                                                if (!value || getFieldValue("NewPassword") === value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject("Zaporke se ne podudaraju");
                                                            },
                                                        }),
                                                    ]}
                                                    name="ConfirmPassword"
                                                >
                                                    <Input.Password />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col {...this.prefixColLayout}></Col>
                                            <Col {...this.middleColLayout}>
                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex" style={{ display: "block", textAlign: "center" }}>
                                                    <Col style={{ display: "inline-block" }}>
                                                        <Form.Item style={{ textAlign: "right" }}>
                                                            <Button type="primary" htmlType="submit" className="search-button">
                                                                Promjena
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                {/* <Form.Item>
                                </Form.Item> */}
                                            </Col>
                                            {/* <Col span={4}></Col>
                            <Col span={4}></Col> */}
                                        </Row>
                                    </Col>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                )}
            </Row>
        );
    }
}
export default PasswordChange;
