import React from "react";
import axios from "axios";
import { Form, FormInstance, Input, Button, Alert, Row, Divider, Checkbox, Select, Option } from "antd";
import locale from "antd/es/date-picker/locale/hr_HR";
import userService from "../utils/userService";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import util from "../utils/util";
import api from "../utils/api";
import $ from "jquery";
import Spinner from "./spinner";

class CallbackForm extends React.Component {
    constructor(props) {
        super(props);
        let params = util.getParametersFromUrl(window.location);
        this.state = {loading: true};

        api.get("transaction/finish?tipTransakcijeID=" + params.tt + "&UID=" + params.UID, this.success, this.fail);
    }

    success = (data) => {
        $("#cb-root").html(data);
        
    };

    fail = (data) => {
        $("#cb-root").html(data);
    };

    back = () => {
        var backto = localStorage.getItem("payment_backto");
        if (backto) {
            if (backto === "home") {
                window.location = "/";
            }
            if (backto.endsWith("/")) {
                window.location = "/";
            } else if (backto.indexOf("/confirm") > 0) {
                window.location = "/";
            } else {
                localStorage.setItem("payment_backto", "home");
                window.location = backto;
                window.location.reload();
            }
        }
    }

    render() {
        return (
            <div>
                <Row type="flex" id="cb-root" justify="center" align="middle" style={{ height: "57vh" }}>
                    {this.state.loading ? <Spinner text="plaćanje u tijeku..."></Spinner> : null}
                </Row>
                <Row justify="center" >
                    <Button 
                        style={{minWidth: "180px"}}
                        onClick={this.back}
                    >
                        Povratak
                    </Button>
                </Row>
            </div>
        );
    }
}

export default CallbackForm;
