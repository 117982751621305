import moment from "moment";
import userService from "./userService";
import $ from "jquery";

export const util = {
    getParametersFromUrl,
    isMobileDevice,
    otvoriPropis,
    OIBCheck,
    isValidEmail,
    validatePassword,
    datum,
    getCookie,
    deleteCookie,
    isIE,
    log,
    setCookie,
    tooltip,
    toEmptyString,
    clearSettings,
    getLoggedInUser,
    podesiGoToLinkove
};

function log(text) {
    console.log("%c" + text, " color: black ; font-weight: bold ; " + "font-size: 20px ; font-style: italic ; text-decoration: underline ; " + "font-family: 'american typewriter' ;");
}

function isIE() {
    let ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return is_ie;
}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
}

function setCookie(cookieName, value, expirationDate) {
    document.cookie = cookieName + "=" + value + "; path=/; expires=" + expirationDate.toUTCString();
}

function deleteCookie(name) {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

function getParametersFromUrl(url) {
    let params = {};
    (url + "?")
        .split("?")[1]
        .split("&")
        .forEach(function (pair) {
            pair = (pair + "=").split("=").map(decodeURIComponent);
            if (pair[0].length) {
                params[pair[0]] = pair[1];
            }
        });
    return params;
}

function isMobileDevice() {
    let isIE = this.isIE();

    const isMobile = window.matchMedia("only screen and (max-width: 760px)");
    if (((/Mobi|Tablet|iPad|iPhone/i.test(navigator.userAgent) || isMobile.matches) && !isIE) || (isIE && isMobile.matches)) {
        return true;
    }
    return false;
}

function otvoriPropis(propis) {
    window.location = "/propis/" + propis.UID;
}

function OIBCheck(oib) {
    oib = oib.toString();

    if (oib.length != 11) return false;

    var isNumeric = parseInt(oib, 10);

    if (isNaN(isNumeric)) return false;

    var a = 10;
    for (var i = 0; i < 10; i++) {
        a = a + parseInt(oib.substr(i, 1), 10);
        a = a % 10;
        if (a == 0) a = 10;
        a *= 2;
        a = a % 11;
    }

    var crc = 11 - a;
    if (crc == 10) crc = 0;
    return crc == parseInt(oib.substr(10, 1));
}

function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validatePassword(str) {
    if (str.length < 6) {
        return "Minimalni broj znakova je 6";
    } else if (str.search(/\d/) == -1) {
        return "Zaporka mora sadržavati minimalno jednu znamenku";
    } else if (str.search(/[a-zA-Z]/) == -1) {
        return "Zaporka mora sadžavati barem jedno slovo";
    }

    // else if (str.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+]/) != -1) {
    //     return("bad_char");
    // }
    return "ok";
}

function datum(date) {
    if (!date) return "-";

    return moment(date).format("DD.MM.YYYY");
}

function tooltip(message){
    //return "<span class='tooltip'>?<div class='tooltip-left'><h3>LEX.HR</h3><ul><li>" + message + "</li></ul><i></i></div></span>";
    
    return "<span class='tooltip'>?<div class='tooltip-left'>" + message + "<i></i></div></span>";

    // return  <Tooltip align="right" mouseEnterDelay={1} placement="right" title={message}>
    //     </Tooltip>
                                           
}

function toEmptyString(item){
     if(item==="null" || item===null ||item===undefined) return "";
     return item;  
}


function clearSettings() {
    sessionStorage.setItem("pretraga-text", "");
    sessionStorage.setItem("datum-objave", "");
    sessionStorage.setItem("propisi-data", null);
    sessionStorage.setItem("broj-narodnih-novina", "");
    sessionStorage.setItem("pretraga-pageSize", null);
    sessionStorage.setItem("pretraga-pageNumber", null);
    sessionStorage.setItem("pretraga-resultsCount", null);
    sessionStorage.setItem("pretraga-searchType", null);
    sessionStorage.setItem("narodne-novine-godina", null);
    sessionStorage.setItem("napredno-pretrazivanje", null);
}

function getLoggedInUser() {
    return userService.getLoggedInUser();
}

function podesiGoToLinkove(root, offset){
    $(root).each((index, e) => {
        let href = $(e).attr("href");
        let name = $(e).attr("name");
        if(!name) name = "";

        if (href && href.startsWith("#") || name) {
            $(e).replaceWith("<span class='link' to='" + href + "' id='#" + name + "'>" + $(e).html() + "</span>");
        }
    });

    $(".link").each((index, e) => {
        $(e).click(() => {
            let to = $(e).attr("to");
            let element = document.getElementById(to);
            if(!element) 
                element = document.getElementsByName(to)[0];
                
            if(element) {
                var headerOffset = offset;
                var elementPosition = element.getBoundingClientRect().top;
                var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
               });
                //element.scrollIntoView();
            }
        });
    });
}
 

export default util;
