import React from "react";
import { Modal, Form, Input, InputNumber, Button, Alert, Row, Col, Divider, Tooltip, Checkbox, Dropdown, AutoComplete, Select, Popconfirm, message, Menu, Layout } from "antd";
import locale from "antd/es/date-picker/locale/hr_HR";
import userService from "../utils/userService";
import util from "../utils/util";
import api from "../utils/api";
import { PlusOutlined, QuestionCircleOutlined, SaveFilled, DollarOutlined, KeyOutlined, UserOutlined, LockOutlined, UploadOutlined, VideoCameraOutlined } from "@ant-design/icons";
import PasswordChange from "./passwordchange";
import $ from "jquery";
import WsPayForm from "./wspayform";
import SpinnerFixed from "./spinnerfixed";
import PdfUvjetiKoristenja from "../uvjeti-koristenja-lexhr.pdf";

const { confirm } = Modal;

const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;

var tipOsobaOptions = [
    { label: "Domaća fizička", value: 1 },
    { label: "Domaća pravna", value: 2 },
    { label: "Strana fizička", value: 3 },
    { label: "Strana pravna", value: 4 },
];
var varStatusTokenaOptions = [
    { label: "Onemogućen", value: 0 },
    { label: "Omogućen", value: 1 },
    { label: "Pauziran", value: 2 },
];
var mjestoOptions = [
    { label: "10000 Zagreb", value: 1 },
    { label: "11000 Split", value: 8517 },
    { label: "31000 Osijek", value: 3 },
    { label: "49000 Krapina", value: 4 },
];

function onChange(value) {
    console.log(`selected ${value}`);
}

function onBlur() {
    console.log("blur");
}

function onFocus() {
    console.log("focus");
}

function onSearch(val) {
    console.log("search:", val);
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const validateMessages = {
    required: "${label} je obvezan podatak!",
    types: {
        email: "${label} nije ispravna e-mail adresa!",
        number: "${label} nije ispravan broj!",
    },
    number: {
        range: "${label} mora biti između ${min} i ${max}",
    },
    string: {
        min: "${label} - prekratko. Min: ${min}",
    },
    pattern: {
        mismatch: "${name} ne odgovara predlošku! Primjer ispravnog formata: +3850911234567",
    },
};

// const validateFields = (values) => {
//   console.log(values);
// }

const successSubmit = (data) => {
    if (data.Status === 200) {
        

        let msg = data.Message;

        if (data.Status == 200) {
            let config = { content: msg, onClose: onMessageClose };
            message.success(config);
        } else {
            message.error(msg);
        }
    } else {
        responseNotValid(data);
    }
};

const responseNotValid = (data) => {
    message.error("Pohrana podataka nije uspjela: " + data.responseText);
};

const failSubmit = (data) => {
    this.responseNotValid(data);
    if (data.responseText) alert(JSON.parse(data.responseText).Message);
};

const onMessageClose = () => {
    // window.location = "/";
    window.history.back();
};

const prefixColLayout = {
    xs: { span: 4 },
    sm: { span: 8 },
};

var self;

class UserProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            korisnik: { TokenValid: true },
            getSuccess: false,
            getSifarniciSuccess: false,
            tokenDisabled: false,
            mirkoOptions: null,
            offset: util.isMobileDevice() ? 2 : 3,
            spanCol1: util.isMobileDevice() ? 18 : 16,
            menuSelected: "1",
            pm: null,
            visible: false,
            loading: true,
            uvjetiPrihvaceni: false,
        };

        if (this.props.firstRegistration) {
            let params = util.getParametersFromUrl(window.location);
            api.get("korisnik/getkorisnikregistration?username=" + params.username + "&guid=" + params.token, this.successFirstRegistration, this.failFirstRegistration);
        }

        api.get("sifarnici/get?requesterID=1", this.sifarniciSuccess, this.sifarniciFail);
    }

    pm = {};

    showModal = () => {
        this.setState({
            visible: true,
        });
        $("#Nova").html(this.tekstTokenConfirm);
    };

    handleOk = (e) => {
        this.setState({
            visible: false,
        });
        var location = window.location;
        localStorage.setItem("payment_backto", location.hash);
        this.setState({ pm: this.pm });
    };

    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    };

    onFinish = (values) => {
        let self = this;
        let data = {
            KorisnikID: values.KorisnikID,
            Ime: values.Ime,
            Prezime: values.Prezime,
            TipOsobaID: values.TipOsobaID,
            Naziv: values.Naziv,
            OIB: values.OIB,
            Adresa: values.Adresa,
            Telefon: values.Telefon,
            MjestoID: values.MjestoID,
            TipStatusaBrzogPlacanjaID: values.TipStatusaBrzogPlacanjaID,
        };
        if (self.props.firstRegistration) {
            //dolazimo sa linka iz maila

            if (self.props.onSave) self.props.onSave(data);
        } else {
            api.post("korisnik/updatekorisnik", JSON.stringify(data), successSubmit, failSubmit);
        }
    };

    korisnikForm = React.createRef();

    success = (data) => {
        this.korisnikForm.current.setFieldsValue(data);

        this.pm.TipPlacanjaID = 1;
        this.pm.KorisnikID = data.KorisnikID;
        this.pm.ModelPretplateID = null;
        this.pm.UID = null;
        this.pm.TokenPaymentForThisPropisOnly = null;
        this.pm.IsTokenRequest = 1;

        let tk = this.tipKorisnickogRacunaOptions.find((element) => element.value == data.TipKorisnickogRacunaID);
        this.tipKorisnika = "(" + tk.label + ")";

        this.setState({
            korisnik: data,
            getSuccess: true,
            tokenDisabled: data.TipStatusaBrzogPlacanjaID == 0,
            loading: false,
            voditelj: data.Voditelj
        });

        if (data.TipStatusaBrzogPlacanjaID != 0) {
            //da li mogu maknuti jednu opciju u selektu?
            var id = 0;

            var pomocnaLista = this.state.mirkoOptions.filter(function (obj) {
                return obj.value !== id;
            });

            this.setState({ mirkoOptions: pomocnaLista });
        }
    
    };

    fail = (data) => {
        this.setState({ getSuccess: false, loading: false });
    };

    sifarniciSuccess = (data) => {
        //ovo sam ovako napravio u petlji iako je samo jedan tekst, zato što očekujem da će ih možda biti više
        for (var i = 0; i < data.Tekstovi.length; i++) {
            var tekst = data.Tekstovi[i];
            if (i == 0) {
                this.tekstTokenConfirm = tekst;
            }
            if (i == 1) {
                this.tekstTokenConfirmTitle = tekst;
            }
        }
        this.setState({ loading: false });
        this.tipOsobaOptions = data.TipOsoba;
        this.mjestoOptions = data.Mjesto;
        this.tipKorisnickogRacunaOptions = data.TipKorisnickogRacuna;

        this.varStatusTokenaOptions = data.TipStatusaBrzogPlacanja;
        this.setState({ mirkoOptions: data.TipStatusaBrzogPlacanja });
        if (!this.props.firstRegistration) {
            this.setState({ loading: true });
            api.get("korisnik/getkorisnik", this.success, this.fail);
        }
    };

    sifarniciFail = (data) => {
        this.setState({ getSifarniciSuccess: false });
    };

    successFirstRegistration = (data) => {
        this.setState({ voditelj:data })
        this.korisnikForm.current.setFieldsValue({ Voditelj:data });
        //alert(this.state.voditelj);
        //api.get("sifarnici/get?requesterID=1", this.sifarniciSuccess, this.sifarniciFail);
    };

    failFirstRegistration = (data) => {

    };

    nazivChange = (value) => {
        this.setState({ korisnikNaziv: value.target.value });
    };

    menuSelected = (menu) => {
        this.setState({ menuSelected: menu.key });
    };

    onUvjetiChange = (data) => {
        this.setState({ uvjetiPrihvaceni: data.target.checked });
    };

    render() {
        self = this;
        let isMobileDevice = util.isMobileDevice();

        let span = isMobileDevice ? 24 : 12;
        let offsetFrame = isMobileDevice ? 1 : 4;
        let spanFrame = isMobileDevice ? 22 : 16;

        return (
            <Row
                type="flex"
                // justify="center"
                align="middle"
                style={{ minHeight: "75vh", marginTop: "70px" }}
            >
                <SpinnerFixed show={this.state.loading} text="Učitavanje..."></SpinnerFixed>
                <WsPayForm id="wspayForm" paymentModel={this.state.pm}></WsPayForm>

                <Modal title={this.tekstTokenConfirmTitle} visible={this.state.visible} onOk={() => this.handleOk()} onCancel={this.handleCancel} forceRender={true}>
                    <div id="Nova"></div>
                </Modal>

                <Col offset={offsetFrame} span={spanFrame} className="center">
                    {!this.props.firstRegistration ? (
                        <Form style={{ marginTop: "20px" }}>
                            <div
                                disabled={true}
                                style={{
                                    border: "none",
                                    fontSize: "2em",
                                    fontWeight: "bold",
                                    float: "right",
                                }}
                            >
                                {this.state.korisnik.Username}
                            </div>
                            <Input
                                disabled={true}
                                style={{
                                    border: "none",
                                    paddingTop: "0",
                                    paddingBottom: "0",
                                    textAlign: "right",
                                    backgroundColor: "rgba(0, 0, 0, 0)",
                                }}
                                value={this.tipKorisnika}
                            />
                        </Form>
                    ) : null}

                    <Form {...layout} style={{ marginTop: "1px" }} ref={this.korisnikForm} name="Korisnik" onFinish={this.onFinish} validateMessages={validateMessages} validateTrigger="onBlur">
                        <Divider orientation="left">Osobni podaci</Divider>
                        <Row style={{ display: "none" }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex">
                            <Form.Item name="KorisnikID">
                                <Input />
                            </Form.Item>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex">
                            <Col span={span}>
                                <Form.Item label="Ime" rules={[{ required: true }, { min: 2 }]} name="Ime">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={span}>
                                <Form.Item label="Prezime" rules={[{ required: true }, { min: 3 }]} name="Prezime">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex">
                            <Col span={span}>
                                <Form.Item
                                    name="OIB"
                                    label="OIB"
                                    rules={[
                                        ({}) => ({
                                            validator(rule, value) {
                                                if (value != null && value != "" && (value.length != 11 || !util.OIBCheck(value))) {
                                                    return Promise.reject("OIB nije ispravan!");
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={span}>
                                <Form.Item
                                    name="Naziv"
                                    label="Naziv pravne osobe"
                                    // rules={[{ required: true }]}
                                    onChange={this.nazivChange}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex">
                            <Col span={span}>
                                <Form.Item name="Adresa" label="Adresa" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={span}>
                                <Form.Item name="MjestoID" label="Mjesto" rules={[{ required: true }]}>
                                    <Select showSearch placeholder="Odaberite mjesto" optionFilterProp="children" onChange={onChange} onFocus={onFocus} onBlur={onBlur} onSearch={onSearch} options={this.mjestoOptions} filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}></Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex">
                            <Col span={span}>
                                <Form.Item name="Telefon" label="Telefon" rules={[{ pattern: /^[+]?([\d]{7,15})$/ }]}>
                                    <Input placeholder="+3850911234567" />
                                </Form.Item>
                            </Col>
                            <Col span={span}>
                                <Form.Item
                                    name="TipOsobaID"
                                    label="Osoba"
                                    dependencies={["Naziv", "OIB"]}
                                    rules={[
                                        { required: true },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if ((value == 2 || value == 4) && (getFieldValue("Naziv") == null || getFieldValue("Naziv") === "")) {
                                                    return Promise.reject("Pravna osoba - naziv obvezan!");
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if ((value == 2 || value == 4) && (getFieldValue("OIB") == null || getFieldValue("OIB") === "")) {
                                                    return Promise.reject("Pravna osoba - OIB obvezan!");
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if ((value == 2 || value == 4) && self.state.korisnik.TipKorisnickogRacunaID == 2) {
                                                    return Promise.reject("Član tima je fizička osoba!");
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Select
                                        // showSearch
                                        placeholder="Odaberite vrstu osobe"
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        // onSearch={onSearch}
                                        options={this.tipOsobaOptions}
                                        filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) >= 0}
                                    ></Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex">
                            <Col span={span}>
                                <Tooltip placement="top" title="Email adresa za slanje računa ukoliko je različita od glavne">
                                    <Form.Item name="RacunEmail" label="Email" rules={[{ type: "email" }]}>
                                        <Input />
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col span={span}>
                                {(this.state.korisnik.TipKorisnickogRacunaID == 1 && (this.state.voditelj == null || this.state.voditelj == ""))
                                || (this.props.firstRegistration && (this.state.voditelj == null || this.state.voditelj == "")) ? null : (
                                    <Form.Item name="Voditelj" label="Glavni korisnik">
                                        <Input disabled={true} value={this.state.voditelj} style={{ color: "rgba(0, 0, 0, 0.65)" }} />
                                    </Form.Item>
                                )  }
                            </Col>
                        </Row>

                        {!this.props.firstRegistration ? (
                            <div>
                                <Divider orientation="left">
                                    <Tooltip title="Status i podaci o tokenu za plaćanje jednim klikom">Podaci za One-Click kupovinu</Tooltip>
                                </Divider>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex">
                                    <Col span={span}>
                                        <Form.Item
                                            name="TipStatusaBrzogPlacanjaID"
                                            label="Status"
                                            rules={[
                                                { required: true },
                                                ({}) => ({
                                                    validator(rule, value) {
                                                        if ((value == null || value == 0) && !(self.state.korisnik.TipStatusaBrzogPlacanjaID == 0)) {
                                                            return Promise.reject("Nedopušteni odabir!");
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Select
                                                disabled={this.state.korisnik.TipStatusaBrzogPlacanjaID == 0}
                                                // showSearch
                                                placeholder="Odaberite status tokena"
                                                optionFilterProp="children"
                                                onChange={onChange}
                                                onFocus={onFocus}
                                                onBlur={onBlur}
                                                // onSearch={onSearch}
                                                options={this.state.mirkoOptions}
                                                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            ></Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        {!self.state.korisnik.TokenValid ? <div style={{ color: "red", textAlign: "center" }}>Podaci za One-Click kupnju su istekli. Obnovite ih klikom na gumb Novi token!</div> : null}
                                        {/* <Form.Item
                                            label="Broj"
                                            // rules={[{ required: true }]}
                                        >
                                            <Input disabled={true} style={{ color: "red!important" }} value={"završava s " + this.state.korisnik.Token} />
                                        </Form.Item> */}
                                    </Col>
                                </Row>

                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex">
                                    <Col span={span}>
                                        <Form.Item
                                            label="Broj kartice"
                                            // rules={[{ required: true }]}
                                        >
                                            <Input disabled={true} style={{ color: "rgba(0, 0, 0, 0.65)" }} value={"završava s " + this.state.korisnik.TokenNumber} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={span}>
                                        <Form.Item
                                            label="Istek"
                                            // rules={[{ required: true }]}
                                        >
                                            <Input disabled={true} style={{ color: "rgba(0, 0, 0, 0.65)" }} value={this.state.korisnik.TokenExp} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                        <Divider orientation="left"></Divider>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex" style={{ display: "block", textAlign: "center" }}>
                            {self.state.korisnik.TipKorisnickogRacunaID == 1 && !self.state.korisnik.TokenValid ? (
                                <Col style={{ display: "inline-block" }}>
                                    <Form.Item
                                        // wrapperCol={{ span:20, offset: 4 }}
                                        style={{
                                            textAlign: "right",
                                        }}
                                    >
                                        <Tooltip placement="top" title="Preuzimanje novog tokena">
                                            <Button
                                                // type="text"
                                                htmlType="button"
                                                style={{
                                                    width: "100%",
                                                    minWidth: "120px",
                                                }}
                                                icon={<DollarOutlined />}
                                                onClick={this.showModal}
                                            >
                                                Novi token
                                            </Button>
                                        </Tooltip>
                                    </Form.Item>
                                </Col>
                            ) : null}

                            {self.props.firstRegistration && (
                                <Col style={{ display: "inline-block" }}>
                                    <Checkbox onChange={this.onUvjetiChange}>
                                        Prihvaćam
                                        <a href={PdfUvjetiKoristenja} target="_blank">
                                            {" "}
                                            &nbsp;
                                            <label style={{ textDecoration: "underline", cursor: "pointer" }}>uvjete korištenja</label>
                                        </a>
                                    </Checkbox>
                                </Col>
                            )}
                            <Col style={{ display: "inline-block" }}>
                                <Form.Item
                                    // wrapperCol={{ span:20, offset: 4 }}
                                    style={{
                                        textAlign: "right",
                                    }}
                                >
                                    {/* <Popconfirm
                                        placement="topLeft"
                                        title="Da li ste sigurni da želite spremiti upisane podatke?"
                                        okText="Da"
                                        cancelText="Ne"
                                        okButtonProps={{
                                            type: "Primary",
                                            htmlType: "submit",
                                            form: "Korisnik",
                                        }}
                                    >
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{
                                                width: "100%",
                                                minWidth: "120px",
                                            }}
                                            icon={<SaveFilled />}
                                        >
                                            Pohrana2
                                        </Button>
                                    </Popconfirm> */}
                                    <Button disabled={self.props.firstRegistration && !this.state.uvjetiPrihvaceni} className="search-button" type="primary" htmlType="submit" icon={<SaveFilled />}>
                                        Pohrana
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        );
    }
}

export default UserProfile;
