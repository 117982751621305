import React from "react";
import { Row, Col, Space, Button, Input, Tabs } from "antd";
import api from "../utils/api";
import util from "../utils/util";
import $ from "jquery";
import logo from "../images/web-secure-logo.png";
import userService from "../utils/userService";
import WsPayForm from "./wspayform";

const { TabPane } = Tabs;

class WsPayPlacanje extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: userService.getLoggedInUser(),
            isMobileDevice: util.isMobileDevice(),
            span: util.isMobileDevice() ? 12 : 16,
            offset: util.isMobileDevice() ? 1 : 4,
            uspjesnaRegistracija: false,
            customerFirstName: "",
            customerLastName: "",
            customerAddress: "",
            customerCity: "",
            customerZIP: "",
            customerCountry: "",
            customerPhone: "",
            customerEmail: "",
            shopID: "ZGOMBICT",
            shoppingCartID: "",
            totalAmount: "0",
            totalAmountSaZarezom: "0",
            signature: "",
            korisnikID: 0,
            token: "",
        };
    }

    paySuccess = (data) => {
        if(this.props.onSuccess)
            this.props.success(data);
    };

    payFail = (data) => {
        if(this.props.onError)
            this.props.onError(data);
    };

    success = (data) => {
        this.setState({ uspjesnaRegistracija: true });
    };

    fail = (data) => {
        this.setState({ uspjesnaRegistracija: false });
    };

    oneClickPay = () => {
        // this is the id of the form
        let data = {
            Version: "2.0",
            ShopID: this.state.shopID,
            ShoppingCartID: this.state.shoppingCartID,
            Token: "d5c2621c-a73a-4d53-b1d5-3eafabccde44",
            TokenNumber: "0189",
            PaymentPlan: "0000",
            TotalAmount: this.state.totalAmountSaZarezom,
            DateTime: "20190722115807",
            CustomerFirstName: "John",
            CustomerLastName: "Doe",
            CustomerAddress: "Address 10",
            CustomerCity: "zagreb",
            CustomerZIP: "10000",
            CustomerCountry: "Croatia",
            CustomerPhone: "0911111111",
            CustomerEmail: "vedran.selendic@ipt.hr",
            Language: "HR",
            Signature: this.state.signature,
        };

        api.postOut("https://test.WSPay.biz/api/services/processPayment", data, this.paySuccess, this.payFail);
    };

    pay = () => {


    
        let data = {
            KorisnikID: null,
            UID: sessionStorage.getItem("propisi_propisID"),
            TipPlacanjaID: 2,
            WsPayOrderId: 0,
            DokumentFormatID: 0,
        };
        this.setState({pm: data});
        //this.preparePayment();
    };

    preuzmi = () => {
         api.post("sendtomail?token=" + this.state.token, ()=>alert("uspjeh"), ()=>alert("neuspjeh") );
    };

    tokenChange = (e) => {
        this.setState({ token: e.target.value });
    };

    preuzmiPdf = () => {
        api.download("pdf", this.state.token);
    };

    preuzmiWord = () => {
        api.download("word", this.state.token);
    };

    render() {
        return (
            <Row type="flex" justify="center" align="middle" style={{ minHeight: "90vh" }}>
                <WsPayForm
                    id="wspayForm"
                    paymentModel = {this.state.pm}
                ></WsPayForm>
                <Col span={this.state.isMobileDevice ? 20 : 20} offset={this.state.isMobileDevice ? 1 : 1}>
                    <Tabs defaultActiveKey="1" type="flex" justify="center" align="middle" style={{ minHeight: "90vh" }}>
                        <TabPane tab="Plaćanje kreditnom karticom" key="1">
                            <Row align="center">
                                <Col span={24} align="center">
                                    <img src={logo} alt="Logo" className="wspaylogo" />
                                </Col>
                            </Row>
                            <Row align="center">
                                <Col span={24} className="cijena" align="center">
                                    Cijena: 7.25 KN + PDV
                                </Col>
                            </Row>
                            <Row align="center">
                                <Col span={24} align="center">
                                    <Button onClick={() => this.pay()}>Idi na plaćanje</Button>
                                </Col>
                            </Row>
                        </TabPane>
                        {this.state.user ? null : (
                            <TabPane tab="Preuzimanje putem koda" key="2">
                                <Row align="center">
                                    <Col span={24} align="center"></Col>
                                </Row>
                                <Row align="center">
                                    <Col span={24} align="center">
                                        <Input onChange={this.tokenChange} placeholder="Unesite kod"></Input>
                                    </Col>
                                </Row>
                                <Row align="center">
                                    <Col span={24} align="center">
                                        <br />
                                        <Space>
                                            <Button onClick={this.preuzmi}>Preuzmi WORD</Button>
                                            <Button onClick={this.preuzmi}>Preuzmi PDF</Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </TabPane>
                        )}
                    </Tabs>
                </Col>
            </Row>
        );
    }
}

export default WsPayPlacanje;
