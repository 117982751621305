import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import "../src/css/index.css";
import "../src/css/highlight.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { HelmetProvider } from "react-helmet-async";

ReactDOM.render(
    <div>
        <HelmetProvider>
            <App />
        </HelmetProvider>
    </div>,

    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
